import { COLORS } from 'constants/colors';
import useColorSetStore from 'stores/colorSet';
import useLanguageStore from 'stores/languages';
import { Floor } from 'types/common/floor.type';
import styles from './Floor.module.scss';

type FloorProps = {
  floor: Floor;
  activeFloor: boolean;
  overFiveFloor: boolean;

  onClickFloor: (floor: Floor) => void;
};
const FloorButton = ({
  floor,
  activeFloor,

  overFiveFloor,

  onClickFloor,
}: FloorProps) => {
  const themeStore = useColorSetStore();
  const langStore = useLanguageStore();

  return (
    <button
      style={{ background: activeFloor ? themeStore.main : COLORS.WHITE }}
      type='button'
      disabled={activeFloor}
      className={`${styles.floor} ${overFiveFloor && styles.overFive} ${activeFloor && styles.active}`}
      onClick={onClickFloor.bind(this, floor)}
    >
      {floor.name[langStore.currentLang]}
    </button>
  );
};

export default FloorButton;
