import { PoiContentKeyword } from 'types/common/poiContentKeyword.type';
import { sortKoEnFirst } from '../ko-en-first/sortKoEnFirst';

/**
 * @desc 키워드 목록을 정렬한다.
 * @desc 검색어가 포함된 키워드는 우선 정렬한다.
 * @param {PoiContentKeyword[]} keywords 검색어를 포함한 키워드 목록
 * @param {string} searchWord 검색어
 */
export function sortKeywordsBySearchWord(keywords: PoiContentKeyword[], searchWord: string) {
  return keywords.sort((a, b) => {
    // 검색 시, 키워드가 검색어로 시작하는지 확인한다.
    const startsWithA = a.initialSearchKeyword.startsWith(searchWord);
    const startsWithB = b.initialSearchKeyword.startsWith(searchWord);

    // a가 검색어로 시작하고, b는 검색어로 시작하지 않는다면 a를 먼저 정렬한다.
    if (startsWithA && !startsWithB) {
      return -1;
    }

    // a는 검색어로 시작하지 않고, b는 검색어로 시작한다면 b를 먼저 정렬한다.
    if (!startsWithA && startsWithB) {
      return 1;
    }

    // 둘 다 검색어로 시작하지 않으면 사전 순으로 정렬한다. (한 -> 영 -> 숫자)
    return sortKoEnFirst(a.title.toUpperCase(), b.title.toUpperCase());
  });
}
