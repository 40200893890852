import CustomPagination from 'component/common/pagination/CustomPagination';
import useCustomBoardStore from 'stores/customBoard';
import { SwiperSlide } from 'swiper/react';
import styles from './CardCustomBoardContainer.module.scss';
import CardCustomBoardItem from './item/CardCustomBoardItem';
import CardSwiper from './swiper/CardSwiper';

const CardCustomBoardContainer = () => {
  const customBoardStore = useCustomBoardStore();

  return (
    <div className={styles.container}>
      <div className={styles.swiper_wrapper}>
        <CardSwiper>
          <div className={styles.boards_wrapper}>
            {customBoardStore.currentCustomBoards.map(board => {
              return (
                <SwiperSlide key={board.id} style={{ overflow: 'visible' }}>
                  <CardCustomBoardItem board={board} />
                </SwiperSlide>
              );
            })}
          </div>
        </CardSwiper>
      </div>

      {/* pagination */}
      <CustomPagination />
    </div>
  );
};
export default CardCustomBoardContainer;
