/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-useless-fragment */
import useColorSetStore from 'stores/colorSet';
import useScreenTypeStore from 'stores/screenType';
import { ScreenType } from 'types/common/common.type';
import styles from './CustomBoardHeader.module.scss';

type CustomBoardHeaderProps = {
  title: string;
  isList: boolean;
};

const CustomBoardHeader = ({ title, isList }: CustomBoardHeaderProps) => {
  const screenTypeStore = useScreenTypeStore();
  const colorSetStore = useColorSetStore();

  return (
    <>
      {/* 세로형, 정방형 */}
      {screenTypeStore.screenType !== ScreenType.hori ? (
        <div
          className={styles.verti_header}
          style={{
            background: colorSetStore.header.bg,
            color: colorSetStore.header.text,
          }}
        >
          {title}
        </div>
      ) : isList ? (
        // 가로형 목록형
        <div
          className={styles.hori_header_list}
          style={{
            background: 'rgba(255, 255, 255, 0.6)',
          }}
        >
          {title}
        </div>
      ) : (
        // 가로형 카드형
        <div
          style={{
            background: 'transparent',
            backdropFilter: `blur(2px)`,
          }}
          className={styles.hori_header_card}
        >
          <span>{title}</span>
        </div>
      )}
    </>
  );
};

export default CustomBoardHeader;
