import CALENDAR from 'assets/customBoard/calendar.svg';
import { t } from 'i18next';
import { CustomBoardContent, Display } from 'types/feature/customBoard.type';
import styles from './DisplayDate.module.scss';

type Props = {
  board: CustomBoardContent;
};

const DisplayDate = ({ board }: Props) => {
  return (
    <div className={styles.date}>
      <div className={styles.icon}>
        <img src={CALENDAR} alt='' />
      </div>
      {/* 상시, 기간일 경우 처리 */}
      <div className={styles.days}>
        {board.displayType === Display.ALWAYS_ON && t('customBoard.always')}
        {board.displayType === Display.DURATION && `${board.startDate} ~ ${board.endDate}`}
      </div>
    </div>
  );
};
export default DisplayDate;
