// 매장 검색 슬라이드 만드는 유틸
export const makeSlides = <T>(tenants: T[], perSlides: number) => {
  const slides: T[][] = [];

  if (!tenants || tenants.length < 1) return slides;
  if (perSlides < 1) return slides;

  while (tenants.length > perSlides) {
    const slide = tenants.splice(0, perSlides);
    slides.push(slide);
  }

  slides.push(tenants);

  return slides;
};
