import MapDraw from 'map/MapDraw';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useMapActionStore from 'stores/action';
import useColorSetStore from 'stores/colorSet';
import useFloorStore from 'stores/floor';
import useTransportStore from 'stores/transport';
import { PathType } from 'types/common/common.type';
import styles from './TransportPopup.module.scss';

type TransportPopupProps = {
  closeModal: () => void;
};

const TransportPopup = ({ closeModal }: TransportPopupProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const transportStore = useTransportStore();
  const floorStore = useFloorStore();
  const mapActionStore = useMapActionStore();
  const themeStore = useColorSetStore();

  const onClick = (transportType: string) => {
    navigate(PathType.floor);

    transportStore.setCurrentTransport(transportType);
    mapActionStore.setMapAction('navigation', true);
    floorStore.setCurrentFloorId(MapDraw.machine.floor.id);

    closeModal();
  };

  return (
    <div className={styles.container}>
      <div>{t('popup.transport.desc')}</div>

      <div className={styles.transport_wrapper}>
        {transportStore.transports.map(transport => (
          <div
            style={{ background: themeStore.sub }}
            className={styles.transport}
            key={transport}
            onClick={onClick.bind(this, transport)}
          >
            <div className={styles.icon}>
              <img src={`assets/popup/transport/${transport}.svg`} alt='transport' />
            </div>
            <span>{t(`popup.transport.method.${transport}`)}</span>
          </div>
        ))}
      </div>
    </div>
  );
};
export default TransportPopup;
