import useColorSetStore from 'stores/colorSet';
import useLanguageStore from 'stores/languages';
import { MultiLang } from 'types/common/language.type';
import styles from './CategoryNameTag.module.scss';

type Props = {
  categoryName: MultiLang;
};

const CategoryNameTag = ({ categoryName }: Props) => {
  const langStore = useLanguageStore();
  const colorSetStore = useColorSetStore();

  return (
    <div style={{ border: `1px solid ${colorSetStore.text.category}` }} className={styles.category_border}>
      <div style={{ color: colorSetStore.text.category }} className={styles.category_name}>
        {categoryName?.[langStore.currentLang]}
      </div>
    </div>
  );
};
export default CategoryNameTag;
