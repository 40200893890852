/* eslint-disable react/jsx-no-useless-fragment */
import { changeMapFloor } from 'map/control/floor';
import { useEffect, useState } from 'react';
import Slider from 'react-slick';
import useFacilityStore from 'stores/facility';
import useFloorStore from 'stores/floor';
import useLoadingStore from 'stores/loading';
import usePointStore from 'stores/point';
import useQrStore from 'stores/qr';
import { Floor, OrderedFloor } from 'types/common/floor.type';
import { changeMapPointSetting } from 'utils/map/changeMapSetting';
import styles from './FloorList.module.scss';
import FloorButton from './floor/FloorButton';
import MyLocationBtn from './my-location/MyLocationBtn';
import FloorSlider from './slider/FloorSlider';

type FloorListProps = {
  removeNoFacilityToast: () => void;
};

const FloorList = ({ removeNoFacilityToast }: FloorListProps) => {
  const floorStore = useFloorStore();
  const facilityStore = useFacilityStore();
  const qrStore = useQrStore();
  const pointStore = usePointStore();
  const loadingStore = useLoadingStore();

  // 층 슬라이드
  const [slide, setSlide] = useState<Slider | null>(null);
  const [orderedFloors, setOrderedFloors] = useState<OrderedFloor[]>([]);

  const isOverFiveFloors = floorStore.floors.length > 5;
  const isOverOneFloor = floorStore.floors.length > 1;

  /**
   * 층을 클릭한다
   * @param floor 클릭한 층
   */
  const onClickFloor = async (floor: Floor) => {
    loadingStore.setFloorChanging(true);

    removeNoFacilityToast();

    // 전역 상태값 초기화
    facilityStore.resetCurrentFacilityId();
    qrStore.setShowNaviQr(false);
    floorStore.setFloorZoomControl(true);

    // 층 변경
    await changeMapFloor(floor.id);
    floorStore.setCurrentFloorId(floor.id);
    floorStore.setFloorName(floor.name);

    if (pointStore.selectedPoint) {
      // 카메라 설정 및 센터값 변경
      changeMapPointSetting(pointStore.selectedPoint);
    }

    loadingStore.setFloorChanging(false);
  };

  /**
   * 슬라이드에 보여줄 층 목록을 설정한다
   */
  useEffect(() => {
    const floors: OrderedFloor[] = [...floorStore.floors]
      .map((floor, index) => ({
        ...floor,
        order: index,
      }))
      .sort(floor => floor.order - floor.order);

    setOrderedFloors(floors);
  }, [pointStore.selectedPoint]);

  return (
    <div className={`${styles.container} ${!isOverOneFloor && styles.no_floor}`}>
      <>
        {isOverOneFloor && (
          <>
            {/* 층 갯수 6개부터는 층 슬라이드 노출 */}
            {isOverFiveFloors ? (
              <FloorSlider floors={orderedFloors} slide={slide} setSlide={setSlide} onClickFloor={onClickFloor} />
            ) : (
              // 층이 5개 이하면 층 버튼 노출
              <div className={`${styles.floors}`}>
                {[...floorStore.floors].map(floor => {
                  return (
                    <FloorButton
                      key={floor.id}
                      floor={floor}
                      activeFloor={floorStore.currentFloorId === floor.id}
                      overFiveFloor={false}
                      onClickFloor={onClickFloor}
                    />
                  );
                })}
              </div>
            )}
          </>
        )}
      </>

      {/* 현재 위치 버튼 */}
      <MyLocationBtn floors={orderedFloors} slide={slide} />
    </div>
  );
};

export default FloorList;
