import { KeypadType, SearchTab, SearchTabType } from 'types/feature/tenantFilter.type';

export const SEARCH_FILTERS: SearchTab[] = [
  {
    type: SearchTabType.tenant,
    value: 'name',
  },
  {
    type: SearchTabType.floor,
    value: 'floor',
  },
  {
    type: SearchTabType.category,
    value: 'category',
  },
];

export const KEYPAD_TABS = [
  {
    type: KeypadType.chosung,
    value: 'ㄱㄴㄷ',
  },
  {
    type: KeypadType.alphabet,
    value: 'ABC',
  },
  {
    type: KeypadType.number,
    value: '123',
  },
];

export const KEYPAD_TABS_ENG = [
  {
    type: KeypadType.alphabet,
    value: 'ABC',
  },
  {
    type: KeypadType.number,
    value: '123',
  },
];
