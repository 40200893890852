import SwiperCore from 'swiper';
import { create } from 'zustand';

type SwiperStoreProps = {
  // 매장검색 페이지 스와이퍼용
  tenantSwiper: SwiperCore | null;
  setTenantSwiperState: (swiper: SwiperCore) => void;
  moveTenantSwiperToFirstSlide: () => void;

  // 커스텀 페이지 카드뷰 스와이퍼용
  cardSwiper: SwiperCore | null;
  setCardSwiperState: (swiper: SwiperCore) => void;
  moveCardSwiperToFirstSlide: () => void;
};

const useSwiperStore = create<SwiperStoreProps>((set, get) => ({
  tenantSwiper: null,

  setTenantSwiperState(swiper) {
    set(() => ({ tenantSwiper: swiper }));
  },

  moveTenantSwiperToFirstSlide() {
    get().tenantSwiper?.slideTo(0);
  },

  cardSwiper: null,

  setCardSwiperState(swiper) {
    set(() => ({ cardSwiper: swiper }));
  },

  moveCardSwiperToFirstSlide() {
    get().cardSwiper?.slideTo(0);
  },
}));

export default useSwiperStore;
