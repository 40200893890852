import { useEffect, useRef, useState } from 'react';

const useMultiTouchPopup = () => {
  const [showPopup, setShowPopup] = useState(false);

  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const showPopupForSeconds = () => {
    setShowPopup(true);

    timerRef.current = setTimeout(() => {
      setShowPopup(false);
    }, 4000);
  };

  const clearPopupAndTimer = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    setShowPopup(false);
  };

  useEffect(() => {
    showPopupForSeconds();

    return () => {
      clearPopupAndTimer();
    };
  }, []);

  return {
    showPopup,
  };
};

export default useMultiTouchPopup;
