import React, { useEffect, useState } from 'react';
import useSwiperStore from 'stores/swiper';
import SwiperCore, { Navigation, Pagination, Virtual } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/virtual';
import { Swiper } from 'swiper/react';
import { PaginationOptions } from 'swiper/types';
import './TenantSlide.scss';

type TenantSlideProps = {
  children: React.JSX.Element;
};

const TenantSlide = ({ children }: TenantSlideProps) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const swiperStore = useSwiperStore();

  // TODO: refactor
  // dependency current Index 에서 element 로 변경
  const handleEvent = () => {
    const leftClick = () => {
      swiperStore.tenantSwiper?.slidePrev();
    };
    const rightClick = () => {
      swiperStore.tenantSwiper?.slideNext();
    };

    const leftEl = document.querySelector('.left');
    const rightEl = document.querySelector('.right');

    leftEl?.addEventListener('click', leftClick);
    rightEl?.addEventListener('click', rightClick);
  };

  useEffect(() => {
    handleEvent();
  }, [currentIndex]);

  const handleRenderPagination = (swiperCore: SwiperCore, current: any, total: any) => {
    swiperStore.setTenantSwiperState(swiperCore);

    setCurrentIndex(total);

    let customPagination = '';
    // let endIndex;

    const quotient = Math.floor((current - 1) / 10);
    const startIndex = quotient * 10;

    // 이전 버튼
    // off
    if (current === 1) {
      customPagination += `<div class="left"><img src="assets/icon/pre.svg"/></div>`;
    } else {
      // on
      customPagination += `<div class="left"><img src="assets/icon/pre-active.svg"/></div>`;
    }

    // end index 구하는 식
    const endIndex = total;
    // if (total < 11) {
    //   endIndex = total;
    // } else {
    //   endIndex = startIndex + 10 < total ? startIndex + 10 : total - startIndex + (quotient + 1) * 10 - 10;
    // }

    // page 숫자
    customPagination += `
    <div class="page-wrapper">
      <span class="swiper-pagination-customs current" data-index=${current}>
        ${current}
      </span>
      <span class="swiper-pagination-customs slash">/</span>
      <span class="swiper-pagination-customs total">${endIndex}</span>
    </div>
  `;

    // 다음 버튼
    // off
    if (current === total) {
      customPagination += `<div class='right'><img src="assets/icon/next.svg"/></div>`;
    } else {
      // on
      customPagination += `<div class='right'><img src="assets/icon/next-active.svg"/></div>`;
    }

    return customPagination;
  };

  // pagination
  const pagination: PaginationOptions = {
    clickable: true,
    el: '.swiper-pagination',
    type: 'custom',
    renderCustom: handleRenderPagination,
  };

  return (
    <Swiper
      onInit={e => {
        swiperStore.setTenantSwiperState(e);
      }}
      onSlideChange={e => {
        setCurrentIndex(e.activeIndex);
      }}
      modules={[Navigation, Pagination, Virtual]}
      pagination={pagination}
      virtual
    >
      {children}
    </Swiper>
  );
};

export default TenantSlide;

// page bullet
// for (let i = startIndex; i < endIndex; i += 1) {
//   if (i === current - 1) {
//     customPagination += `<span class="swiper-pagination-customs swiper-pagination-customs-active" data-index=${i}></span>`;
//   } else {
//     customPagination += `<span class="swiper-pagination-customs bullet" data-index=${i} ></span>`;
//   }
// }
