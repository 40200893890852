import { useTranslation } from 'react-i18next';
import styles from './DisplayBadge.module.scss';

type DisplayBadgeProps = {
  displayType: string;
};

const DisplayBadge = ({ displayType }: DisplayBadgeProps) => {
  const { t } = useTranslation();

  return <div className={`${styles.container} ${styles[displayType.toLowerCase()]}`}>{t(`badge.${displayType}`)}</div>;
};

export default DisplayBadge;
