/* eslint-disable no-console */
import { ModalType } from 'component/common/modal/container/ModalContainer';
import useMultiContent from 'hooks/map/multi/useMultiContent';
import useMultiPoi from 'hooks/map/multi/useMultiPoi';
import { addBuilding, hideBuilding } from 'map/control/building';
import { clearMap } from 'map/control/common/clear';
import { findObject } from 'map/control/object';
import MapDraw from 'map/MapDraw';
import { DataObject, PoiData } from 'map/types/object';
import { useEffect } from 'react';
import useMapActionStore from 'stores/action';
import useCategoryGroupStore from 'stores/categoryGroup';
import useFacilityStore from 'stores/facility';
import useFloorStore from 'stores/floor';
import useLoadingStore from 'stores/loading';
import usePointStore from 'stores/point';
import useQrStore from 'stores/qr';
import useTenantStore from 'stores/tenant';
import { CategoryGroupWithTenants } from 'types/common/category.type';
import { Floor } from 'types/common/floor.type';
import { TenantWithPointId } from 'types/common/tenant.type';
import { changeMapPointSetting } from 'utils/map/changeMapSetting';

type Props = {
  openModal: (type: ModalType) => void;
};

// object-click, poi-click event 처리
const useMapClick = ({ openModal }: Props) => {
  // store
  const tenantStore = useTenantStore();
  const mapActionStore = useMapActionStore();
  const facilityStore = useFacilityStore();
  const pointStore = usePointStore();
  const qrStore = useQrStore();
  const categoryStore = useCategoryGroupStore();
  const floorStore = useFloorStore();
  const loadingStore = useLoadingStore();

  // hook
  const { handleMultiPoi } = useMultiPoi();
  const { handlePoiContent } = useMultiContent();

  // 층, 층 목록, 층 이름 변경
  const changeFloorRelatedStates = (pointId: string) => {
    const findFloors: Floor[] | undefined = floorStore.pointFloorsMap?.get(pointId);

    if (findFloors) {
      floorStore.setFloors(findFloors);

      const findMainFloor = findFloors.find(floor => floor.main);

      if (findMainFloor) {
        // 층
        floorStore.setCurrentFloorId(findMainFloor.id);
        // 층 이름
        floorStore.setFloorName(findMainFloor.name);
      }
    }
  };

  // 카테고리
  const changeCategory = (pointId: string) => {
    if (categoryStore.mapFacilityCategoriesMap) {
      const findCategories: CategoryGroupWithTenants | undefined = categoryStore.mapFacilityCategoriesMap.get(pointId);

      if (findCategories) {
        categoryStore.setCategoryList('MAP_FACILITY', findCategories);
      }
    }
  };

  // 포인트
  const changePointAndCategory = (pointId: string) => {
    const findPoint = pointStore.pointsMap?.get(pointId);

    if (findPoint) {
      pointStore.setSelectedPoint(findPoint);
      // 카테고리
      changeCategory(findPoint.id);
      // 카메라 설정 및 센터값 변경
      changeMapPointSetting(findPoint);
    }
  };

  // outdoor map click 시 building mask 를 제거하고 원하는 building 을 그리고 싶다.
  const handleClickOutdoorBuilding = async (refBuildingId: string) => {
    let outdoorBuildingId = '';

    // 우리가 가지고 있는 빌딩들의 outdoor 를 찾아서 만약 있다면 outdoorBuildingId 를 저장
    pointStore.pointsMap?.forEach(value => {
      if (value.buildingType === 'OUTDOOR') {
        outdoorBuildingId = value.buildingId;
      }
    });

    if (refBuildingId) {
      // 클릭한 빌딩이 outdoor 가 아니라면 outdoor 빌딩을 지우고 클릭한 빌딩을 그려라
      if (outdoorBuildingId !== refBuildingId) {
        hideBuilding(outdoorBuildingId);
        await addBuilding(refBuildingId);

        const findPoint = pointStore.buildingPointsMap?.get(refBuildingId);
        if (findPoint) {
          // 층, 층 목록, 층 이름 변경
          changeFloorRelatedStates(findPoint.id);
          // 포인트, 카테고리
          changePointAndCategory(findPoint.id);
        }
      }
    }
  };

  // 상태 초기화
  const resetState = () => {
    mapActionStore.resetMapActions();
    facilityStore.resetCurrentFacilityId();
    tenantStore.resetCurrentTenant();
    qrStore.setShowNaviQr(false);
  };

  /**
   * object click
   * @param {DataObject[]} findValidObjects
   */
  const handleClickObject = (findValidObjects: DataObject[]): void => {
    const multiPoiObjects = findValidObjects.filter(object => object.poiDataArr.length > 1);

    if (multiPoiObjects.length > 0) {
      // ! multi pois
      console.log('object-click 다중 pois', multiPoiObjects[0]);
      handleMultiPoi(multiPoiObjects[0].poiDataArr, openModal);
    } else {
      // ! single poi, single / multi contents
      console.log('object-click 단일 poi');
      const findTenants = tenantStore.poiTenantsMap?.get(findValidObjects[0].poiDataArr[0].id);
      if (findTenants) {
        handlePoiContent(findTenants, openModal, findValidObjects[0].position);
      }
    }
  };

  /**
   * object-click 시 실행하는 함수
   * @param objectClickEvent object click 시 event.detail 은 클릭한 object[] 정보와 같음
   */
  const objectClick = async (objectClickEvent: any) => {
    clearMap();
    resetState();

    const objects = objectClickEvent.detail;

    // poi 가 들어있지 않는 object 는 필요없다.
    const findValidObjects: DataObject[] = objects.filter((object: DataObject) => object.poiDataArr.length > 0);
    if (findValidObjects.length < 1) return;
    handleClickObject(findValidObjects);

    // outdoor 에서 indoor 클릭해서 indoor 로 들어온다.
    const refBuildingObject: DataObject | undefined = objects.find((object: DataObject) => object.refBuildingId);
    if (refBuildingObject && refBuildingObject.refBuildingId) {
      await handleClickOutdoorBuilding(refBuildingObject.refBuildingId);
    }
  };

  /**
   * poi-click 시 실행하는 함수
   * @param poiClickEvent poi click 시 event.detail 은 클릭한 poi[] 정보와 같음
   */
  const poiClick = async (poiClickEvent: any) => {
    clearMap();
    resetState();

    const pois: PoiData[] = poiClickEvent.detail;
    if (pois.length < 1) return;

    if (pois.length > 1) {
      // ! multi pois
      console.log('poi-click 다중 pois', pois);
      handleMultiPoi(pois, openModal);
    } else {
      // ! single poi, single / multi contents
      console.log('poi-click 단일 poi', pois);
      const findTenants = tenantStore.poiTenantsMap?.get(pois[0].id);
      if (findTenants) {
        handlePoiContent(findTenants, openModal, pois[0].position);
      }
    }

    // outdoor 에서 indoor 클릭해서 indoor 로 들어온다.
    const clickedObject = findObject({ id: pois[0].objectId });
    if (clickedObject && clickedObject.refBuildingId) {
      await handleClickOutdoorBuilding(clickedObject.refBuildingId);
    }
  };

  useEffect(() => {
    if (!loadingStore.isLoadingMap) {
      if (MapDraw.mapContainer) {
        MapDraw.mapContainer.addEventListener('poi-click', poiClick);
        MapDraw.mapContainer.addEventListener('object-click', objectClick);
      }
    }

    return () => {
      if (MapDraw.mapContainer) {
        MapDraw.mapContainer.removeEventListener('poi-click', poiClick);
        MapDraw.mapContainer.removeEventListener('object-click', objectClick);
      }
    };
  }, [loadingStore.isLoadingMap, MapDraw.mapContainer]);
};

export default useMapClick;
