import MapDraw from 'map/MapDraw';
import { PositionNum, PositionStr } from 'map/types/position';
import { Marker } from 'types/dabeeomaps/marker.type';
import { clearAllMarkers } from './common/clear';

// 마커 한 개 표시
export const drawMarker = async (floorId: string, position: PositionStr | PositionNum) => {
  clearAllMarkers();

  await MapDraw.map.markers.set({
    marker: [
      {
        x: Math.abs(Number(position.x)),
        y: Math.abs(Number(position.y)),
        iconOption: {
          // positionZ: 50,
          // anchor: {
          //   x: 0.5,
          //   y: 0.5,
          // },
          iconUrl: '/assets/icon/arrive.png',
          width: 50,
          height: 70,
          visibleIcon: true,
        },
        floorId,
      },
    ],
  });
};

// 마커 여러개 표시
export const drawMarkers = async (markers: Marker[]) => {
  clearAllMarkers();

  await MapDraw.map.markers.set({ marker: markers });
};
