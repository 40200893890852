import MapDraw from 'map/MapDraw';

// 모든 마커 제거
export const clearAllMarkers = () => {
  if (MapDraw.map) {
    MapDraw.map.markers.clearAll();
  }
};

// 길찾기 내비게이션 제거
export const clearNavigation = () => {
  if (MapDraw.map) {
    MapDraw.map.routeSimulation.stop();
    MapDraw.map.routeSimulation.clear();
  }
};

// 내위치 gps 제거
export const clearMyLocation = () => {
  if (MapDraw.map) {
    MapDraw.map.mylocation.clear();
  }
};

// 지도 clear : 마커 제거 + 길찾기 경로 제거
export const clearMap = () => {
  clearAllMarkers();
  clearNavigation();
};
