import useColorSetStore from 'stores/colorSet';
import useMachineStore from 'stores/machine';
import Clock from '../clock/Clock';
import BusinessHour from '../info/business-hour/BusinessHour';
import ClosedDays from '../info/closed-days/ClosedDays';
import styles from './HoriMallInfo.module.scss';

const HoriMallInfo = () => {
  // store
  const machineStore = useMachineStore();
  const colorSetStore = useColorSetStore();

  /**
   * @desc 휴무일 노출 조건
   */
  const isShowClosedDays = machineStore.machine?.point.closedDaysUsed;

  /**
   * @desc 운영시간과 휴무일 사용하지 않고, 대체 텍스트도 없을 경우 시계를 노출한다.
   */
  const isShowClock =
    !isShowClosedDays && !machineStore.machine?.point.hoursUsed && !machineStore.machine?.point.hoursDescription;

  return (
    <div
      className={styles.mall_info_wrapper}
      style={{
        background: colorSetStore.footer.bg,
      }}
    >
      {isShowClock ? (
        // 시계
        <Clock />
      ) : (
        // 운영시간, 휴무일
        <div className={styles.mall_info}>
          {/* 운영시간 */}
          <BusinessHour />

          {/* 휴무일 */}
          {isShowClosedDays && <ClosedDays />}
        </div>
      )}
    </div>
  );
};
export default HoriMallInfo;
