import { ModalType } from 'component/common/modal/container/ModalContainer';
import useCookie from 'hooks/cookie/useCookie';
import useKioskLogin from 'hooks/login/useKioskLogin';
import ICON_KEYBOARD from 'assets/login/keyboard.svg';
import { isEmpty } from 'lodash';
import { useRef, useState, FocusEvent } from 'react';
import { KeyboardReactInterface } from 'react-simple-keyboard';
import NotifyCapsLock from '../capslock/NotifyCapsLock';
import InputForm from '../form/InputForm';
import LoginHeader from '../header/LoginHeader';
import VirtualKeyboard from '../keyboard/VirtualKeyboard';
import SaveAccount from '../save-btn/SaveAccount';
import styles from './LoginContainer.module.scss';

type LoginContainerProps = {
  openModal: (type: ModalType) => void;
};

const LoginContainer = ({ openModal }: LoginContainerProps) => {
  const [isRemember, setIsRemember] = useState(false);
  const [showKeyboard, setShowKeyboard] = useState(false);
  const [activeInputName, setActiveInputName] = useState('username');
  const [notifyCapsLock, setNotifyCapsLock] = useState('');

  const keyboardRef = useRef<KeyboardReactInterface | null>(null);

  const userRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);

  const {
    login,
    handleLoginUser,
    // handleLoginUserByKeyboard,
    deleteLoginInfo,
    loginInfo,
    completeFillOutLoginForm,
    handleSearchedValue,
    removeOneValue,
  } = useKioskLogin({
    openModal,
    isUserRemember: isRemember,
  });
  const { getCookie } = useCookie();

  const isRememberUsernameInCookie = getCookie('id');

  const handleOnKeyup = (e: any) => {
    if (e.getModifierState('CapsLock')) {
      setNotifyCapsLock('CapsLock이 켜져 있습니다.');
    } else {
      setNotifyCapsLock('');
    }
  };

  // 가상 키보드 노출
  const handleShowKeyboard = () => {
    if (showKeyboard) {
      setShowKeyboard(false);
      return;
    }
    setShowKeyboard(true);

    // 아이디, 패스워드 focus 처리
    if (userRef.current && passwordRef.current) {
      if (!isEmpty(userRef.current.value) && !isEmpty(passwordRef.current.value)) return;
      // 아이디가 입력되지 않은 경우 아이디 input focus
      if (isEmpty(userRef.current.value)) {
        userRef.current.focus();
        // 패스워드 입력되지 않은 경우 패스워드 input focus
      } else if (passwordRef.current) {
        passwordRef.current.focus();
      }
    }
  };

  // 가상 키보드에서 active된 ref의 이름 상태 관리
  const handleInputFocus = (e: FocusEvent<HTMLInputElement>) => {
    setShowKeyboard(true);
    const { name } = e.target;
    setActiveInputName(name);
  };

  return (
    <>
      <div className={styles.container}>
        <LoginHeader />
        <form className={styles.form} onSubmit={login.bind(this)}>
          <div className={styles.box}>
            <div className={styles.input}>
              <InputForm
                inputKey='username'
                inputRef={userRef}
                type='text'
                placeholder='아이디'
                name='username'
                value={loginInfo.username}
                onChangeValue={handleLoginUser}
                onKeyUpValue={() => {}}
                onFocus={handleInputFocus}
                deleteInput={deleteLoginInfo}
                keyboardRef={keyboardRef}
              />
              <InputForm
                inputKey='password'
                inputRef={passwordRef}
                type='password'
                placeholder='비밀번호'
                name='password'
                value={loginInfo.password}
                onChangeValue={handleLoginUser}
                onKeyUpValue={handleOnKeyup}
                onFocus={handleInputFocus}
                deleteInput={deleteLoginInfo}
                keyboardRef={keyboardRef}
              />
              <NotifyCapsLock notifyText={notifyCapsLock} />
            </div>
            <div className={styles.options}>
              <SaveAccount
                isRememberUsernameInCookie={isRememberUsernameInCookie || ''}
                isRemember={isRemember}
                setIsRemember={setIsRemember}
              />
              <div className={styles.keyboard} onClick={handleShowKeyboard}>
                <div className={styles.icon_keyboard}>
                  <img src={ICON_KEYBOARD} alt='keyboard' />
                </div>
                {showKeyboard ? '키보드 닫기' : '가상 키보드'}
              </div>
            </div>
          </div>

          <button type='submit' className={`${styles.button} ${completeFillOutLoginForm && styles.active}`}>
            로그인
          </button>
        </form>
      </div>
      {showKeyboard && (
        <VirtualKeyboard
          keyboardRef={keyboardRef}
          activeInputName={activeInputName}
          userRef={userRef}
          passwordRef={passwordRef}
          // handleLoginUserByKeyboard={handleLoginUserByKeyboard}
          handleSearchedValue={handleSearchedValue}
          removeOneValue={removeOneValue}
        />
      )}
    </>
  );
};

export default LoginContainer;
