import FloorContainer from 'component/floor/FloorContainer';
import styles from './Pages.module.scss';

const FloorPage = () => {
  return (
    <div className={styles.floor}>
      <FloorContainer />
    </div>
  );
};

export default FloorPage;
