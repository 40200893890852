import { TenantWithPointId } from 'types/common/tenant.type';
import styles from './BoothNumber.module.scss';

type Props = {
  tenant: TenantWithPointId;
};

const BoothNumber = ({ tenant }: Props) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.divider} />

      <div className={styles.booth_number}>{tenant.boothNumber}</div>
    </div>
  );
};
export default BoothNumber;
