import HoriScreen from 'assets/screen/default_horizontal.svg';
import VertiScreen from 'assets/screen/default_vertical.svg';
import { Fragment, useRef, useState } from 'react';
import useScreenTypeStore from 'stores/screenType';
import useScreensaverStore from 'stores/screensaver';
import SwiperCore, { Autoplay, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ScreenType } from 'types/common/common.type';
import { Screensaver } from 'types/feature/screensaver.type';
import styles from './Screensaver.module.scss';
import Image from './media/Image';
import Video from './media/Video';

const ScreensaverContainer = () => {
  const [swiperState, setSwiperState] = useState<SwiperCore>();
  const screensaverStore = useScreensaverStore();
  const { screenType } = useScreenTypeStore();
  const timer = useRef<NodeJS.Timeout | null>(null);

  const defaultImage = screenType === ScreenType.verti ? VertiScreen : HoriScreen;

  const modules = [Autoplay, Navigation];

  const goNextSlide = () => {
    swiperState?.slideNext(1500);
  };
  const goNextSlideAfterSeconds = () => {
    timer.current = setTimeout(() => {
      goNextSlide();
    }, 8000);
  };

  return (
    <div className={styles.container}>
      {screensaverStore.succeededMedia.length === 0 ? (
        <Image imageUrl={defaultImage} isActive goNextSlide={() => {}} />
      ) : (
        <Swiper
          style={{ height: '100%' }}
          loop
          modules={modules}
          onInit={swiper => setSwiperState(swiper)}
          onSlideChange={() => {
            if (timer.current) {
              clearTimeout(timer.current);
            }
          }}
        >
          {screensaverStore.succeededMedia
            .sort((a: Screensaver, b: Screensaver) => a.sortOrder - b.sortOrder)
            .map(({ fileUri, fileType, fileName, id }, index) => {
              return (
                <Fragment key={id}>
                  <SwiperSlide key={`${id}`}>
                    {({ isActive }) =>
                      fileType === 'IMAGE' ? (
                        <Image
                          key={index}
                          imageUrl={fileUri}
                          isActive={isActive}
                          goNextSlide={goNextSlideAfterSeconds}
                        />
                      ) : (
                        <Video key={index} videoUrl={fileUri} isActive={isActive} goNextSlide={goNextSlide} />
                      )
                    }
                  </SwiperSlide>
                  {fileType === 'VIDEO' && screensaverStore.succeededMedia.length === 1 && (
                    <SwiperSlide>
                      {({ isActive }) => (
                        <Video key={index} videoUrl={fileUri} isActive={isActive} goNextSlide={goNextSlide} />
                      )}
                    </SwiperSlide>
                  )}
                </Fragment>
              );
            })}
        </Swiper>
      )}
    </div>
  );
};

export default ScreensaverContainer;
