/* eslint-disable react/jsx-no-useless-fragment */
import { t } from 'i18next';
import useColorSetStore from 'stores/colorSet';
import useMachineStore from 'stores/machine';
import styles from './ClosedDays.module.scss';

const ClosedDays = () => {
  const machineStore = useMachineStore();
  const colorSetStore = useColorSetStore();

  const textColor = colorSetStore.footer.text;
  const borderColor = `${`1px solid ${colorSetStore.footer.text}`}`;

  return (
    <>
      <div className={styles.container}>
        <>
          {machineStore.machine && (
            <>
              <div
                style={{
                  border: borderColor,
                  color: textColor,
                }}
                className={styles.box}
              >
                {t(`info.holiday`)}
              </div>
              <div style={{ color: textColor }} className={styles.desc}>
                {machineStore.machine.point.closedDaysUsed && machineStore.machine.point.closedDays?.length > 0 ? (
                  machineStore.getClosedDays().map((date, index) => {
                    if (index % 2 === 0 && index !== machineStore.getClosedDays().length - 1) {
                      return <div key={index} className={styles.closed_day}>{`${date},`}</div>;
                    }
                    return (
                      <div key={index} className={styles.closed_day}>
                        {date}
                      </div>
                    );
                  })
                ) : (
                  <div className={styles.noHoliday}>{t(`info.noHoliday`)}</div>
                )}
              </div>
            </>
          )}
        </>
      </div>
    </>
  );
};

export default ClosedDays;
