import { changeMapLanguage } from 'map/control/language';
import { LangType } from 'map/types/floor';
import { LangCode } from 'types/common/language.type';

// 지도 언어코드로 변경
export const changeLangCodeForMap = (lang: string) => {
  let langCodeForMap = lang as LangType;

  // TODO: 중국어 번체는 추가 예정
  if (lang === LangCode.cn || lang === LangCode.tw) {
    langCodeForMap = 'zh-CN';
  }

  changeMapLanguage(langCodeForMap);
};
