import ArrowLeft from 'assets/customBoard/arrow-left.svg';
import ArrowTop from 'assets/customBoard/here.svg';
import { Fragment, useEffect, useRef, useState } from 'react';
import useCustomBoardStore from 'stores/customBoard';
import useScreenTypeStore from 'stores/screenType';
import { ScreenType } from 'types/common/common.type';
import ListCustomBoardItem from './item/ListCustomBoardItem';
import styles from './ListCustomBoardContainer.module.scss';

const ListCustomBoardContainer = () => {
  const customBoardStore = useCustomBoardStore();
  const screenTypeStore = useScreenTypeStore();

  const scrollRef = useRef<HTMLDivElement>(null);
  const horiScrollRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLDivElement>(null);
  const horiListRef = useRef<HTMLDivElement>(null);

  const [showTopButton, setShowTopButton] = useState(false);

  const handleScrollRef = () => {
    if (listRef.current && horiListRef.current) {
      if (screenTypeStore.screenType === ScreenType.verti) {
        if (listRef.current.scrollTop !== 0) {
          setShowTopButton(true);
        }

        if (listRef.current.scrollTop === 0) {
          setShowTopButton(false);
        }
      }

      if (screenTypeStore.screenType === ScreenType.hori) {
        if (horiListRef.current.scrollLeft !== 0) {
          setShowTopButton(true);
        }

        if (horiListRef.current.scrollLeft === 0) {
          setShowTopButton(false);
        }
      }
    }
  };

  useEffect(() => {
    if (listRef.current && horiListRef.current) {
      if (screenTypeStore.screenType === ScreenType.verti) {
        listRef.current.addEventListener('scroll', handleScrollRef);
      }

      if (screenTypeStore.screenType === ScreenType.hori) {
        horiListRef.current.addEventListener('scroll', handleScrollRef);
      }
    }

    return () => {
      if (listRef.current && horiListRef.current) {
        if (screenTypeStore.screenType === ScreenType.verti) {
          listRef.current.removeEventListener('scroll', handleScrollRef);
        }

        if (screenTypeStore.screenType === ScreenType.hori) {
          horiListRef.current.removeEventListener('scroll', handleScrollRef);
        }
      }
    };
  }, []);

  const scrollToListTop = () => {
    if (screenTypeStore.screenType === ScreenType.hori) {
      if (horiScrollRef.current) {
        horiScrollRef.current.scrollIntoView({ behavior: 'smooth', inline: 'start' });
      }
    }

    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.custom_board_list} ref={listRef}>
        <div ref={scrollRef} className={styles.scroll} />
        <div className={styles.list} ref={horiListRef}>
          {screenTypeStore.screenType === ScreenType.hori && <div ref={horiScrollRef} />}
          {customBoardStore.currentCustomBoards?.map((board, boardIndex) => (
            <Fragment key={board.id}>
              <ListCustomBoardItem
                key={board.id}
                isLast={customBoardStore.currentCustomBoards.length - 1 === boardIndex}
                customBoard={board}
              />
            </Fragment>
          ))}
        </div>
      </div>

      {/* scroll top btn */}
      {showTopButton && (
        <div className={styles.top_button} onClick={scrollToListTop}>
          <div className={styles.icon}>
            <img src={screenTypeStore.screenType === ScreenType.hori ? ArrowLeft : ArrowTop} alt='arrow' />
          </div>
        </div>
      )}
    </div>
  );
};

export default ListCustomBoardContainer;
