import { LangCode, MultiLang } from 'types/common/language.type';

/**
 * 다국어를 사용하는 object 의 값이 비어있다면 main 언어의 값을 넣은 새로운 object 를 반환하고
 * main lang 의 값도 비어있다면 그대로 반환한다.
 */
export const convertMultiLang = (object: MultiLang, main: LangCode) => {
  const mainLang = main.toLowerCase();

  // main 언어가 비어있다면 그대로 반환
  if (!object?.[mainLang]) {
    return object;
  }

  // object value 가 비어있으면 main value 를 넣어준다.
  const langObject = object;

  if (!object.ko) {
    langObject.ko = object[mainLang];
  }

  if (!object.en) {
    langObject.en = object[mainLang];
  }

  if (!object.ja) {
    langObject.ja = object[mainLang];
  }

  if (!object.cn) {
    langObject.cn = object[mainLang];
  }

  if (!object.tw) {
    langObject.tw = object[mainLang];
  }

  return langObject as MultiLang;
};
