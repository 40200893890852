/* eslint-disable @typescript-eslint/no-unused-vars */
import useStats from 'hooks/fetch/stats/useStats';
import { useRef, useState } from 'react';
import useTenantStore from 'stores/tenant';
import { TenantWithPointId } from 'types/common/tenant.type';
import styles from './MultiTenants.module.scss';
import TenantSelectItem from './item/TenantSelectItem';

type Props = {
  tenantList: TenantWithPointId[];
  closeModal: () => void;
};

// 층별안내의 다중 컨텐츠 목록
const MultiTenants = ({ tenantList, closeModal }: Props) => {
  const [isScrollTop, setScrollTop] = useState(false);
  const [isScrollBottom, setScrollBottom] = useState(false);

  const scrollRef = useRef<HTMLDivElement>(null);

  const tenantStore = useTenantStore();

  const { postTenantUsage } = useStats();

  const handleShowTopDim = (state: boolean) => setScrollTop(state);
  const handleShowBottomDim = (state: boolean) => setScrollBottom(state);

  // 테넌트 클릭 시
  const onClickItem = async (tenant: TenantWithPointId) => {
    // 매장 사용량 수집
    await postTenantUsage(tenant.id, 'DETAIL_OF_FLOOR'); // 층별안내

    tenantStore.setCurrentTenant(tenant);
  };

  // 테넌트 목록 클릭 시
  const onClickList = (e: React.MouseEvent) => {
    if (e.target !== e.currentTarget) return;
    closeModal();
  };

  const modalStyle = tenantStore.currentTenant?.popupType === 'SMALL' ? styles.simple : styles.default;

  return (
    <div className={`${styles.container} ${modalStyle}`} ref={scrollRef} onClick={e => onClickList(e)}>
      <div className={`${styles.wrapper}`} onClick={e => onClickList(e)}>
        {tenantList.map((tenant, index) => (
          <TenantSelectItem
            isFirst={index === 0}
            isLast={index === tenantList.length - 1}
            handleShowBottomDim={handleShowBottomDim}
            handleShowTopDim={handleShowTopDim}
            key={index}
            tenant={tenant}
            onClick={onClickItem}
            isActive={tenant.id === tenantStore.currentTenant?.id}
          />
        ))}
      </div>
    </div>
  );
};

export default MultiTenants;
