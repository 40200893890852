import { CategoryGroupWithTenants, TenantCategoryGroup } from 'types/common/category.type';
import { LangCode } from 'types/common/language.type';
import { convertMultiLang } from 'utils/multi-lang/convertMultiLang';

export const convertCategoryListToMap = (
  tenantCategoryGroups: TenantCategoryGroup[] | undefined,
  mainLang: LangCode,
) => {
  const categoryGroupMap = new Map<string, CategoryGroupWithTenants>();

  tenantCategoryGroups?.forEach(tenantCategoryGroup => {
    categoryGroupMap.set(tenantCategoryGroup.pointId, {
      pointId: tenantCategoryGroup.pointId,
      categoryGroups: tenantCategoryGroup.categoryGroups.map(categoryGroup => {
        return {
          ...categoryGroup,
          name: convertMultiLang(categoryGroup.name, mainLang),
        };
      }),
      tenants: [],
    });
  });

  return categoryGroupMap;
};
