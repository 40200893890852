/* eslint-disable react-hooks/exhaustive-deps */
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import useCategoryGroupStore from 'stores/categoryGroup';
import useColorSetStore from 'stores/colorSet';
import useFloorStore from 'stores/floor';
import useLanguageStore from 'stores/languages';
import usePointStore from 'stores/point';
import useSwiperStore from 'stores/swiper';
import useTenantStore from 'stores/tenant';
import { Floor } from 'types/common/floor.type';
import { TenantWithPointId } from 'types/common/tenant.type';
import { groupingFloors } from 'utils/tenant/floor/groupingFloors';
import styles from './FloorController.module.scss';

const FloorController = () => {
  // store
  const swiperStore = useSwiperStore();
  const floorStore = useFloorStore();
  const langStore = useLanguageStore();
  const tenantStore = useTenantStore();
  const pointStore = usePointStore();
  const categoryStore = useCategoryGroupStore();
  const colorSetStore = useColorSetStore();

  // state
  const [validFloors, setValidFloors] = useState<Floor[]>([]);
  const [activeFloorId, setActiveFloorId] = useState('ALL');

  // 테넌트 목록 세팅
  const handleFilteredTenants = (floorId: string) => {
    if (categoryStore.tenantSearchCategories) {
      const originTenants: TenantWithPointId[] = categoryStore.tenantSearchCategories.tenants;

      if (floorId === 'ALL') {
        // 전체
        tenantStore.setFilteredTenants(originTenants, langStore.currentLang);
      } else {
        // 전체 아닐 경우
        const findTenants: TenantWithPointId[] = originTenants.filter(tenant => tenant.content.floorId === floorId);

        tenantStore.setFilteredTenants(findTenants, langStore.currentLang);
      }
    }
  };

  // 테넌트가 없는 층은 걸러줌 (ex. 주차장)
  const filterEmptyFloors = () => {
    if (categoryStore.tenantSearchCategories) {
      const originTenants: TenantWithPointId[] = categoryStore.tenantSearchCategories.tenants;

      const groupedTenantByFloor = groupingFloors(originTenants, floorStore.floors);
      const groupedFloors = floorStore.floors.filter(floor => groupedTenantByFloor[floor.name.ko]);
      const combinedFloors = [...groupedFloors].reverse();

      setValidFloors(combinedFloors);
    }
  };

  // 층 클릭 시
  const onClickFloor = (id: string) => {
    swiperStore.moveTenantSwiperToFirstSlide();

    setActiveFloorId(id);
    handleFilteredTenants(id);
  };

  /**
   * active 층 관리
   * 층이 한 개일 경우 1번째가 active 된다.
   * 층이 없거나 여러 개일 경우 전체 층이 active 된다.
   */
  const handleActiveFloor = () => {
    if (validFloors.length === 1) {
      setActiveFloorId(validFloors[0].id);
    } else {
      setActiveFloorId('ALL');
    }
  };

  // useEffect(() => {
  //   handleFilteredTenants(activeFloorId);
  // }, []);

  useEffect(() => {
    swiperStore.moveTenantSwiperToFirstSlide();
  }, [langStore.currentLang]);

  useEffect(() => {
    filterEmptyFloors();
  }, [floorStore.floors]);

  useEffect(() => {
    handleActiveFloor();
  }, [langStore.currentLang, floorStore.floors, pointStore.point, validFloors]);

  // 층이 9개 이하일 경우 css 달라짐
  const floorsLessThanNine = validFloors.length <= 9;

  return (
    <div className={`${styles.floor_controller} ${floorsLessThanNine && styles.lessFloor}`}>
      {/* 전층 탭 - 유효한 층이 1개가 아닐 경우(없거나 여러개일 경우)에만 보여짐 */}
      {validFloors.length !== 1 && (
        <div
          style={{
            color: activeFloorId === 'ALL' ? colorSetStore.tabButton.active : colorSetStore.tabButton.inactive,
          }}
          onClick={onClickFloor.bind(this, 'ALL')}
          className={`${styles.floor} ${activeFloorId === 'ALL' && styles.active}`}
        >
          {t('tenant.allFloor')}

          {/* TODO: 밑줄 */}
          {activeFloorId === 'ALL' && (
            <div
              style={{
                background: colorSetStore.tabButton.active,
              }}
              className={styles.border_after}
            />
          )}
        </div>
      )}

      {validFloors?.map(({ id, name }) => (
        <div
          className={`${styles.floor}`}
          style={{ color: activeFloorId === id ? colorSetStore.tabButton.active : colorSetStore.tabButton.inactive }}
          onClick={onClickFloor.bind(this, id)}
          key={id}
        >
          {name[langStore.currentLang]}

          {/* TODO: 밑줄 */}
          {activeFloorId === id && (
            <div
              style={{
                background: colorSetStore.tabButton.active,
              }}
              className={styles.border_after}
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default FloorController;
