/* eslint-disable no-console */
import AuthCheck from 'component/common/auth/AuthCheck';
import { logConsole } from 'component/common/console/console';
import Layout from 'component/common/layout/Layout';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import useFetchServerApis from 'hooks/fetch/useFetchServerApis';
import useResetSection from 'hooks/reset/useResetSection';
import useScript from 'hooks/script/useScript';
import MapDraw from 'map/MapDraw';
import { useEffect } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes.config';
import useMenuStore from 'stores/menu';
import useMultiLangStore from 'stores/multiLang';
import useScreenTypeStore from 'stores/screenType';
import { PathType } from 'types/common/common.type';
import { validResolution } from 'utils/resolution/validResolution';
import { returnScreenType } from 'utils/screen-type/returnScreenType';

function App() {
  // 지도 script 불러오기
  useScript();

  // 일정시간 후 홈으로 돌아오도록 처리
  useResetSection();

  // hook
  const location = useLocation();
  const navigate = useNavigate();
  const { fetchApiLoaded, machineAccessToken } = useFetchServerApis();

  // store
  const screenTypeStore = useScreenTypeStore();
  const multiLangStore = useMultiLangStore();
  const menuStore = useMenuStore();

  // 초기 로딩 완료 여부
  const isInitialLoading = !fetchApiLoaded || !MapDraw.mapData;

  // 사용할 수 없는 해상도에서 앱에 접근 시 에러페이지로 이동
  const moveToResolutionErrorPage = () => {
    if (!validResolution()) {
      navigate(PathType.resolutionError);
    }
  };

  // 현재 앱의 screenType 반환 (가로형/세로형/정방형)
  const handleScreenType = () => {
    screenTypeStore.setScreenType(returnScreenType());

    moveToResolutionErrorPage();
  };

  useEffect(() => {
    logConsole('📐 Screen Type :', screenTypeStore.screenType);
  }, [screenTypeStore.screenType]);

  useEffect(() => {
    logConsole('📱 User Agent :', (window.navigator as any).userAgentData);
    logConsole('current app mode :', process.env.REACT_APP_ENVIRONMENT);

    handleScreenType();
    dayjs.locale('ko');

    window.addEventListener('resize', handleScreenType);

    return () => {
      window.removeEventListener('resize', handleScreenType);
    };
  }, []);

  /**
   * @desc menuStore.mainMenus, location 때 마다 현재메뉴를 저장한다.
   */
  useEffect(() => {
    if (menuStore.mainMenus.length > 0) {
      menuStore.setCurMenu(location, menuStore.mainMenus);
    }
  }, [menuStore.mainMenus, location]);

  return (
    <div onClick={multiLangStore.closeMultiLang} className={`App ${screenTypeStore.screenType}`}>
      <Layout>
        <Routes>
          <Route path='*' element={<Navigate to={PathType.notFound} />} />
          <Route path='/' element={<Navigate to={PathType.home} />} />
          {ROUTES.map(({ path, isProtected, isError, screen }) => (
            <Route
              key={path}
              path={path}
              element={
                <AuthCheck
                  screen={screen}
                  isProtected={isProtected}
                  isError={isError}
                  isLoading={isInitialLoading}
                  token={machineAccessToken}
                />
              }
            />
          ))}
        </Routes>
      </Layout>
    </div>
  );
}

export default App;
