/* eslint-disable no-return-assign */
class Replacement {
  constructor(private str: string) {}

  setAmPm() {
    return this.str === '오후' || this.str === 'PM' || this.str === '午後' || this.str === '晚上'
      ? (this.str = 'PM')
      : (this.str = 'AM');
  }
}

export default Replacement;
