import { COLORS } from 'constants/colors';
import useLanguageStore from 'stores/languages';
import useColorSetStore from 'stores/colorSet';
import { CategoryGroup } from 'types/common/category.type';
import styles from './FacilityItem.module.scss';

type FacilityItemProps = {
  handleClickItem: (categoryGroup: CategoryGroup) => void;
  isActive: boolean;
  categoryGroup: CategoryGroup;
};

const FacilityItem = ({ handleClickItem, isActive, categoryGroup }: FacilityItemProps) => {
  const languageStore = useLanguageStore();
  const colorSetStore = useColorSetStore();

  return (
    <div className={`${styles.container}`} onClick={handleClickItem.bind(this, categoryGroup)}>
      {/* //TODO: 추후에 편의시설 아이콘 나오면 주석 해제 */}
      {/* <div className={styles.icon}>
        <img
          src={`/assets/facility/${code}.svg`}
          alt='facility'
          onError={e => {
            const el = e.target as HTMLImageElement;
            el.src = Default;
          }}
        />
      </div> */}
      <div
        style={{
          background: isActive ? colorSetStore.sub : 'transparent',
          borderColor: isActive ? colorSetStore.sub : colorSetStore.button.border,
          color: isActive ? COLORS.WHITE : colorSetStore.button.text,
        }}
        className={`${styles.name} ${isActive && styles.active}`}
      >
        {categoryGroup.name[languageStore.currentLang]}
      </div>
    </div>
  );
};

export default FacilityItem;

// ! deprecated 2023-10-06
// 마커 그리고 첫번째로 카메라 이동
