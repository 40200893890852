import ARROW_DOWN from 'assets/main/arrow/arrow-down-gy.svg';
import ARROW_UP from 'assets/main/arrow/arrow-up-gy.svg';
import ICON_INDOOR from 'assets/point/indoor.svg';
import ICON_OUTDOOR from 'assets/point/outdoor.svg';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import useCategoryGroupStore from 'stores/categoryGroup';
import useColorSetStore from 'stores/colorSet';
import useFloorStore from 'stores/floor';
import useLanguageStore from 'stores/languages';
import useMachineStore from 'stores/machine';
import usePointStore from 'stores/point';
import useTenantStore from 'stores/tenant';
import { CategoryGroupWithTenants } from 'types/common/category.type';
import { Floor } from 'types/common/floor.type';
import { Point } from 'types/common/point.type';
import { SearchTabType } from 'types/feature/tenantFilter.type';
import styles from './FilterPointSelect.module.scss';

type Props = {
  filterType: SearchTabType;
};

// 층별안내 포인트 선택
const FilterPointSelect = ({ filterType }: Props) => {
  const pointStore = usePointStore();
  const machineStore = useMachineStore();
  const floorStore = useFloorStore();
  const tenantStore = useTenantStore();
  const langStore = useLanguageStore();
  const categoryGroupStore = useCategoryGroupStore();
  const themeStore = useColorSetStore();

  const [isOpen, setIsOpen] = useState(false);

  // select on/off
  const handleOpenOptions = () => {
    if (filterType === SearchTabType.tenant) {
      return;
    }

    setIsOpen(prev => !prev);
  };

  // 현재 포인트의 categories 와 filteredTenants 세팅
  const setPointCategoriesAndTenants = (pointId: string | undefined) => {
    if (categoryGroupStore.tenantSearchCategoriesMap && pointId) {
      const findCategory: CategoryGroupWithTenants | undefined =
        categoryGroupStore.tenantSearchCategoriesMap.get(pointId);

      if (findCategory) {
        categoryGroupStore.setCategoryTabs(findCategory.categoryGroups);
        categoryGroupStore.setCategoryList('TENANT_SEARCH', findCategory);

        tenantStore.setFilteredTenants(findCategory.tenants, langStore.currentLang);
      }
    }
  };

  // 현재 포인트의 floors 세팅
  const setPointFloors = (pointId: string | undefined) => {
    if (floorStore.pointFloorsMap && pointId) {
      const findFloors: Floor[] | undefined = floorStore.pointFloorsMap.get(pointId);

      if (findFloors) {
        floorStore.setFloors(findFloors);
      }
    }
  };

  // 검색 필터 세팅
  const handleFilters = (pointId: string) => {
    if (filterType === SearchTabType.floor || filterType === SearchTabType.category) {
      setPointCategoriesAndTenants(pointId);
      setPointFloors(pointId);
    }
  };

  // 포인트를 선택하면 층, 카테고리, 테넌트, 현재 선택한 포인트 변경
  const onClickPoint = (point: Point) => {
    setIsOpen(false);
    pointStore.setSelectedFilterPoint(point);

    handleFilters(point.id);
  };

  useEffect(() => {
    if (machineStore.machine) {
      pointStore.setSelectedFilterPoint(machineStore.machine.point);
    }
  }, [machineStore.machine]);

  useEffect(() => {
    if (pointStore.selectedFilterPoint) {
      handleFilters(pointStore.selectedFilterPoint.id);
    }
  }, [filterType, pointStore.selectedFilterPoint]);

  return (
    <div className={styles.container}>
      <OutsideClickHandler display='flex' onOutsideClick={() => setIsOpen(false)}>
        <div className={styles.select_box_wrapper}>
          {isOpen && (
            <div className={styles.options}>
              {pointStore.points
                .filter(point => point.id !== pointStore.selectedFilterPoint?.id)
                .map(point => (
                  <div key={point.id} onClick={onClickPoint.bind(this, point)} className={styles.option}>
                    <div className={styles.point_icon}>
                      <img src={point.buildingType === 'OUTDOOR' ? ICON_OUTDOOR : ICON_INDOOR} alt='icon' />
                    </div>
                    <div className={styles.point_name}>{point.name[langStore.currentLang]}</div>
                  </div>
                ))}
            </div>
          )}

          <div style={{ backgroundColor: themeStore.sub }} onClick={handleOpenOptions} className={styles.select_box}>
            {filterType === SearchTabType.tenant ? (
              <div className={styles.all}>{t('tenant.allCategories')}</div>
            ) : (
              <>
                <div className={styles.current_select}>
                  <div className={styles.current_select_icon}>
                    <img
                      src={pointStore.selectedFilterPoint?.buildingType === 'OUTDOOR' ? ICON_OUTDOOR : ICON_INDOOR}
                      alt=''
                    />
                  </div>
                  <div className={styles.current_select_name}>
                    {pointStore.selectedFilterPoint?.name[langStore.currentLang] || ''}
                  </div>
                </div>
                <div className={styles.arrow}>
                  <img src={isOpen ? ARROW_UP : ARROW_DOWN} alt='arrow' />
                </div>
              </>
            )}
          </div>
        </div>
      </OutsideClickHandler>
    </div>
  );
};
export default FilterPointSelect;
