import { SvgProps } from '../../type/SvgProps';

const DeleteAllSvg = ({ color }: SvgProps) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
      <g clipPath='url(#clip0_567_27021)'>
        <circle cx='12' cy='12' r='12' fill={color} />
        <path
          d='M8.41421 7L7 8.41421L10.5355 11.9497L7 15.4853L8.41421 16.8995L11.9497 13.364L15.4853 16.8995L16.8995 15.4853L13.364 11.9497L16.8995 8.41421L15.4853 7L11.9497 10.5355L8.41421 7Z'
          fill='#F9F9F9'
        />
      </g>
      <defs>
        <clipPath id='clip0_567_27021'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};
export default DeleteAllSvg;
