import React, { useState, useEffect } from 'react';

import useFloorStore from 'stores/floor';
import useMachineStore from 'stores/machine';
import useColorSetStore from 'stores/colorSet';

import { getCurrentZoomLevel, zoomIn, zoomOut } from 'map/control/zoom';

import ICON_ZOOM_IN_DISABLE from 'assets/floor/plus-disable.svg';
import ICON_ZOOM_IN from 'assets/floor/plus.svg';

import styles from './FloorZoomControlBtn.module.scss';

const MAP_ZOOM_PERCENTAGE = 0.5; // 지도 라이브러리 줌의 증가,감소 폭 (5%)

/**
 * FloorZoomControlBtn, floor map의 줌레벨을 조정하는 버튼 컴포넌트
 */
const FloorZoomControlBtn = () => {
  /* zustand store */
  const { floorZoomControl, setFloorZoomControl } = useFloorStore();
  const themeStore = useColorSetStore();
  const machineStore = useMachineStore();

  /* state */
  const [disableZoomBtn, setDisableZoomBtn] = useState({
    zoomIn: false,
    zoomOut: false,
  });

  const [activeZoomBtn, setActiveZoomBtn] = useState({
    zoomIn: false,
    zoomOut: false,
  });

  /* variable */
  const limitZoom = {
    min: Number(machineStore.machine?.point.mapMinZoom),
    max: Number(machineStore.machine?.point.mapMaxZoom),
  };

  /**
   * 지도 줌 값이 소수점으로 떨어져서 소수점 첫째자리가 9, 0, 1인경우 반올림
   * map sdk에서 제공하는 getCurrentZoomLevel()에서 정수가 아니라 현재 zoom값의 소수점 근사치를 전달하고 있어서
   * 현재 zoom level에 따라 zoomIn, zoomOut 가능 여부를 UI로 표현해야 하기 때문에 반올림 후 비교
   * @function
   * @param {number} zoom 반올림 처리 대상 줌 레벨
   * @returns {number} 반올림 처리 된 zoom level 반환
   */
  const customRound = (zoom: number): number => {
    if (Math.floor(zoom * 10) % 10 === 9) {
      return Math.round(zoom);
    }
    if (Math.floor(zoom * 10) % 10 === 1 || Math.floor(zoom * 10) % 10 === 0) {
      return Math.round(zoom);
    }
    return zoom;
  };

  /**
   * 줌 인,아웃(확대, 축소) 작업을 처리하는 함수
   * 현재 줌레벨과 limit 최소, 최대 줌아웃을 통해 줌 변경 및 버튼 disable 처리 수행
   * @function
   * @param {string} zoomType 줌 인, 아웃 처리를 위한 줌 타입 / in: 확대, out: 축소
   * @returns {void}
   */
  const handleZoom = (zoomType: string): void => {
    const currentZoomLevel = customRound(getCurrentZoomLevel());
    let zoomInDisabled = disableZoomBtn.zoomIn;
    let zoomOutDisabled = disableZoomBtn.zoomOut;

    if (zoomType === 'in' && currentZoomLevel < limitZoom.max) {
      // 현재 줌 레벨 다음 확대를 가정하여, 지도 라이브러리의 기본 줌 증가 폭인 5%를 더하고, 반올림 하여 비활성화 처리를 한다.
      if (customRound(getCurrentZoomLevel() + MAP_ZOOM_PERCENTAGE) >= limitZoom.max) {
        zoomInDisabled = true;
      }
      zoomOutDisabled = false;
      zoomIn();
    } else if (zoomType === 'out' && currentZoomLevel > limitZoom.min) {
      // 현재 줌 레벨 다음 축소를 가정하여, 지도 라이브러리의 기본 줌 감소 폭인 5%를 빼고, 반올림 하여 비활성화 처리를 한다.
      if (customRound(getCurrentZoomLevel() - MAP_ZOOM_PERCENTAGE) <= limitZoom.min) {
        zoomOutDisabled = true;
      }
      zoomInDisabled = false;
      zoomOut();
    }
    setDisableZoomBtn({ zoomIn: zoomInDisabled, zoomOut: zoomOutDisabled });
  };

  useEffect(() => {
    if (floorZoomControl) {
      setDisableZoomBtn({ zoomIn: false, zoomOut: false });
      setActiveZoomBtn({ zoomIn: false, zoomOut: false });
      setFloorZoomControl(false);
    }
  }, [floorZoomControl, setFloorZoomControl]);

  return (
    <div className={styles.container}>
      {/* 줌 인(확대 버튼) */}
      <div
        style={{ backgroundColor: activeZoomBtn.zoomIn ? themeStore.main : 'white' }}
        className={`${styles.plus} ${disableZoomBtn.zoomIn && styles.disabled}`}
        onTouchStart={() => {
          if (disableZoomBtn.zoomIn) return;
          setActiveZoomBtn({ ...activeZoomBtn, zoomIn: true });
        }}
        onTouchEnd={() => setActiveZoomBtn({ ...activeZoomBtn, zoomIn: false })}
        onClick={() => handleZoom('in')}
      >
        <div className={styles.icon}>
          <img src={disableZoomBtn.zoomIn ? ICON_ZOOM_IN_DISABLE : ICON_ZOOM_IN} alt='plus' />
        </div>
      </div>
      {/* 줌 아웃(축소 버튼) */}
      <div
        style={{ backgroundColor: activeZoomBtn.zoomOut ? themeStore.main : 'white' }}
        className={`${styles.minus} ${disableZoomBtn.zoomOut && styles.disabled}`}
        onTouchStart={() => {
          if (disableZoomBtn.zoomOut) return;
          setActiveZoomBtn({ ...activeZoomBtn, zoomOut: true });
        }}
        onTouchEnd={() => setActiveZoomBtn({ ...activeZoomBtn, zoomOut: false })}
        onClick={() => handleZoom('out')}
      >
        <div className={`${styles.rectangle} `} />
      </div>
    </div>
  );
};

export default FloorZoomControlBtn;
