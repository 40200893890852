import { CustomBoardContent, CustomPage, CustomPageType } from 'types/feature/customBoard.type';
import { create } from 'zustand';

type CustomBoardStoreProps = {
  customPages: Map<string, CustomPage> | null;
  setCustomPages: (customPages: Map<string, CustomPage>) => void;

  currentCustomPageType: CustomPageType;
  currentCustomBoards: CustomBoardContent[];
  setCurrentCustomPage: (currentCustomPage: CustomPage) => void;

  currentCustomBoard: CustomBoardContent | null;
  setCurrentCustomBoard: (currentCustomBoard: CustomBoardContent) => void;
};

const useCustomBoardStore = create<CustomBoardStoreProps>((set, get) => ({
  customPages: null,
  setCustomPages(customPages) {
    set(() => ({ customPages }));
  },

  currentCustomPageType: 'LIST',
  currentCustomBoards: [],

  setCurrentCustomPage(currentCustomPage: CustomPage) {
    set(() => ({
      currentCustomPageType: currentCustomPage.type,
      currentCustomBoards: currentCustomPage.contents,
    }));
  },

  currentCustomBoard: null,
  setCurrentCustomBoard(currentCustomBoard) {
    set(() => ({ currentCustomBoard }));
  },
}));
export default useCustomBoardStore;
