import Calendar from 'assets/customBoard/calendar.svg';
import ArrowTop from 'assets/customBoard/here.svg';
import { DEFAULT_IMAGE } from 'constants/defaultImage';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useCustomBoardStore from 'stores/customBoard';
import useLanguageStore from 'stores/languages';
import { Display } from 'types/feature/customBoard.type';
import styles from './CustomBoardPopup.module.scss';

type CustomBoardPopupProps = {
  closeModal: () => void;
};

const CustomBoardPopup = ({ closeModal }: CustomBoardPopupProps) => {
  const { currentCustomBoard } = useCustomBoardStore();
  const { currentLang } = useLanguageStore();
  const { t } = useTranslation();

  const [showTopButton, setShowTopButton] = useState(false);
  const scrollRef = useRef<HTMLDivElement>(null);
  const topRef = useRef<HTMLDivElement>(null);

  const [isLoading, setLoading] = useState(true);

  const handleScrollRef = () => {
    if (scrollRef.current) {
      if (scrollRef.current.scrollTop !== 0) {
        setShowTopButton(true);
      }

      if (scrollRef.current.scrollTop === 0) {
        setShowTopButton(false);
      }
    }
  };

  const onClickTopButton = () => {
    if (topRef.current) {
      topRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.addEventListener('scroll', handleScrollRef);
    }

    return () => {
      if (scrollRef.current) {
        scrollRef.current.removeEventListener('scroll', handleScrollRef);
      }
    };
  }, []);

  return (
    <div>
      {currentCustomBoard && (
        <div className={styles.container}>
          <div className={`${styles.badge} ${styles[currentCustomBoard.displayStatus.toLowerCase()]}`}>
            {t(`badge.${currentCustomBoard.displayStatus}`)}
          </div>
          {/* header */}
          <div className={styles.header}>
            <div className={styles.title}>{currentCustomBoard?.title[currentLang] || '-'}</div>
            <div className={styles.date}>
              <div className={styles.calendar}>
                <img src={Calendar} alt='calendar' />
              </div>
              <div className={styles.days}>
                {currentCustomBoard?.displayType === Display.ALWAYS_ON && t('customBoard.always')}
                {currentCustomBoard?.displayType === Display.DURATION &&
                  `${currentCustomBoard.startDate} ~ ${currentCustomBoard.endDate}`}
              </div>
            </div>
          </div>

          {isLoading && (
            <div className={styles.content_image} ref={scrollRef}>
              <img src={DEFAULT_IMAGE} alt='default' />
            </div>
          )}

          <div className={`${styles.content_image} ${isLoading && styles.loading}`} ref={scrollRef}>
            <div ref={topRef} />
            <img src={currentCustomBoard?.contentImageUri} alt='content' onLoad={() => setLoading(false)} />
          </div>

          {showTopButton && (
            <div className={styles.top_button} onClick={onClickTopButton}>
              <div className={styles.icon}>
                <img src={ArrowTop} alt='arrow' />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CustomBoardPopup;
