/* eslint-disable react/destructuring-assignment */
import Here from 'assets/here/here.svg';
import useFloor from 'hooks/floor/useFloor';
import MapDraw from 'map/MapDraw';
import { addBuilding } from 'map/control/building';
import { changeCamera, moveCamera } from 'map/control/camera';
import { changeMapFloor } from 'map/control/floor';
import { goToMyLocation } from 'map/control/myLocation';
import { changeMapMinMaxZoom } from 'map/control/zoom';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import useCategoryGroupStore from 'stores/categoryGroup';
import useColorSetStore from 'stores/colorSet';
import useFacilityStore from 'stores/facility';
import useFloorStore from 'stores/floor';
import useLoadingStore from 'stores/loading';
import useMachineStore from 'stores/machine';
import usePointStore from 'stores/point';
import useQrStore from 'stores/qr';
import { CategoryGroupWithTenants } from 'types/common/category.type';
import { Floor, OrderedFloor } from 'types/common/floor.type';
import { Point } from 'types/common/point.type';
import styles from './MyLocationBtn.module.scss';

type Props = {
  slide: Slider | null;
  floors: OrderedFloor[];
};

const MyLocationBtn = (props: Props) => {
  const { t } = useTranslation();
  const { findFloorNameByBuildingId } = useFloor();

  const floorStore = useFloorStore();
  const facilityStore = useFacilityStore();
  const qrStore = useQrStore();
  const themeStore = useColorSetStore();
  const pointStore = usePointStore();
  const categoryStore = useCategoryGroupStore();
  const machineStore = useMachineStore();
  const loadingStore = useLoadingStore();

  const isOverOneFloor = floorStore.floors.length > 1;

  /**
   * 기기 층으로 슬라이드를 이동한다
   * @param floorId 기기 층 아이디
   */
  const moveSlickToMachineFloor = (floorId: string) => {
    if (!props.slide || !floorId) return;
    const findFloor = props.floors.find(floor => floor.id === floorId);

    if (findFloor) {
      props.slide.slickGoTo(findFloor.order);
    }
  };

  // 층 목록 변경
  const changeFloors = (pointId: string) => {
    const findFloors: Floor[] | undefined = floorStore.pointFloorsMap?.get(pointId);

    if (findFloors) {
      floorStore.setFloors(findFloors);
    }
  };

  // 선택된 point 변경
  const changePoint = (pointId: string) => {
    const findPoint: Point | undefined = pointStore.pointsMap?.get(pointId);

    if (findPoint) {
      pointStore.setSelectedPoint(findPoint);
    }
  };

  // 카테고리 리스트 변경
  const changeCategories = (pointId: string) => {
    if (categoryStore.mapFacilityCategoriesMap) {
      const findCategories: CategoryGroupWithTenants | undefined = categoryStore.mapFacilityCategoriesMap.get(pointId);

      if (findCategories) {
        categoryStore.setCategoryList('MAP_FACILITY', findCategories);
      }
    }
  };

  // 층 이름 변경
  const changeFloorName = (floorId: string, buildingId: string) => {
    const floorName = findFloorNameByBuildingId(floorId, buildingId);

    if (floorName) {
      floorStore.setFloorName(floorName);
    }
  };

  // 현재위치 클릭 시
  const onClick = async () => {
    loadingStore.setFloorChanging(true);

    facilityStore.resetCurrentFacilityId();
    qrStore.setShowNaviQr(false);
    floorStore.setFloorZoomControl(true);

    const machinePointId = machineStore.machine?.point.id || '';
    const machineFloorId = machineStore.machine?.floor.id || '';
    const machineBuildingId = machineStore.machine?.point.buildingId || '';

    changeFloors(machinePointId);
    changePoint(machinePointId);
    changeCategories(machinePointId);

    /**
     * 지도의 빌딩 이동 및 층 이동
     *
     * 같은 빌딩이라면 changeFloor 해야 하고 다른 빌딩이라면 addBuilding 해야함.
     * 이유 : 2024 0404 기준 addBuilding 시 이미 context 에 같은 빌딩 있으면 api 내부적으로 return 하고 있음.
     * 추후 changeFloor 메서드로 다른 빌딩까지 이동할 수 있도록 api 업데이트 예정이라고 들음.
     */
    if (pointStore.selectedPoint?.id === machinePointId) {
      await changeMapFloor(machineFloorId);
    } else {
      await addBuilding(machineBuildingId, machineFloorId);
    }

    changeFloorName(machineFloorId, machineBuildingId);
    floorStore.setCurrentFloorId(machineFloorId);

    // 층 슬라이드 이동
    moveSlickToMachineFloor(machineFloorId);

    // 지도 기능
    await goToMyLocation();

    // 기기 위치로 카메라 이동
    moveCamera(MapDraw.machine.coordinate);

    // 기기 포인트 최소/최대 줌레벨 적용
    changeMapMinMaxZoom(Number(MapDraw.machine.point.mapMinZoom), Number(MapDraw.machine.point.mapMaxZoom));

    // 기기 방향각, 줌레벨 적용
    changeCamera({
      rotation: Number(MapDraw.machine.mapPosition.rotation),
      zoom: Number(MapDraw.machine.mapPosition.zoom),
      transition: true,
    });

    loadingStore.setFloorChanging(false);
  };

  return (
    <button
      className={`${styles.container} ${!isOverOneFloor && styles.no_floor}`}
      style={{ background: themeStore.sub }}
      type='button'
      onClick={onClick}
    >
      <div className={styles.icon}>
        <img src={Here} alt='here' />
      </div>
      <div>{t(`floor.currentLocation`)}</div>
    </button>
  );
};

export default MyLocationBtn;
