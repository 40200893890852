import ModalContainer from 'component/common/modal/container/ModalContainer';
import LoginContainer from 'component/login/container/LoginContainer';
import useModal from 'hooks/modal/useModal';
import styles from './Pages.module.scss';

const LoginPage = () => {
  const { openModal, closeModal, isVisibleModal, modalType } = useModal();

  const modalProps = {
    isVisibleModal,
    openModal,
    closeModal,
    modalType,
  };
  return (
    <div className={styles.login}>
      <LoginContainer openModal={openModal} />
      {isVisibleModal && <ModalContainer {...modalProps} />}
    </div>
  );
};

export default LoginPage;
