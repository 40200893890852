import useStats from 'hooks/fetch/stats/useStats';
import { useIdleTimer } from 'react-idle-timer';
import { useLocation, useNavigate } from 'react-router-dom';
import useLanguageStore from 'stores/languages';
import useMultiLangStore from 'stores/multiLang';
import useQrStore from 'stores/qr';
import useTransportStore from 'stores/transport';
import { PathType } from 'types/common/common.type';
import { LangCode } from 'types/common/language.type';

const useResetSection = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { postUsages } = useStats();

  const langStore = useLanguageStore();
  const multiLangStore = useMultiLangStore();
  const qrStore = useQrStore();
  const transportStore = useTransportStore();

  const PROD_TIMEOUT = 40000; // 40초

  // local 일 경우와 아닌 경우 분기
  const timeout = process.env.REACT_APP_ENVIRONMENT === 'local' ? 4000 * 10000 : PROD_TIMEOUT;

  /**
   * 일정 시간 후에 메인페이지로 돌아간다.
   * 로그인 페이지, 메인 페이지 제외
   */
  const resetToMainPage = async () => {
    // idle timer가 적용되면 안되는 path
    if (location.pathname === PathType.login) return;
    if (location.pathname === PathType.error) return;
    if (location.pathname === PathType.desktopError) return;
    if (location.pathname === PathType.resolutionError) return;
    if (location.pathname === PathType.loading) return;
    if (location.pathname === PathType.noLoginWis) return;
    if (location.pathname === PathType.noLoginCafeShow) return;

    // 메인언어로 변경
    const mainLanguage = langStore.languages.find(lang => lang.main)?.code ?? LangCode.ko;
    langStore.setCurrentLang(mainLanguage as LangCode);

    // 다국어 버튼 닫기
    multiLangStore.closeMultiLang();

    // qr 닫기
    qrStore.closeMainQr();
    qrStore.setShowNaviQr(false);

    // 이동수단 팝업 제거
    transportStore.setOpenTransportPopup(false);

    // 메인페이지로 이동
    if (location.pathname !== PathType.home) {
      navigate(PathType.home, { replace: true });

      // 메인페이지가 아니었을 경우 홈으로 돌아가고 기기 사용량 데이터 수집
      await postUsages();
    }
  };

  useIdleTimer({
    timeout,
    onIdle: resetToMainPage,
  });
};

export default useResetSection;
