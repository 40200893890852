import { errorConsole, logConsole } from 'component/common/console/console';
import { DESKTOP_ENVIRONMENT } from 'constants/environment';
import { useEffect } from 'react';
import useScreensaverStore from 'stores/screensaver';
import { Screensaver } from 'types/feature/screensaver.type';
import { getTimeStamp } from 'utils/dateTime/timeStamp';

const useFetchScreensaver = () => {
  const screensaverStore = useScreensaverStore();

  /*
screensaver api에서 내려받은 콘텐츠를 fetch하고 성공한 콘텐츠들과 실패한 콘텐츠를 분리합니다.
*/

  const fetchUrl = async (url: string, screensaver: Screensaver) => {
    try {
      // desktop, web 스크린세이버 URL
      const screensaverUri = DESKTOP_ENVIRONMENT
        ? `${process.env.REACT_APP_SCREENSAVER_URL}/${screensaver.fileName}`
        : screensaver.fileUri;
      const response = await fetch(screensaverUri);
      if (response.ok) {
        const blob = await response.blob();
        if (blob) {
          return {
            fileUri: response.url,
            fileType: screensaver.fileType,
            fileName: screensaver.fileName,
            id: screensaver.id,
            sortOrder: screensaver.sortOrder,
          };
        }
      } else {
        screensaverStore.setFailedMedia(screensaver);
      }
    } catch (error) {
      errorConsole('blob fetch error ::: ', error);
      screensaverStore.setFailedMedia(screensaver);
    }
  };

  const setSucceededMedia = async (screensavers: Screensaver[]) => {
    try {
      // ! 병렬 처리
      const fetchPromises = screensavers.map(url => fetchUrl(url.fileUri, url));
      const result = await Promise.all(fetchPromises);
      if (result) {
        screensaverStore.setScreensaverStatus(true);
        const screensaverLoaded = result.filter(screensaver => screensaver !== undefined);
        screensaverStore.setSucceededMedia(screensaverLoaded as Screensaver[]);

        logConsole('> 6. 미디어 fetch 완료 : ', getTimeStamp());
      }
      // ! 직렬 처리
      // screensavers.forEach((screensaver, index) =>
      //   fetch(screensaver.fileUri).then(response => {
      //     if (response) {
      //       // 실패하면?
      //       if (response.status !== 200) {
      //         return screensaverStore.setFailedMedia(screensaver);
      //       }
      //       response.blob().then(() => {
      //         const urls = {
      //           fileUri: response.url,
      //           fileType: screensaver.fileType,
      //           id: screensaver.id,
      //           sortOrder: index,
      //         };
      //         screensaverStore.setSucceededMedia(urls);
      //       });
      //     }
      //   }),
      // );
    } catch (error) {
      errorConsole('error screensaver fetch', error);
    }
  };

  /*
  fetch에 실패한 콘텐츠가 있을 경우 다시 refetch를 시도해서 성공하면 성공 리스트로 넘어갑니다.
  */
  const refetchContent = async () => {
    try {
      if (screensaverStore.failedMedia.length === 0) return;
      screensaverStore.failedMedia.forEach(screensaver => {
        // desktop, web 스크린세이버 URL
        const screensaverUri = DESKTOP_ENVIRONMENT
          ? `${process.env.REACT_APP_SCREENSAVER_URL}/${screensaver.fileName}`
          : screensaver.fileUri;

        fetch(screensaverUri)
          .then(response => {
            if (response.ok) {
              const file: Screensaver = {
                id: screensaver.id,
                fileUri: screensaver.fileUri,
                fileType: screensaver.fileType,
                fileName: screensaver.fileName,
                sortOrder: screensaver.sortOrder,
              };
              screensaverStore.setOneSucceedMedia(file);
              screensaverStore.deleteFailedMedia(screensaver.fileUri);
              logConsole('재로드 성공');
            }
          })
          .catch(error => {
            if (error instanceof TypeError && error.message === 'Failed to fetch') {
              errorConsole('네트워크 오류 발생 : 동영상을 가져올 수 없습니다.', error);
            } else {
              errorConsole('동영상 로드 중 오류 발생', error);
            }
          });
      });
    } catch (error) {
      logConsole('refetch screensaver error: ', error);
    }
  };

  useEffect(() => {
    // 일정 시간 간격으로 refetch를 시도합니다.
    const intervalId = setInterval(() => {
      refetchContent();
    }, 60 * 60 * 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [screensaverStore.failedMedia]);

  return {
    setSucceededMedia,
  };
};

export default useFetchScreensaver;
