import { COLORS } from 'constants/colors';
import _ from 'lodash';
import { Link, useLocation } from 'react-router-dom';
import useColorSetStore from 'stores/colorSet';
import useLanguageStore from 'stores/languages';
import useQrStore from 'stores/qr';
import { PathType } from 'types/common/common.type';
import { MainMenu } from 'types/feature/menu.type';
import styles from './MenuButton.module.scss';
import MenuIcon from './menu-icon/MenuIcon';

type Props = {
  menu: MainMenu;
  index: number;
  filteredMenu: MainMenu[];
};

const MenuButton = ({ menu, index, filteredMenu }: Props) => {
  const colorSetStore = useColorSetStore();
  const qrStore = useQrStore();
  const langStore = useLanguageStore();

  const location = useLocation();

  const isActiveMenu = (currentMenu: MainMenu): boolean => {
    // 메뉴가 커스텀 보드일 경우
    if (`/${currentMenu.type}` === PathType.customBoard) {
      if (!location.state) {
        return false;
      }
      if (location.state.pageId === currentMenu.customPageId) {
        return true;
      }
      return false;
    }

    // 나머지 메뉴일 경우
    if (`/${currentMenu.type}` === location.pathname) {
      return true;
    }

    return false;
  };

  const handleClickMenu = _.debounce(() => {
    qrStore.setShowNaviQr(false);
  }, 400);

  return (
    <Link
      onClick={handleClickMenu}
      style={{
        background: `${isActiveMenu(menu) ? colorSetStore.gnb.bg.active : colorSetStore.gnb.bg.inactive}`,
      }}
      className={`${styles.menu_wrapper} ${isActiveMenu(menu) && styles.active}
       ${index !== filteredMenu.length - 1 && styles.border_right}
      `}
      to={menu.type}
      state={{ pageId: menu.customPageId }}
    >
      <div className={`${styles.menu} ${isActiveMenu(menu) && styles.active}`}>
        {/* 메뉴 아이콘 */}
        {menu.iconUsed && (
          <div className={styles.icon}>
            <MenuIcon
              iconUri={menu.mainIconUri}
              iconColor={isActiveMenu(menu) ? COLORS.WHITE : colorSetStore.text.title}
            />
          </div>
        )}

        {/* 메뉴 이름 */}
        <div
          style={{ color: `${isActiveMenu(menu) ? colorSetStore.gnb.text.active : colorSetStore.text.title}` }}
          className={styles.menu_text}
        >
          {menu.name[langStore.currentLang]}
        </div>
      </div>
    </Link>
  );
};
export default MenuButton;
