import React from 'react';
import Login from 'assets/login/login.svg';
import styles from './LoginHeader.module.scss';

const LoginHeader = () => (
  <div className={styles.header}>
    <div className={styles.title}>
      <div className={styles.icon}>
        <img src={Login} alt="login" />
      </div>
      <div>로그인</div>
    </div>
    <div className={styles.border} />
  </div>
);

export default LoginHeader;
