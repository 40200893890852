import { useState, useEffect } from 'react';
import DateTime from 'utils/dateTime/dateTime';
import { intervalTimer } from 'utils/timer/intervalTimer';

// 현재 시간 관리하는 훅
const useClock = () => {
  let timer: NodeJS.Timer;
  const [realTime, setRealTime] = useState<string | null>(new DateTime().convertCurrentTime());

  useEffect(() => {
    timer = intervalTimer(1, () => {
      setRealTime(new DateTime().convertCurrentTime());
    });

    return () => {
      clearInterval(Number(timer));
    };
  }, []);

  return realTime;
};

export default useClock;
