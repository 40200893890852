import { EndPoint } from 'data/endPoints';
import useLanguageStore from 'stores/languages';
import { LangCode, Language } from 'types/common/language.type';
import useAxios from 'utils/axios/fetchAxios';

const useLanguage = () => {
  // hook
  const apiManager = useAxios();
  // store
  const langStore = useLanguageStore();

  /**
   * @desc 언어 목록을 조회 후, 메인 언어를 반환한다.
   * @async
   * @returns {Promise<LangCode | undefined>} 메인 언어
   */
  const getLanguages = async (): Promise<LangCode | undefined> => {
    const result = await apiManager.api.get<Language[]>(EndPoint.languages);

    if (result) {
      const languages: Language[] = result.map(lang => {
        return {
          ...lang,
          code: lang.code.toLowerCase() as LangCode,
        };
      });

      langStore.setLanguages(languages);

      const mainLang: LangCode = (languages.find(lang => lang.main)?.code as LangCode) ?? LangCode.ko;

      langStore.setCurrentLang(mainLang);

      return mainLang;
    }
  };

  return { getLanguages };
};
export default useLanguage;
