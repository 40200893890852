import MapDraw from 'map/MapDraw';
import { DataObject, FindDataObjectOption } from 'map/types/object';
import { returnAnything } from 'utils/common/returnAnything';
import { validMapData } from './common/valid';

/**
 * object 를 검색한다.
 * @param {FindDataObjectOption} option 검색할 option. 전부 optional.
 * @returns {DataObject | undefined} 검색한 object. option 이 없다면 찾은 모든 object list 를 반환.
 */
export const findObject = (option: FindDataObjectOption): DataObject | undefined => {
  validMapData();

  return returnAnything<DataObject>(MapDraw.mapData.dataObject.find(option));
};
