/* eslint-disable react-hooks/exhaustive-deps */
import PoweredByDabeeo from 'assets/main/poweredby.svg';
import ModalContainer, { ModalType } from 'component/common/modal/container/ModalContainer';
import FloorChangedPopup from 'component/common/popup/floor-changed/FloorChangedPopup';
import useMapClick from 'hooks/map/event/useMapClick';
import useMapLocation from 'hooks/map/trigger/useMapLocation';
import useMapNavigation from 'hooks/map/trigger/useMapNavigation';
import useMapTransport from 'hooks/map/trigger/useMapTransport';
import MapDraw from 'map/MapDraw';
import { clearMap } from 'map/control/common/clear';
import { showMyLocationNoAni } from 'map/control/myLocation';
import { useEffect, useRef } from 'react';
import useMapActionStore from 'stores/action';
import useFacilityStore from 'stores/facility';
import useFloorStore from 'stores/floor';
import useLanguageStore from 'stores/languages';
import useLoadingStore from 'stores/loading';
import useMachineStore from 'stores/machine';
import usePopupStore from 'stores/popup';
import useQrStore from 'stores/qr';
import useTransportStore from 'stores/transport';
import { changeLangCodeForMap } from 'utils/multi-lang/convertLangCodeForMap';
import FloorList from '../floor-list/FloorList';
import styles from './Map.module.scss';
import MapQr from './qr/MapQr';
import FloorZoomControlBtn from './zoom/FloorZoomControlBtn';

type MapProps = {
  openModal: (type: ModalType) => void;
  closeModal: () => void;
  removeNoFacilityToast: () => void;
};

const MapContainer = ({ openModal, removeNoFacilityToast, closeModal }: MapProps) => {
  const mapRef = useRef<HTMLDivElement>(null);

  const floorStore = useFloorStore();
  const facilityStore = useFacilityStore();
  const qrStore = useQrStore();
  const popupStore = usePopupStore();
  const machineStore = useMachineStore();
  const langStore = useLanguageStore();
  const loadingStore = useLoadingStore();
  const mapActionStore = useMapActionStore();
  const transportStore = useTransportStore();

  const { triggerLocation } = useMapLocation();
  const { triggerNavigation } = useMapNavigation();
  const { triggerTransport } = useMapTransport();
  useMapClick({ openModal });

  const isOverOneFloor = floorStore.floors.length > 1;

  // 지도 그리고 붙이기
  const showAndAppendMap = async () => {
    if (machineStore.machine) {
      if (mapRef.current) {
        const map = mapRef.current;
        await new MapDraw().showMap(map);

        // 내위치 마커
        await showMyLocationNoAni();

        changeLangCodeForMap(langStore.currentLang);

        loadingStore.setLoadingMap(false);
      }
    }
  };

  useEffect(() => {
    if (loadingStore.hasMapData) {
      showAndAppendMap();
    }
  }, [loadingStore.hasMapData]);

  useEffect(() => {
    if (!loadingStore.isLoadingMap) {
      if (mapActionStore.actions.location) {
        triggerLocation();
      }
    }
  }, [mapActionStore.actions.location, loadingStore.isLoadingMap]);

  useEffect(() => {
    if (!loadingStore.isLoadingMap) {
      if (mapActionStore.actions.navigation) {
        triggerNavigation();
      }
    }
  }, [mapActionStore.actions.navigation, loadingStore.isLoadingMap]);

  useEffect(() => {
    if (!loadingStore.isLoadingMap) {
      if (mapActionStore.actions.transport) {
        closeModal();
        triggerTransport();
      }
    }
  }, [mapActionStore.actions.transport, loadingStore.isLoadingMap]);

  useEffect(() => {
    return () => {
      clearMap();
      MapDraw.cleanup();

      loadingStore.setLoadingMap(true);
      mapActionStore.resetMapActions();
      facilityStore.resetCurrentFacilityId();
      floorStore.setCurrentFloorId(machineStore.machine?.floor.id || '');
      qrStore.setShowNaviQr(false);
    };
  }, []);

  return (
    <div className={styles.container}>
      {/* 지도 */}
      <div className={`${styles.map} ${!isOverOneFloor && styles.no_floor}`} ref={mapRef}>
        {popupStore.isOpenFloorChangedPopup && <FloorChangedPopup />}

        {/* 이동수단 팝업 */}
        {transportStore.openTransportPopup && (
          <ModalContainer
            modalType={ModalType.transport}
            openModal={() => transportStore.setOpenTransportPopup(true)}
            closeModal={() => transportStore.setOpenTransportPopup(false)}
          />
        )}

        {/* dabeeo logo */}
        <div className={styles.dabeeo}>
          <img src={PoweredByDabeeo} alt='dabeeo' />
        </div>
      </div>

      {/* 층 목록 */}
      <FloorList removeNoFacilityToast={removeNoFacilityToast} />

      {/* 지도 QR - 모바일로 진입 시 지도에서 해당 tenant 의 위치로 이동 + marker */}
      {machineStore.machine?.workspace.mobileUsed && qrStore.isShowNaviQr && <MapQr />}

      {/* zoom button */}
      {/* machineStore (machine 정보 내) zoomButtonUsed 값에 따라 toggle */}
      {machineStore.machine?.workspace.zoomButtonUsed && <FloorZoomControlBtn />}
    </div>
  );
};

export default MapContainer;
