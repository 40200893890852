import { MultiLang } from 'types/common/language.type';

export type CustomBoardContent = {
  id: string;
  title: MultiLang;
  displayType: string;
  startDate: string;
  endDate: string;
  mainImageUri: string;
  contentImageUri: string;
  createdDate: string;
  displayStatus: string;
};

export enum Display {
  ALWAYS_ON = 'ALWAYS_ON',
  DURATION = 'DURATION',
}

export type CustomPageType = 'LIST' | 'CARD';

export type CustomPage = {
  id: string;
  type: CustomPageType;
  contents: CustomBoardContent[];
};
