import React from 'react';
import { QRCodeSVG } from 'qrcode.react';
import styles from './CustomFieldQr.module.scss';

const CustomFieldQr = ({ url }: { url: string }) => {
  return (
    <div className={styles.qr_svg_wrapper}>
      <QRCodeSVG className={styles.qr_svg} value={url} size={300} />
    </div>
  );
};

export default CustomFieldQr;
