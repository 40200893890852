import dayjs from 'dayjs';
import Replacement from 'utils/replacement/replacement';

class DateTime {
  constructor(private readonly day: dayjs.Dayjs = dayjs()) {}

  // DATE
  getCurrentYear() {
    return this.day.format('YYYY');
  }

  getCurrentMonth() {
    return this.day.format('MM');
  }

  getCurrentDays() {
    return this.day.format('DD');
  }

  getCurrentDay() {
    return this.day.format('(ddd)');
  }

  convertDate(date: string) {
    try {
      const currentYear = dayjs().get('year').toString();

      if (date.includes('.')) {
        const parsingDate = date.replace('.', '-');
        const dateWithYear = currentYear.concat('-', parsingDate);
        const currentDate = dayjs(dateWithYear).format('MM/DD(ddd)');
        return currentDate;
      }

      if (date.includes('/')) {
        const parsingDate = date.replace('/', '-');
        const dateWithYear = currentYear.concat('-', parsingDate);
        const currentDate = dayjs(dateWithYear).format('MM/DD(ddd)');
        return currentDate;
      }

      return '';
    } catch (e) {
      return 'invalid date';
    }
  }

  // TIME
  getHour(time: number) {
    if (time < 10) {
      return `0${time.toString()}`;
    }
    return `${time.toString()}`;
  }

  getCurrentTime() {
    return this.day.format('A:hh:mm');
  }

  convertTime(time: string) {
    const splittedTime = time.split(':');
    const amPm = splittedTime[0];
    const hour = Number(splittedTime[1]);
    const minutes = splittedTime[2];

    return `${new Replacement(amPm).setAmPm()} ${this.getHour(hour)}:${minutes}`;
  }

  convertCurrentTime() {
    return this.convertTime(this.getCurrentTime());
  }

  getCurrentDateTime(format: string) {
    return this.day.format(format);
  }
}

export default DateTime;
