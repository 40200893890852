import { COLORS } from 'constants/colors';
import { KEYPAD_TABS, KEYPAD_TABS_ENG } from 'data/tenantSearchFilter';
import React, { Dispatch, SetStateAction } from 'react';
import useColorSetStore from 'stores/colorSet';
import useLanguageStore from 'stores/languages';
import { LangCode } from 'types/common/language.type';
import { KeypadType } from 'types/feature/tenantFilter.type';
import styles from './KeypadTabs.module.scss';

type KeypadTabsProps = {
  keypadType: KeypadType;
  setKeypadType: Dispatch<SetStateAction<KeypadType>>;
};

const KeypadTabs = ({ keypadType, setKeypadType }: KeypadTabsProps) => {
  const langStore = useLanguageStore();
  const colorSetStore = useColorSetStore();

  const handleClickTab = (e: React.MouseEvent<HTMLDivElement>) => {
    setKeypadType((e.target as HTMLDivElement).id as KeypadType);
  };

  const useKorean = langStore.languages.some(language => language.code === LangCode.ko);

  return (
    <div className={styles.keypad_tabs}>
      {(useKorean ? KEYPAD_TABS : KEYPAD_TABS_ENG).map(({ type, value }) => {
        const isActive = keypadType === type;

        return (
          <div
            style={{
              background: isActive ? colorSetStore.sub : colorSetStore.header.bg,
              borderColor: isActive ? colorSetStore.sub : colorSetStore.button.border,
              color: isActive ? COLORS.WHITE : colorSetStore.button.text,
            }}
            className={`${isActive && styles.active}`}
            onClick={handleClickTab}
            key={type}
            id={type}
          >
            {value}
          </div>
        );
      })}
    </div>
  );
};
export default KeypadTabs;
