import { findRoute } from 'map/control/route';
import MapDraw from 'map/MapDraw';
import { useState } from 'react';
import { TenantWithPointId } from 'types/common/tenant.type';
import { isEmpty } from 'lodash';
import useNaviRouteStore from 'stores/naviRoute';
import { warnConsole } from 'component/common/console/console';

// 팝업 진입 시, 길안내가 가능한지 여부를 체크하는 함수를 관리하는 custom hook
const useCheckValidRoute = () => {
  const [routeLoading, setRouteLoading] = useState(true);
  const [notAvailableNavi, setNotAvailableNavi] = useState(false);

  const naviRouteStore = useNaviRouteStore();

  const checkValidRoute = async (tenant: TenantWithPointId) => {
    const routeResponse = await findRoute(MapDraw.machine.coordinate, tenant);

    if (isEmpty(routeResponse)) {
      warnConsole('길찾기 경로가 없습니다. : routeResponse 가 {} 입니다.');
      setRouteLoading(false);
      setNotAvailableNavi(true);
      return;
    }

    // 전역 store 에 저장
    naviRouteStore.setNaviRoutes(routeResponse);

    const totalDistances = [
      {
        route: 'recommendation',
        totalDistance: routeResponse.recommendation?.totalDistance || 0,
      },
      {
        route: 'elevator',
        totalDistance: routeResponse.elevator?.totalDistance || 0,
      },
      {
        route: 'escalator',
        totalDistance: routeResponse.escalator?.totalDistance || 0,
      },
      {
        route: 'stairs',
        totalDistance: routeResponse.stairs?.totalDistance || 0,
      },
    ];

    const noDistance = totalDistances.every(distance => !distance.totalDistance);

    if (noDistance) {
      warnConsole('길찾기 경로가 없습니다. : 경로의 totalDistance 가 모두 0 입니다.');
      setRouteLoading(false);
      setNotAvailableNavi(true);
      return;
    }

    setRouteLoading(false);
    setNotAvailableNavi(false);
  };

  return {
    checkValidRoute,
    routeLoading,
    notAvailableNavi,
  };
};

export default useCheckValidRoute;
