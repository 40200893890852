import { create } from 'zustand';

type Props = {
  isOpenMultiLang: boolean;
  openMultiLang: () => void;
  closeMultiLang: () => void;
  toggleOpenMultiLang: (isOpenMultiLang: boolean) => void;
};

const useMultiLangStore = create<Props>((set, get) => ({
  isOpenMultiLang: false,
  openMultiLang: () => {
    set({
      isOpenMultiLang: true,
    });
  },
  closeMultiLang: () => {
    set({
      isOpenMultiLang: false,
    });
  },
  toggleOpenMultiLang: (isOpenMultiLang: boolean) => {
    set(() => ({
      isOpenMultiLang: !isOpenMultiLang,
    }));
  },
}));

export default useMultiLangStore;
