import React from 'react';
import styles from './KeywordItem.module.scss';

type Props = {
  keyword: string;
  type: 'list' | 'item';
};

const KeywordItem = ({ keyword, type }: Props) => {
  return (
    <div className={`${styles.word_box} ${type === 'item' ? styles.item : styles.list}`}>
      <span className={styles.text}>{keyword.slice(0, 16)}</span>
    </div>
  );
};
export default KeywordItem;
