/* eslint-disable react/require-default-props */
import CLOSE from 'assets/popup/close.svg';
import CustomBoardPopup from 'component/common/popup/custom-board/CustomBoardPopup';
import TenantPopup from 'component/common/popup/tenant/TenantPopup';
import TransportPopup from 'component/common/popup/transport/TransportPopup';
import NoAccountPopup from 'component/login/popup/NoAccountPopup';
import { t } from 'i18next';
import useLoadingStore from 'stores/loading';
import ModalPortal from '../portal/ModalPortal';
import styles from './ModalContainer.module.scss';

export enum ModalType {
  tenant = 'tenant',
  facility = 'facility',
  transport = 'transport',
  account = 'account',
  customBoard = 'customBoard',
}

type ModalContainerProps = {
  modalType: ModalType;
  openModal: (type: ModalType) => void;
  closeModal: () => void;
  isFloorPage?: boolean;
};

const ModalContainer = ({ modalType, openModal, closeModal, isFloorPage = false }: ModalContainerProps) => {
  const loadingStore = useLoadingStore();

  const props = {
    openModal,
    closeModal,
    isFloorPage,
  };

  return (
    <ModalPortal>
      <div
        className={styles.container}
        onClick={e => {
          if (e.target !== e.currentTarget) return;
          e.stopPropagation();
          closeModal();
          loadingStore.setActionLoading(false);
        }}
      >
        {/* modal children */}
        <div className={styles.wrapper}>
          {modalType === ModalType.tenant && <TenantPopup {...props} />}
          {modalType === ModalType.transport && <TransportPopup {...props} />}
          {modalType === ModalType.account && <NoAccountPopup {...props} />}
          {modalType === ModalType.customBoard && <CustomBoardPopup {...props} />}
        </div>

        {/* 닫기 버튼 */}
        <div className={styles.close_btn} onClick={closeModal}>
          <div className={styles.icon_wrapper}>
            <div className={styles.close_icon}>
              <img src={CLOSE} alt='close' />
            </div>
          </div>

          <span>{t(`button.close`)}</span>
        </div>
      </div>
    </ModalPortal>
  );
};
export default ModalContainer;
