import { isStartWithKoEn } from '../start-with/isStartWithKoEn';

/**
 * @desc 한글 또는 영어로 시작하는 것을 우선으로 정렬하고 나머지는 사전 순으로 정렬한다.
 * @param {string} a 첫번째 단어
 * @param {string} b 두번째 단어
 * @returns {number} 내림차순(1) 또는 오름차순(-1)
 */
export const sortKoEnFirst = (a: string, b: string): number => {
  // a가 한글 또는 영어로 시작하지 않으면 b보다 뒤에 위치하도록 함
  if (!isStartWithKoEn(a)) return 1;

  // b가 한글 또는 영어로 시작하지 않으면 a보다 뒤에 위치하도록 함
  if (!isStartWithKoEn(b)) return -1;

  // 모두 한글이나 영어로 시작하면 localeCompare를 사용하여 사전 순서로 정렬
  return a.localeCompare(b);
};
