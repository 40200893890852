import useColorSetStore from 'stores/colorSet';
import useLanguageStore from 'stores/languages';
import useTenantStore from 'stores/tenant';
import styles from './TenantDescription.module.scss';

const TenantDescription = () => {
  const tenantStore = useTenantStore();
  const langStore = useLanguageStore();
  const colorSetStore = useColorSetStore();

  return (
    <div
      style={{
        color: colorSetStore.text.title,
      }}
      className={styles.desc}
    >
      {tenantStore.currentTenant?.description[langStore.currentLang].replaceAll('\\n', '\n')}
    </div>
  );
};
export default TenantDescription;
