import TenantDetailQr from 'component/common/qr/container/tenant/TenantDetailQr';
import { useTranslation } from 'react-i18next';
import useColorSetStore from 'stores/colorSet';
import styles from './TenantPopupQr.module.scss';

const TenantQr = () => {
  const colorSetStore = useColorSetStore();

  const { t } = useTranslation();

  return (
    <div className={styles.qr_container}>
      <div
        style={{
          color: colorSetStore.text.item,
        }}
        className={styles.text}
      >
        {t('qr.mobile_popup')}
      </div>

      <div className={styles.qr}>
        <TenantDetailQr />
      </div>
    </div>
  );
};
export default TenantQr;
