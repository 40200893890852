import { COLORS } from 'constants/colors';
import useFloor from 'hooks/floor/useFloor';
import useColorSetStore from 'stores/colorSet';
import useFloorStore from 'stores/floor';
import useLanguageStore from 'stores/languages';
import usePointStore from 'stores/point';
import useScreenTypeStore from 'stores/screenType';
import { ScreenType } from 'types/common/common.type';
import styles from './FloorHeader.module.scss';

const FloorHeader = () => {
  const floorStore = useFloorStore();
  const langStore = useLanguageStore();
  const pointStore = usePointStore();
  const colorSetStore = useColorSetStore();
  const screenTypeStore = useScreenTypeStore();

  const { findFloorDesc } = useFloor();

  const isHori = screenTypeStore.screenType === ScreenType.hori;
  const color = isHori ? COLORS.BLACK : colorSetStore.header.text;

  if (!pointStore.selectedPoint) {
    return <> </>;
  }

  return (
    <div
      className={styles.container}
      style={{ background: isHori ? 'rgba(255, 255, 255, 0.6)' : colorSetStore.header.bg }}
    >
      <div style={{ color }} className={styles.floor}>
        {floorStore.floorName[langStore.currentLang]}
      </div>
      <div style={{ color }} className={styles.desc}>
        {findFloorDesc(floorStore.currentFloorId, pointStore.selectedPoint.id)?.[langStore.currentLang]}
      </div>
    </div>
  );
};

export default FloorHeader;
