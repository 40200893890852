import React from 'react';
import styles from './NotifyCapsLock.module.scss';

type NotifyCapsLockProps = {
  notifyText: string;
};

const NotifyCapsLock = ({ notifyText }: NotifyCapsLockProps) => {
  return <div className={styles.container}>{notifyText}</div>;
};

export default NotifyCapsLock;
