import MapDraw from '../MapDraw';
import { validMap } from './common/valid';

//  통합지도 api docs : https://api-sample.dabeeomaps.com/multiMap

/**
 * 빌딩 숨기는 메서드
 * @param buildingId 숨길 빌딩 id
 */
export const hideBuilding = (buildingId: string) => {
  validMap();

  MapDraw.map.context.removeBuilding(buildingId);
};

/**
 * 다른 빌딩으로 이동 시 사용하는 메서드
 * @param buildingId 추가할 빌딩 id
 * @param floorId 추가할 층 id
 * api 내부적으로 이미 빌딩이 있으면 return 시킴.
 */
export const addBuilding = async (buildingId: string, floorId?: string) => {
  validMap();

  await MapDraw.map.context.addBuilding(buildingId, floorId);
};
