import { CustomBoardContent } from 'types/feature/customBoard.type';
import styles from './CardCustomBoardItem.module.scss';
import CardImage from './image/CardImage';
import CardTitle from './title/CardTitle';

type Props = {
  board: CustomBoardContent;
};

const CardCustomBoardItem = ({ board }: Props) => {
  return (
    <div className={styles.board_wrapper}>
      <CardTitle board={board} />

      <CardImage board={board} />
    </div>
  );
};
export default CardCustomBoardItem;
