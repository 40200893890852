import { DESKTOP_ENVIRONMENT } from 'constants/environment';
import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter, MemoryRouter } from 'react-router-dom';
import 'styles/_global.scss';
import App from './App';
import i18n from './i18n';

const root = ReactDOM.createRoot(document.getElementById('root')!);

/**
 * DESKTOP app 에서 BrowserRouter 를 사용할 수 없어서 MemoryRouter 를 사용한다.
 * WEB 에서는 로그인하지 않고 사용하는 버전을 위해서 MemoryRouter 를 사용한다.
 *
 * 이 때문에 custom page 에서 link 의 state 를 이용해서 customPageId 를 전달하고 있다.
 */
root.render(
  DESKTOP_ENVIRONMENT ? (
    <MemoryRouter>
      <I18nextProvider i18n={i18n}>
        <App />
      </I18nextProvider>
    </MemoryRouter>
  ) : (
    <BrowserRouter>
      <I18nextProvider i18n={i18n}>
        <App />
      </I18nextProvider>
    </BrowserRouter>
  ),
);
