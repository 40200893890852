import Hands from 'assets/floor/hands.svg';
import { useTranslation } from 'react-i18next';
import styles from './MultiTouchPopup.module.scss';

const MultiTouchPopup = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.icon}>
        <img src={Hands} alt='hand' />
      </div>
      <div className={styles.content}>{t(`popup.multiTouch`)}</div>
    </div>
  );
};

export default MultiTouchPopup;
