import dayjs from 'dayjs';

export const getTimeStamp = () => {
  const time = dayjs().format('mm:ss:SSS');

  return `${time}`;
};

export const getUnix = () => {
  const time = dayjs().unix();

  return time;
};

export const getUnixMillis = () => {
  const time = Date.now();

  return time;
};
