import { PathType } from 'types/common/common.type';
import { MainMenu } from 'types/feature/menu.type';
import { create } from 'zustand';

type Location = {
  hash: string;
  key: string;
  pathname: string;
  search: string;
  state: { pageId: string | null };
};

type MenuStoreProps = {
  mainMenus: MainMenu[] | [];
  setMenus: (menus: MainMenu[]) => void;
  curMenu: string;
  setCurMenu: (location: Location, menus: MainMenu[]) => void;
};

const useMenuStore = create<MenuStoreProps>((set, get) => ({
  mainMenus: [],
  setMenus(menus: MainMenu[]) {
    const mainMenuList: MainMenu[] = menus.map(menu => {
      return {
        id: menu.id,
        type: menu.type.toLowerCase().replace('_', '-'),
        name: menu.name,
        iconUsed: menu.iconUsed,
        mainIconUri: menu.mainIconUri,
        subIconUri: menu.subIconUri,
        customPageId: menu.customPageId,
      };
    });

    set({ mainMenus: mainMenuList });
  },
  curMenu: '',
  setCurMenu(location: Location, menus: MainMenu[]) {
    if (location.pathname === PathType.customBoard) {
      const curMenuObj = menus.filter(menu => menu.customPageId === location.state.pageId)[0];
      set({ curMenu: curMenuObj?.id });
    } else {
      const curMenuObj = menus.filter(menu => menu.type === location.pathname.slice(1))[0];
      set({ curMenu: curMenuObj?.id });
    }
  },
}));

export default useMenuStore;
