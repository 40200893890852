export const COLORS = {
  BLUE_BLACK: '#272a36',
  WHITE: '#fff',
  BLACK: '#060a1b',
  GRAY_CC: '#ccc',
};

// color type 에서 사용
export const BASIC_COLOR_SET = {
  MAIN: '#4F74F9',
  SUB: '#272A36',
  NAVI: '#2CC4CE',

  TEXT_TITLE: '#444',
  TEXT_CATEGORY: '#777',
  TEXT_ITEM: '#999',

  HEADER_BG: '#FFF',
  HEADER_TEXT: '#272A36',

  GNB_BG: '#FFF',
  GNB_BG_ACTIVE: '#FFF',
  GNB_TEXT_ACTIVE: '#FFF',

  FOOTER_BG: '#22252E',
  FOOTER_TEXT: '#CCC',

  BUTTON_BORDER: '#272A36',
  BUTTON_TEXT: '#242335',

  TAB_BUTTON_ACTIVE: '#222222',
  TAB_BUTTON_INACTIVE: '#999999',

  FLOOR_LIST_BG: '#FFFFFF',
  FLOOR_TEXT_ACTIVE: '#FFFFFF',

  LIST_BG: '#F9F9F9',
  LIST_ITEM_BORDER: '#EAEAEA',

  INPUT_BG: '#FFFFFF',
  INPUT_ACTIVE: '#444444',
  INPUT_INACTIVE: '#999999',

  MOBILE_HERE_BG: '#f9f9f9',
} as const;
