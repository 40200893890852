/* eslint-disable react-hooks/exhaustive-deps */
import NoCurrentFloorFacilityPopup from 'component/common/popup/toast/NoCurrentFloorFacilityPopup';
import useStats from 'hooks/fetch/stats/useStats';
import { drawFacilityMarkers } from 'map/control/facilityMarker';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useCategoryGroupStore from 'stores/categoryGroup';
import useColorSetStore from 'stores/colorSet';
import useFacilityStore from 'stores/facility';
import useFloorStore from 'stores/floor';
import useLanguageStore from 'stores/languages';
import usePointStore from 'stores/point';
import useQrStore from 'stores/qr';
import { CategoryGroup } from 'types/common/category.type';
import { TenantWithPointId } from 'types/common/tenant.type';
import { Marker } from 'types/dabeeomaps/marker.type';
import FloorPointSelect from '../point-select/FloorPointSelect';
import styles from './FacilityBar.module.scss';
import FacilityItem from './item/FacilityItem';

type FacilityBarProps = {
  isOpenPopup: boolean;
  handleOpenPopup: () => void;
  handleClosePopup: () => void;
};

const FacilityBar = ({ isOpenPopup, handleOpenPopup, handleClosePopup }: FacilityBarProps) => {
  const categoryStore = useCategoryGroupStore();
  const qrStore = useQrStore();
  const floorStore = useFloorStore();
  const langStore = useLanguageStore();
  const facilityStore = useFacilityStore();
  const pointStore = usePointStore();
  const colorSetStore = useColorSetStore();

  const [facilityName, setFacilityName] = useState<string>('');

  const { t } = useTranslation();
  const { postCategoryUsage } = useStats();

  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const closePopupAfterSeconds = () => {
    timerRef.current = setTimeout(() => {
      handleClosePopup();
    }, 4000);
  };

  const clearPopupTimer = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    handleClosePopup();
  };

  const openToastPopup = () => {
    handleOpenPopup();
    closePopupAfterSeconds();
  };

  // 해당 카테고리에 포함된 테넌트 검색
  const findTenants = (categoryGroupId: string): TenantWithPointId[] | undefined => {
    if (categoryStore.mapFacilityCategories) {
      const origin = [...categoryStore.mapFacilityCategories.tenants];

      const tenants = origin.filter(tenant => {
        return tenant.categoryGroups.some(category => category.id === categoryGroupId);
      });

      return tenants;
    }
  };

  // 편의시설 클릭 시
  const onClickItem = async (categoryGroup: CategoryGroup) => {
    // 카테고리 통계 수집
    await postCategoryUsage(categoryGroup.id);

    clearPopupTimer();
    setFacilityName(categoryGroup.name[langStore.currentLang]);

    qrStore.setShowNaviQr(false);
    facilityStore.setCurrentFacilityId(categoryGroup.id);

    // zoom control button 상태 초기화
    floorStore.setFloorZoomControl(true);

    // TODO: 할때마다 찾지말고 map 으로 저장해놓고 찾도록 변경
    const tenants: TenantWithPointId[] | undefined = findTenants(categoryGroup.id);

    if (tenants) {
      const currentFloorMarkerList: Marker[] | undefined = await drawFacilityMarkers(
        tenants,
        floorStore.currentFloorId,
      );

      // 현재 층의 마커가 없다면 토스트팝업 노출
      if (!currentFloorMarkerList || currentFloorMarkerList.length < 1) {
        openToastPopup();
        return;
      }
    }

    handleClosePopup();
    clearPopupTimer();
  };

  useEffect(() => {
    return () => {
      clearPopupTimer();
    };
  }, []);

  return (
    <div style={{ background: colorSetStore.header.bg }} className={styles.container}>
      <div className={`${styles.title_container} ${pointStore.points.length > 1 && styles.border_bottom}`}>
        {/* point 가 한 개 보다 많을 경우 select 노출 */}
        {pointStore.points.length > 1 && <FloorPointSelect />}

        {/* 주요시설 */}
        <div className={styles.title} style={{ color: colorSetStore.tabButton.active }}>
          {t(`floor.mainFacility`)}

          {/* 밑줄 - 포인트 select 있을 경우 비노출 */}
          {pointStore.points.length <= 1 && (
            <div
              style={{
                background: colorSetStore.tabButton.active,
              }}
              className={styles.border_after}
            />
          )}
        </div>
      </div>
      <div className={styles.facilities}>
        <div className={styles.facilities_wrapper}>
          {categoryStore.mapFacilityCategories?.categoryGroups
            // TODO: 테넌트 없는 카테고리 필터링
            // .filter(group => group.tenants?.length > 0)
            .map(categoryGroup => (
              /**
               * ! holding 2023-10-06
               * 어떻게 할지 논의 후 수정 예정
               * 우선 category group의 첫번째 poiCategoryCode로 보여주고 있음
               */
              <FacilityItem
                key={categoryGroup.id ?? ''}
                categoryGroup={categoryGroup}
                handleClickItem={onClickItem}
                isActive={categoryGroup.id === facilityStore.currentFacilityId}
              />
            ))}
        </div>
      </div>

      {isOpenPopup && <NoCurrentFloorFacilityPopup facilityName={facilityName} />}
    </div>
  );
};

export default FacilityBar;
