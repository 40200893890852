import { EndPoint } from 'data/endPoints';
import { TokenType } from 'data/token';
import useCookie from 'hooks/cookie/useCookie';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PathType } from 'types/common/common.type';
import useNoLoginAxios from 'utils/axios/noLoginAxios';
import { Auth } from './useKioskLogin';

type Props = {
  userName: string;
};

/**
 * 로그인 없는 WEB 버전용 로그인 hook
 * DESKTOP 은 사용하지 않음
 */
const useWebNoLogin = ({ userName }: Props) => {
  const { api } = useNoLoginAxios();
  const { setCookie, deleteCookie } = useCookie();
  const navigate = useNavigate();

  const sendNoLogin = async () => {
    try {
      deleteCookie(TokenType.WEB);
      // username 으로 token 을 발급한다.
      // id 는 backend 와 맞춰야 하고, pwd 는 아무거나 입력 가능
      const authResponse = await api.post<Auth>(EndPoint.auth, {
        username: userName,
        password: 'password',
      });

      if (authResponse) {
        // token 을 Cookie 에 저장한다.
        setCookie(TokenType.WEB, authResponse.token);
      }
    } catch (error) {
      // error 발생 시 이동할 route 가 없어서 not found 로 이동
      navigate(PathType.notFound);
    }
  };

  useEffect(() => {
    sendNoLogin();
  }, []);

  return { sendNoLogin };
};
export default useWebNoLogin;
