import { Floor } from 'types/common/floor.type';
import { MultiLang } from 'types/common/language.type';
import { create } from 'zustand';

type FloorStore = {
  // pointId 별 floor Map
  pointFloorsMap: Map<string, Floor[]> | null;
  setPointFloorsMap: (floorsMap: Map<string, Floor[]>) => void;

  // buildingId 별 floor Map
  buildingFloorsMap: Map<string, Floor[]> | null;
  setBuildingFloorsMap: (floorsMap: Map<string, Floor[]>) => void;

  // 현재 point 의 floors
  floors: Floor[];
  setFloors: (floors: Floor[]) => void;

  // floor id
  currentFloorId: string;
  setCurrentFloorId: (floors: string) => void;

  // zoom state
  floorZoomControl: boolean;
  setFloorZoomControl: (status: boolean) => void;

  // 층 이름
  floorName: MultiLang;
  setFloorName: (floorName: MultiLang) => void;
};

const useFloorStore = create<FloorStore>((set, get) => ({
  pointFloorsMap: null,
  setPointFloorsMap(floorsMap: Map<string, Floor[]>) {
    set(() => ({
      pointFloorsMap: floorsMap,
    }));
  },

  buildingFloorsMap: null,
  setBuildingFloorsMap(buildingFloorsMap: Map<string, Floor[]>) {
    set(() => ({
      buildingFloorsMap,
    }));
  },

  floors: [],
  setFloors(floors: Floor[]) {
    set(() => ({
      floors,
    }));
  },

  currentFloorId: '',
  setCurrentFloorId(floor: string) {
    set(() => ({
      currentFloorId: floor,
    }));
  },

  floorZoomControl: false,
  setFloorZoomControl(status: boolean) {
    set(() => ({
      floorZoomControl: status,
    }));
  },

  floorName: { ko: '', en: '', ja: '', cn: '', tw: '' },
  setFloorName(floorName: MultiLang) {
    set(() => ({
      floorName,
    }));
  },
}));

export default useFloorStore;
