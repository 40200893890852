import { COLORS } from 'constants/colors';
import useStats from 'hooks/fetch/stats/useStats';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import useCategoryGroupStore from 'stores/categoryGroup';
import useColorSetStore from 'stores/colorSet';
import useLanguageStore from 'stores/languages';
import usePointStore from 'stores/point';
import useSwiperStore from 'stores/swiper';
import useTenantStore from 'stores/tenant';
import { CategoryGroupWithTenants } from 'types/common/category.type';
import { SearchTabType } from 'types/feature/tenantFilter.type';
import styles from './CategoryController.module.scss';

type Props = {
  currentFilter: SearchTabType;
};

const CategoryController = ({ currentFilter }: Props) => {
  // store
  const langStore = useLanguageStore();
  const swiperStore = useSwiperStore();
  const tenantStore = useTenantStore();
  const categoryStore = useCategoryGroupStore();
  const colorSetStore = useColorSetStore();
  const pointStore = usePointStore();

  // state
  const [activeCategory, setActiveCategory] = useState('ALL');

  // hook
  const { postCategoryUsage } = useStats();

  // 내 point 의 category 를 처음에 보여주자
  const setMyPointCategoryFilter = () => {
    if (categoryStore.tenantSearchCategoriesMap && pointStore.selectedFilterPoint) {
      const pointCategoryFilter: CategoryGroupWithTenants | undefined = categoryStore.tenantSearchCategoriesMap.get(
        pointStore.selectedFilterPoint.id,
      );

      if (pointCategoryFilter) {
        categoryStore.setCategoryTabs(pointCategoryFilter.categoryGroups);
      }
    }
  };

  // 현재 point 의 tenants 로 세팅
  const setMyPointTenants = () => {
    if (categoryStore.tenantSearchCategories) {
      tenantStore.setFilteredTenants(categoryStore.tenantSearchCategories.tenants, langStore.currentLang);
    }
  };

  // tenant 목록 세팅
  const handleFilteredTenants = (categoryId: string) => {
    if (categoryStore.tenantSearchCategories) {
      if (categoryId === 'ALL') {
        // 전체
        setMyPointTenants();
      } else {
        // 전체 아닐 경우
        const origin = categoryStore.tenantSearchCategories.tenants;

        const tenants = origin.filter(tenant => {
          return tenant.categoryGroups.some(category => category.id === categoryId);
        });

        if (tenants) {
          tenantStore.setFilteredTenants(tenants, langStore.currentLang);
        }
      }
    }
  };

  // 카테고리 클릭 시
  const onClickCategory = async (categoryGroupId: string) => {
    // 전체가 아닐경우 카테고리 통계 수집
    if (categoryGroupId !== 'ALL') {
      await postCategoryUsage(categoryGroupId);
    }

    swiperStore.moveTenantSwiperToFirstSlide();
    setActiveCategory(categoryGroupId);
    handleFilteredTenants(categoryGroupId);
  };

  useEffect(() => {
    setActiveCategory('ALL');
  }, [langStore.currentLang, pointStore.point]);

  useEffect(() => {
    swiperStore.moveTenantSwiperToFirstSlide();
  }, [langStore.currentLang]);

  useEffect(() => {
    setMyPointCategoryFilter();
  }, [currentFilter]);

  return (
    <div className={styles.container}>
      <div className={styles.category_controller}>
        {/* 전체 카테고리 탭 */}
        <div
          style={{
            background: activeCategory === 'ALL' ? colorSetStore.sub : colorSetStore.header.bg,
            borderColor: activeCategory === 'ALL' ? colorSetStore.sub : colorSetStore.button.border,
            color: activeCategory === 'ALL' ? COLORS.WHITE : colorSetStore.button.text,
          }}
          onClick={onClickCategory.bind(this, 'ALL')}
          className={`${activeCategory === 'ALL' && styles.active}`}
        >
          {t('tenant.allCategories')}
        </div>

        {/* 카테고리 탭 */}
        {categoryStore.categoryTabs.map(({ id, name }) => (
          <div
            key={id}
            style={{
              background: activeCategory === id ? colorSetStore.sub : colorSetStore.header.bg,
              borderColor: activeCategory === id ? colorSetStore.sub : colorSetStore.button.border,
              color: activeCategory === id ? COLORS.WHITE : colorSetStore.button.text,
            }}
            className={`${activeCategory === id && styles.active}`}
            onClick={onClickCategory.bind(this, id)}
          >
            {name[langStore.currentLang]}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CategoryController;
