export const validResolution = () => {
  const width = window.screen.availWidth || window.innerWidth;
  const height = window.screen.availHeight || window.innerHeight;

  const vertical = width / height < 1;
  const horizontal = width / height >= 1;

  if (vertical) {
    const vertiMinSize = width < 600 || height < 800;

    if (vertiMinSize) {
      return false;
    }
  }

  if (horizontal) {
    const horiMinSize = width < 800 || height < 600;

    if (horiMinSize) {
      return false;
    }
  }

  return true;
};
