import ModalContainer from 'component/common/modal/container/ModalContainer';
import MultiTouchPopup from 'component/common/popup/touch/MultiTouchPopup';
import useModal from 'hooks/modal/useModal';
import useMultiTouchPopup from 'hooks/popup/useMultiTouchPopup';
import ActionLoadingPage from 'pages/ActionLoadingPage';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useMapActionStore from 'stores/action';
import useCategoryGroupStore from 'stores/categoryGroup';
import useFloorStore from 'stores/floor';
import useLoadingStore from 'stores/loading';
import useMachineStore from 'stores/machine';
import usePointStore from 'stores/point';
import { Floor } from 'types/common/floor.type';
import styles from './FloorContainer.module.scss';
import FacilityBar from './facility/FacilityBar';
import FloorHeader from './header/FloorHeader';
import MapContainer from './map/Map';

const FloorContainer = () => {
  const { showPopup } = useMultiTouchPopup();
  const { isVisibleModal, openModal, closeModal, modalType } = useModal();
  const { t } = useTranslation();

  const loadingStore = useLoadingStore();
  const actionStore = useMapActionStore();
  const categoryStore = useCategoryGroupStore();
  const pointStore = usePointStore();
  const machineStore = useMachineStore();
  const floorStore = useFloorStore();

  const [isOpenPopup, setOpenPopup] = useState<boolean>(false);

  const modalProps = {
    isVisibleModal,
    openModal,
    closeModal,
    modalType,
    categoryFeature: 'MAP_FACILITY',
  };

  const removeNoFacilityToast = () => {
    setOpenPopup(false);
  };

  const handleOpenPopup = () => {
    setOpenPopup(true);
  };

  // 카테고리 설정
  const initCategoryGroups = () => {
    if (pointStore.point && categoryStore.mapFacilityCategoriesMap) {
      const pointCategory = categoryStore.mapFacilityCategoriesMap.get(pointStore.point.id);

      if (pointCategory) {
        categoryStore.setCategoryList('MAP_FACILITY', pointCategory);
      }
    }
  };

  //  층 세팅
  const initFloor = () => {
    if (machineStore.machine && floorStore.pointFloorsMap) {
      const findFloors: Floor[] | undefined = floorStore.pointFloorsMap.get(machineStore.machine.point.id);

      if (findFloors) {
        floorStore.setFloors(findFloors);
      }
    }
  };

  // 층 이름
  const initFloorName = () => {
    if (machineStore.machine) {
      floorStore.setFloorName(machineStore.machine.floor.name);
    }
  };

  // 페이지 마운트 시 층별안내 상태 값 초기 설정
  const initFloorPage = () => {
    if (machineStore.machine) {
      pointStore.setSelectedPoint(machineStore.machine.point);

      initCategoryGroups();

      initFloor();
      initFloorName();
    }
  };

  useEffect(() => {
    initFloorPage();
  }, []);

  // 처음 지도 로딩 시, 지도 층 변경 시, 길찾기 경로 찾을 경우 사용
  const isLoadingMap = loadingStore.isLoadingMap || loadingStore.isActionLoading || loadingStore.isFloorChanging;

  return (
    <div className={styles.container}>
      <FloorHeader />

      {/* 지도 */}
      <MapContainer removeNoFacilityToast={removeNoFacilityToast} {...modalProps} />

      {/* 편의시설 */}
      <FacilityBar
        handleOpenPopup={handleOpenPopup}
        handleClosePopup={removeNoFacilityToast}
        isOpenPopup={isOpenPopup}
      />

      {isVisibleModal && <ModalContainer {...modalProps} isFloorPage />}

      {/* 멀티 터치 팝업 */}
      {!loadingStore.isLoadingMap &&
        showPopup &&
        !(actionStore.actions.navigation || actionStore.actions.transport) && <MultiTouchPopup />}

      {/* 지도 로딩 */}
      {isLoadingMap && (
        <ActionLoadingPage
          message={loadingStore.isActionLoading ? `${t(`loading.action`)}` : `${t(`loading.showMap`)}`}
        />
      )}
    </div>
  );
};
export default FloorContainer;
