import { EndPoint } from 'data/endPoints';
import useTenantStore from 'stores/tenant';
import { LangCode } from 'types/common/language.type';
import { Tenant, TenantWithPointId, TenantsOfPoint } from 'types/common/tenant.type';
import useAxios from 'utils/axios/fetchAxios';
import { convertMultiLang } from 'utils/multi-lang/convertMultiLang';

const useTenant = () => {
  const tenantStore = useTenantStore();

  const { api } = useAxios();

  // 정제한 tenants 를 poiId 별로 mapping
  const setPoiTenantsMap = (tenantsWithPointId: TenantWithPointId[]) => {
    const pointTenantsMap = new Map<string, TenantWithPointId[]>();

    tenantsWithPointId.forEach(tenant => {
      // poiId 있는지 없는지 check
      const findTenant: TenantWithPointId[] | undefined = pointTenantsMap.get(tenant.content.poiId);

      // 같은 poiId 없을 경우 set
      if (!findTenant) {
        pointTenantsMap.set(tenant.content.poiId, [tenant]);
      } else {
        // 같은 poiId 있을 경우 찾은 value 에 add
        findTenant.push(tenant);
      }
    });

    tenantStore.setPoiTenantsMap(pointTenantsMap);
  };

  /**
   * 테넌트 목록을 조회한다.
   * 사용하는 테넌트만 필터링 후, 각 테넌트에 pointId를 넣어준다.
   * @param {LangCode} mainLang 메인 언어
   * @returns {Promise<TenantWithPointId[] | undefined>}
   */
  const getRawTenants = async (mainLang: LangCode): Promise<TenantWithPointId[] | undefined> => {
    const tenantsOfPoints: TenantsOfPoint[] | undefined = await api.get<TenantsOfPoint[]>(EndPoint.tenants);

    // TODO: Map 으로 변환하는 작업도 할 수 있다.
    // pointId 를 가지고 있는 tenant 를 만들자!
    if (tenantsOfPoints) {
      const tenantsWithPointId: TenantWithPointId[] = tenantsOfPoints
        .map(tenantsOfPoint => {
          // 사용하는 테넌트만 저장
          const usedTenantsOfPoint: Tenant[] = tenantsOfPoint.tenants.filter(tenant => tenant.used);

          // 내용 다국어 변환
          const parsedTenants: TenantWithPointId[] = usedTenantsOfPoint.map(usedTenant => {
            // 커스텀 필드
            usedTenant.tenantCustomFields.forEach(customField => {
              convertMultiLang(customField.title, mainLang);
              convertMultiLang(customField.content, mainLang);
            });

            // 카테고리 그룹
            usedTenant.categoryGroups.forEach(group => {
              convertMultiLang(group.name, mainLang);
            });

            // pointId 넣어준다
            return {
              ...usedTenant,
              name: convertMultiLang(usedTenant.name, mainLang),
              description: convertMultiLang(usedTenant.description, mainLang),
              tenantCustomFields: usedTenant.tenantCustomFields,
              categoryGroups: usedTenant.categoryGroups,
              pointId: tenantsOfPoint.pointId,
            };
          });

          return parsedTenants;
        })
        .flat();

      // 전체 points 의 tenant list
      tenantStore.setRawTenants(tenantsWithPointId);

      setPoiTenantsMap(tenantsWithPointId);

      return tenantsWithPointId;
    }
  };

  return { getRawTenants };
};
export default useTenant;
