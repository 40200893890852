import { DESKTOP_ENVIRONMENT } from 'constants/environment';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PathType } from 'types/common/common.type';

type AuthCheckProps = {
  screen: React.ReactElement;
  isProtected: boolean;
  isError: boolean;
  isLoading: boolean;
  token: string;
};

const AuthCheck = ({ screen, isProtected, isLoading, isError, token }: AuthCheckProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  /**
   * WEB, DESKTOP 공통 로딩 관리
   * 로딩 중 -> 로딩 페이지
   * 로딩 완료 -> 메인 페이지
   * 에러 발생 -> 에러 페이지
   */
  useEffect(() => {
    /**
     * 에러 페이지는 페이지를 이동하지 않는다.
     */
    if (isError) {
      return;
    }

    /**
     * ! 로그인이 필요없는 페이지로 진입했을 경우 별도 처리한다.
     * WIS -> NoLoginWisPage
     * Cafe Show -> NoLoginCafeShowPage
     */
    if (location.pathname === PathType.noLoginCafeShow || location.pathname === PathType.noLoginWis) {
      /**
       * 토큰이 없거나 로딩중일 경우,
       * 각 페이지 내부에서 별도 로그인을 처리한다.
       * (로그인하는 동안 로딩 페이지 노출)
       */
      if (!token || isLoading) {
        return;
      }

      /**
       * 토큰이 있고, 로딩이 완료되었을 경우 처리
       *
       * Cafe Show -> 층별안내로 이동
       * 나머지 -> 홈으로 이동
       */
      if (location.pathname === PathType.noLoginCafeShow) {
        navigate(PathType.floor);
      } else {
        navigate(PathType.home);
      }

      return;
    }

    /**
     * WEB 환경일 경우
     * 토큰이 없고, 로그인이 필요없는 페이지일 경우에만 로그인 페이지로 이동한다.
     */
    if (!DESKTOP_ENVIRONMENT && !token && isProtected) {
      navigate(PathType.login);
      return;
    }

    /**
     * 로딩중이고, 토큰이 있을 경우 로딩페이지로 이동한다.
     */
    if (isLoading && token) {
      navigate(PathType.loading);
      return;
    }

    /**
     * 토큰이 있고, 로딩이 완료되었다면 홈으로 이동한다.
     */
    if (token && !isLoading) {
      navigate(PathType.home);
    }
  }, [token, isLoading]);

  return screen;
};

export default AuthCheck;
