import { PoiContentKeyword } from 'types/common/poiContentKeyword.type';
import { TenantWithPointId } from 'types/common/tenant.type';
import { create } from 'zustand';

type Store = {
  // keyword 목록
  poiContentKeywords: PoiContentKeyword[];
  setPoiContentKeywords: (poiContentKeywords: PoiContentKeyword[]) => void;

  // 모든 points 의 keyword 별 tenant 목록
  keywordTenantsMap: Map<string, TenantWithPointId[]> | null;
  setKeywordTenantsMap: (keywordTenantsMap: Map<string, TenantWithPointId[]>) => void;
};

const usePoiContentKeywordStore = create<Store>((set, get) => ({
  poiContentKeywords: [],
  setPoiContentKeywords(poiContentKeywords: PoiContentKeyword[]) {
    set(() => ({
      poiContentKeywords,
    }));
  },

  keywordTenantsMap: null,
  setKeywordTenantsMap(keywordTenantsMap: Map<string, TenantWithPointId[]>) {
    return set(() => ({ keywordTenantsMap }));
  },
}));

export default usePoiContentKeywordStore;
