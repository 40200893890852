import { ScreenType } from 'types/common/common.type';
import { create } from 'zustand';

type ScreenTypeStoreType = {
  screenType: ScreenType;
  setScreenType: (screenType: ScreenType) => void;
};
const useScreenTypeStore = create<ScreenTypeStoreType>((set, get) => ({
  screenType: ScreenType.hori,

  setScreenType(screenType: ScreenType) {
    set(() => ({
      screenType,
    }));
  },
}));

export default useScreenTypeStore;
