import useFloor from 'hooks/floor/useFloor';
import useColorSetStore from 'stores/colorSet';
import useLanguageStore from 'stores/languages';
import { TenantWithPointId } from 'types/common/tenant.type';
import styles from './FloorName.module.scss';

type Props = {
  tenant: TenantWithPointId;
};

const FloorName = ({ tenant }: Props) => {
  const langStore = useLanguageStore();
  const colorSetStore = useColorSetStore();

  const { findFloorName } = useFloor();

  return (
    <div style={{ color: colorSetStore.text.title }} className={styles.floor_name}>
      {findFloorName(tenant?.content.floorId ?? '', tenant?.pointId ?? '')?.[langStore.currentLang] || '-'}
    </div>
  );
};
export default FloorName;
