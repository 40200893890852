/* eslint-disable no-useless-catch */
import { ModalType } from 'component/common/modal/container/ModalContainer';
import { EndPoint } from 'data/endPoints';
import { TokenType } from 'data/token';
import useCookie from 'hooks/cookie/useCookie';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PathType } from 'types/common/common.type';
import loginApi from 'utils/axios/loginAxios';

export type Auth = {
  accessKey: string;
  token: string;
};

type LoginInfo = {
  username: string;
  password: string;
};

type KioskLoginProps = {
  openModal: (modalType: ModalType) => void;
  isUserRemember: boolean;
};

const useKioskLogin = ({ openModal, isUserRemember }: KioskLoginProps) => {
  const navigate = useNavigate();
  const { setCookie, getCookie, deleteCookie } = useCookie();
  const [loginInfo, setLoginInfo] = useState<LoginInfo>({
    username: '',
    password: '',
  });

  const completeFillOutLoginForm = loginInfo.username !== '' && loginInfo.password !== '';
  const saveAccountInCookie = () => (isUserRemember ? setCookie('id', loginInfo.username) : deleteCookie('id'));

  useEffect(() => {
    if (getCookie('id') !== '') {
      setLoginInfo({ ...loginInfo, username: getCookie('id') || '' });
    }
  }, []);

  const [id, setId] = useState<string>('');
  const [pw, setPw] = useState<string>('');

  // login 폼 입력 관리
  const handleLoginUser = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setLoginInfo({
      ...loginInfo,
      [name]: value,
    });
  };

  // 키보드로 입력시
  const handleLoginUserByKeyboard = (input: string, name: string) => {
    setLoginInfo({
      ...loginInfo,
      [name]: input,
    });
  };

  const handleSearchedValue = (input: string, name: string) => {
    if (name === 'username') {
      const newInput: string = id + input;
      setId(newInput);
      handleLoginUserByKeyboard(newInput, name);
    } else {
      const newInput: string = pw + input;
      setPw(newInput);
      handleLoginUserByKeyboard(newInput, name);
    }
  };

  const removeOneValue = (name: string) => {
    if (name === 'username') {
      const newInput: string = id.slice(0, -1);
      setId(newInput);
      handleLoginUserByKeyboard(newInput, name);
    } else {
      const newInput: string = pw.slice(0, -1);
      setPw(newInput);
      handleLoginUserByKeyboard(newInput, name);
    }
  };
  // loginInfo 삭제
  const deleteLoginInfo = (name: string) => {
    setId('');
    setPw('');
    setLoginInfo({
      ...loginInfo,
      [name]: '',
    });
  };

  // web버전에서 token 발급
  // web => 로그인 필수, desktop 토큰을 따로 받아옴.
  const getAuth = async () => {
    try {
      const result = await loginApi.post<Auth>(EndPoint.auth, loginInfo);
      return result;
    } catch (error) {
      throw error;
    }
  };

  const login = async (e: any) => {
    e.preventDefault();
    if (completeFillOutLoginForm) {
      try {
        const result = await getAuth();

        if (result) {
          saveAccountInCookie();
          setCookie(TokenType.WEB, result.token);
          navigate(PathType.home);
        }
      } catch {
        openModal(ModalType.account);
      }
    }
  };

  return {
    login,
    getAuth,
    handleLoginUser,
    handleLoginUserByKeyboard,
    deleteLoginInfo,
    loginInfo,
    completeFillOutLoginForm,
    handleSearchedValue,
    removeOneValue,
  };
};

export default useKioskLogin;
