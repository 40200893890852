import LOADING from 'assets/loading/action-loading.gif';
import styles from './Pages.module.scss';

// TODO: component => common 으로 이동
const ActionLoadingPage = ({ message }: { message: string }) => {
  return (
    <button type='button' disabled className={styles.action_loading}>
      <div className={styles.gif_wrapper}>
        <img src={LOADING} alt='loading' />
      </div>

      <span className={styles.loading_text}>{message}</span>
    </button>
  );
};
export default ActionLoadingPage;
