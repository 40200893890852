import { useTranslation } from 'react-i18next';
import useColorSetStore from 'stores/colorSet';
import useLanguageStore from 'stores/languages';
import useTenantStore from 'stores/tenant';
import styles from './TenantCustomField.module.scss';
import QrField from './qr-field/QrField';

const QR_CUSTOM_FIELD_ID = '08C6DAAAE717426198404853FA201311'; // QR fleid id

const TenantCustomField = () => {
  const tenantStore = useTenantStore();
  const langStore = useLanguageStore();
  const colorSetStore = useColorSetStore();

  const { t } = useTranslation();

  const isValidQr = (url: string) => {
    if (url.length < 1) return false;

    const urlPattern = new RegExp(
      '^(https?:\\/\\/)?' + // 프로토콜
        '((([a-zA-Z\\d]([a-zA-Z\\d-]*[a-zA-Z\\d])*)\\.)+[a-zA-Z]{2,}|' + // 도메인 이름
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // 또는 IP (IPv4)
        '(\\:\\d+)?(\\/[-a-zA-Z\\d%_.~+]*)*' + // 포트 및 경로
        '(\\?[;&a-zA-Z\\d%_.~+=-]*)?' + // 쿼리 문자열
        '(\\#[-a-zA-Z\\d_]*)?$', // 프래그먼트
      'i',
    );
    return !!urlPattern.test(url);
  };

  return (
    <div className={styles.detail_grid}>
      {tenantStore.currentTenant?.tenantCustomFields?.map((field, index) => {
        // 상세보기 QR
        if (field.customFieldId === QR_CUSTOM_FIELD_ID) {
          if (isValidQr(field.content?.[langStore.currentLang])) {
            return <QrField field={field} />;
          }
          // url 검증 오류 시 표출 X
          return <div />;
        }

        return (
          <div key={index} className={styles.detail}>
            {/* 제목 */}
            <div style={{ color: colorSetStore.text.item }} className={styles.field_title}>
              {field.title[langStore.currentLang]}
            </div>

            {/* 내용 */}
            <div style={{ color: colorSetStore.text.title }} className={styles.field_content}>
              <div className={styles.content}>{field.content?.[langStore.currentLang] || '-'}</div>

              {/* 가이드 */}
              {field.content?.[langStore.currentLang].includes('http') && (
                <div className={styles.guide}>{t('tenant.link')}</div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default TenantCustomField;
