import { Floor } from 'types/common/floor.type';
import { TenantWithPointId } from 'types/common/tenant.type';

// 매장검색의 층별검색에서 테넌트가 없는 층은 걸러주는 함수 (ex. 주차장)
export const groupingFloors = (tenants: TenantWithPointId[], floors: Floor[]): Record<string, TenantWithPointId[]> => {
  return tenants.reduce<Record<string, TenantWithPointId[]>>((group, tenant) => {
    const findFloor = floors.find(floor => floor.id === tenant.content.floorId);

    if (findFloor) {
      const floorName = findFloor.name.ko;

      return { ...group, [floorName]: [...(group[floorName] ?? []), tenant] };
    }

    return group;
  }, {});
};
