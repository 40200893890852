import { ModalType } from 'component/common/modal/container/ModalContainer';
import React from 'react';
import KIO from 'assets/login/kio.svg';
import styles from './NoAccountPopup.module.scss';

type NoAccountPopupProps = {
  openModal: (type: ModalType) => void;
  closeModal: () => void;
};

const NoAccountPopup = ({ openModal, closeModal }: NoAccountPopupProps) => (
  <div data-testid="no-account" className={styles.container}>
    <div className={styles.icon}>
      <img src={KIO} alt="kiosk" />
    </div>
    <div className={styles.text}>
      <em>
        `아이디와 비밀번호를
        <br /> 다시 확인해주세요..!`
      </em>
    </div>
  </div>
);

export default NoAccountPopup;
