import styles from './Pages.module.scss';

const ResolutionErrorPage = () => {
  return (
    <div className={styles.resolution}>
      <div>600*800 또는 800*600 이상의 해상도만 지원합니다.</div>
    </div>
  );
};
export default ResolutionErrorPage;
