const useCookie = () => {
  const setCookie = (name: string, value: string) => {
    // 일주일로 쿠키 설정
    const oneWeek = new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000);
    const expireTime = oneWeek.toUTCString();

    const expiredTime = new Date();
    const expires = expiredTime.getTime() + 1000 * 60;

    // document.cookie = `${name}=${value}; path=/; expires=${expireTime}`;
    document.cookie = `${name}=${value}; path=/; expires=${expires}`;
  };

  const deleteCookie = (name: string) => {
    document.cookie = `${name}=; max-age: 0`;
  };

  const getCookie = (name: string) => {
    const matches = document.cookie.match(
      new RegExp(`(?:^|; )${name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1')}=([^;]*)`),
    );
    return matches ? decodeURIComponent(matches[1]) : '';
  };

  return {
    setCookie,
    deleteCookie,
    getCookie,
  };
};

export default useCookie;
