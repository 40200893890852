import TenantDetailQr from 'component/common/qr/container/tenant/TenantDetailQr';
import { useTranslation } from 'react-i18next';
import styles from '../Map.module.scss';

// 길찾기 버튼 클릭 시 지도에 보여지는 QR
// 모바일로 진입 시 지도에서 해당 tenant 의 위치로 이동 + marker
const MapQr = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.qr_wrapper}>
      <div className={styles.text}>{t(`qr.mobile_navi`)}</div>

      <div className={styles.tenant_qr}>
        <TenantDetailQr />
      </div>
    </div>
  );
};
export default MapQr;
