import DOWN_DISABLE from 'assets/floor/down-disable.svg';
import DOWN from 'assets/floor/down.svg';
import UP_DISABLE from 'assets/floor/up-disable.svg';
import UP from 'assets/floor/up.svg';

export const ICONS = {
  UP,
  UP_DISABLE,

  DOWN,
  DOWN_DISABLE,
};
