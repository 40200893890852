import CustomPageContainer from 'component/custom-page/container/CustomPageContainer';
import styles from './Pages.module.scss';

const CustomBoardPage = () => {
  return (
    <div className={styles.customBoard}>
      <CustomPageContainer />
    </div>
  );
};

export default CustomBoardPage;
