/* eslint-disable react/jsx-no-useless-fragment */
import InfoGroup from 'component/common/info/group/basic/InfoGroup';
import { ModalType } from 'component/common/modal/container/ModalContainer';
import useLanguageStore from 'stores/languages';
import useTenantStore from 'stores/tenant';
import TenantButtonList from '../../components/button/TenantButtonList';
import KeywordsViewer from '../../components/keywords/KeywordsViewer';
import styles from './SimpleTenantPopup.module.scss';

type Props = {
  openModal: (type: ModalType) => void;
  closeModal: () => void;
};

const SimpleTenantPopup = (props: Props) => {
  const tenantStore = useTenantStore();
  const langStore = useLanguageStore();

  if (!tenantStore.currentTenant) {
    return <> </>;
  }

  return (
    <div className={`${styles.wrapper}`}>
      <div className={styles.header}>
        <div className={styles.title}>{tenantStore.currentTenant?.name[langStore.currentLang]}</div>

        <div className={styles.details_wrapper}>
          <InfoGroup tenant={tenantStore.currentTenant} categoryOn />
        </div>
      </div>

      {/* 키워드 */}
      {tenantStore.currentTenant && tenantStore.currentTenant.keywords.length > 0 && <KeywordsViewer />}

      {/* 버튼 */}
      <TenantButtonList {...props} />
    </div>
  );
};
export default SimpleTenantPopup;
