// import DateTime from 'utils/dateTime/dateTime';
import useClock from 'hooks/clock/useClock';
import useColorSetStore from 'stores/colorSet';
import styles from './Clock.module.scss';

const Clock = () => {
  const realTime = useClock();
  const colorSetStore = useColorSetStore();

  return (
    <div className={styles.clock}>
      {/* 시계만 사용한다고 했지만 나중에 갑자기 날짜도 사용하자고 할 수도 있어서 주석처리 함 */}
      {/* YYYY.MM.DD (ddd) */}
      {/* <div className={styles.date}>
        <span>
          {new DateTime().getCurrentYear()}
          <span className={styles.dot}>.</span>
          {new DateTime().getCurrentMonth()}
          <span className={styles.dot}>.</span>
          {new DateTime().getCurrentDays()}
        </span>
        <span className={styles.day}>{new DateTime().getCurrentDay()}</span>
      </div> */}

      {/* A:hh:mm */}
      <div
        style={{
          color: colorSetStore.footer.text,
        }}
        className={styles.time}
      >
        <span className={styles.type}>{realTime?.slice(0, 2)}</span>
        <span>{realTime?.slice(2)}</span>
      </div>
    </div>
  );
};
export default Clock;
