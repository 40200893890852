import { isStartWithKoEn } from '../start-with/isStartWithKoEn';
import { isStartWithZh } from '../start-with/isStartWithZh';

/**
 * 정렬 순서
 * 한글 -> 영어 -> 숫자 -> 중국어 -> 일본어
 */
export const sortByLangOrder = (a: string, b: string): number => {
  // a - 한글/영어 x
  if (!isStartWithKoEn(a)) {
    // a - 한글/영어 x, b - 한글/영어 x
    if (!isStartWithKoEn(b)) {
      // a, b - 중국어
      if (isStartWithZh(a) && isStartWithZh(b)) {
        return a.localeCompare(b, 'zh-CN'); // 중국어는 종류가 많아서 locale 적어줘야 함
      }

      // 나머지 경우
      return a.localeCompare(b);
    }

    // a - 한글/영어 x, b - 한글/영어 o
    // 한글 또는 영어로 시작하는 단어 우선 정렬
    return 1;
  }

  // a - 한글/영어 o,  b - 한글/영어 x
  // 한글 또는 영어로 시작하는 단어 우선 정렬
  if (!isStartWithKoEn(b)) {
    return -1;
  }

  // a, b - 한글/영어 o
  return a.localeCompare(b);
};
