import React from 'react';
import styles from './Pages.module.scss';

const NotFound = () => (
  <div className={styles.not_found}>
    <div>등록되지 않은 페이지입니다.</div>
  </div>
);

export default NotFound;
