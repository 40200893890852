import { DEFAULT_IMAGE } from 'constants/defaultImage';
import useIntersectionObserver from 'hooks/observer/useIntersectionObserver';
import { useEffect, useRef } from 'react';
import useLanguageStore from 'stores/languages';
import { TenantWithPointId } from 'types/common/tenant.type';
import styles from './TenantSelectItem.module.scss';

type Props = {
  handleShowTopDim: (state: boolean) => void;
  handleShowBottomDim: (state: boolean) => void;
  isFirst: boolean;
  isLast: boolean;
  tenant: TenantWithPointId;
  onClick: (tenant: TenantWithPointId) => void;
  isActive: boolean;
};

const TenantSelectItem = ({
  isFirst,
  isLast,
  tenant,
  onClick,
  handleShowBottomDim,
  handleShowTopDim,
  isActive,
}: Props) => {
  const langStore = useLanguageStore();
  const itemRef = useRef<HTMLDivElement>(null);

  const entry = useIntersectionObserver(itemRef, {});

  useEffect(() => {
    if (isFirst) {
      if (entry?.isIntersecting) {
        handleShowTopDim(false);
        return;
      }
      handleShowTopDim(true);
    }

    if (isLast) {
      if (entry?.isIntersecting) {
        handleShowBottomDim(false);
        return;
      }
      handleShowBottomDim(true);
    }
  }, [entry?.isIntersecting]);

  return (
    <div className={styles.item}>
      <div
        ref={itemRef}
        className={`${styles.container} ${isActive && styles.selected}`}
        onClick={onClick.bind(this, tenant)}
      >
        {/* cover */}
        {!isActive && <div className={styles.cover} />}

        {/* logo */}
        <div className={styles.logo}>
          <img src={tenant.mainLogoUri || DEFAULT_IMAGE} alt='logo' />
        </div>

        {/* name */}
        <div className={styles.name}>
          <div className={styles.text}>{tenant.name[langStore.currentLang]}</div>
        </div>
      </div>
    </div>
  );
};

export default TenantSelectItem;
