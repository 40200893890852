import { COLORS } from 'constants/colors';
import { SEARCH_FILTERS } from 'data/tenantSearchFilter';
import { t } from 'i18next';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import useCategoryGroupStore from 'stores/categoryGroup';
import useColorSetStore from 'stores/colorSet';
import useFloorStore from 'stores/floor';
import useLanguageStore from 'stores/languages';
import usePointStore from 'stores/point';
import { SearchTab, SearchTabType } from 'types/feature/tenantFilter.type';
import FilterPointSelect from '../controller/floor/point-select/FilterPointSelect';
import styles from './SearchTabs.module.scss';

type Props = {
  currentFilter: SearchTabType;
  setCurrentFilter: Dispatch<SetStateAction<SearchTabType>>;
};

const SearchTabs = ({ currentFilter, setCurrentFilter }: Props) => {
  // store
  const pointStore = usePointStore();
  const categoryStore = useCategoryGroupStore();
  const floorStore = useFloorStore();
  const colorSetStore = useColorSetStore();
  const langStore = useLanguageStore();

  // state
  const [searchTabs, setSearchTabs] = useState<SearchTab[]>(SEARCH_FILTERS);
  const [loadingFilter, setLoadingFilter] = useState<boolean>(true);

  // var
  const multiPoints = pointStore.points.length > 1;

  useEffect(() => {
    const handleFilters = () => {
      setLoadingFilter(true);

      let floorsCount = 0;

      // 모든 포인트의 층 갯수 count
      floorStore.pointFloorsMap?.forEach(floors => {
        floorsCount += floors.length;
      });

      let categoryGroupsCount = 0;

      // 모든 포인트의 카테고리 갯수 count
      categoryStore.tenantSearchCategoriesMap?.forEach(categoryMap => {
        categoryGroupsCount += categoryMap.categoryGroups.length;
      });

      if (floorsCount <= 1) {
        // 모든 포인트의 floor 가 총 1개 이하일 때 층 필터는 비노출
        setSearchTabs(prev => prev.filter(tab => tab.type !== SearchTabType.floor));
      }

      if (categoryGroupsCount <= 1) {
        // 모든 포인트의 category 가 총 1개 이하일 때 카테고리 필터는 비노출
        setSearchTabs(prev => prev.filter(tab => tab.type !== SearchTabType.category));
      }

      setLoadingFilter(false);
    };

    handleFilters();
  }, [langStore.currentLang]);

  return (
    <div className={styles.tabs_wrapper} style={{ borderBottom: `${multiPoints && `1px solid ${COLORS.GRAY_CC}`}` }}>
      {/* point 가 한 개일 경우는 포인트 선택박스 비노출 */}
      {multiPoints && (
        <div className={styles.point_select}>
          <FilterPointSelect filterType={currentFilter} />
        </div>
      )}

      {/* 검색 / 층별 검색 / 카테고리 */}
      {loadingFilter ? (
        // 로딩하는 동안 검색 탭만 노출
        <div style={{ color: colorSetStore.tabButton.active }} className={`${styles.tab} ${styles.active}`}>
          {t(`tenant.filter.name`)}

          {!multiPoints && (
            <div
              style={{
                background: colorSetStore.tabButton.active,
              }}
              className={styles.border_after}
            />
          )}
        </div>
      ) : (
        // 로딩 완료 시 모든 탭 노출
        searchTabs.map(({ type, value }) => {
          const isActive = currentFilter === type;
          return (
            <div
              style={{ color: isActive ? colorSetStore.tabButton.active : colorSetStore.tabButton.inactive }}
              onClick={() => setCurrentFilter(type)}
              className={`${styles.tab} ${isActive && styles.active}`}
              key={type}
            >
              {t(`tenant.filter.${value}`)}

              {!multiPoints && isActive && (
                <div
                  style={{
                    background: colorSetStore.tabButton.active,
                  }}
                  className={styles.border_after}
                />
              )}
            </div>
          );
        })
      )}
    </div>
  );
};
export default SearchTabs;
