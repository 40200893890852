import _ from 'lodash';
import { Point } from 'types/common/point.type';
import { create } from 'zustand';

type Props = {
  points: Point[];
  setPoints: (points: Point[]) => void;

  // 기기 포인트 ??
  // TODO: 제거 후 machine 으로 변경
  point: Point | null;
  setPoint: (points: Point) => void;

  // pointId 별 pointsMap
  pointsMap: Map<string, Point> | null;
  setPointsMap: (pointsMap: Map<string, Point>) => void;

  // buildingId 별 pointsMap
  buildingPointsMap: Map<string, Point> | null;
  setBuildingPointsMap: (pointsMap: Map<string, Point>) => void;

  // 층별안내 페이지의 FloorPointSelect 에서 사용하는 선택된 point
  selectedPoint: Point | null;
  setSelectedPoint: (selectedPoint: Point) => void;

  // 매장검색 페이지의 FilterPointSelect 에서 사용하는 선택된 point
  selectedFilterPoint: Point | null;
  setSelectedFilterPoint: (selectedFilterPoint: Point) => void;
};

const usePointStore = create<Props>((set, get) => ({
  points: [],
  setPoints: (points: Point[]) => {
    set(() => ({
      points,
    }));
  },

  point: null,
  setPoint: (point: Point) => {
    set(() => ({
      point,
    }));
  },

  pointsMap: null,
  setPointsMap: (pointsMap: Map<string, Point>) => {
    set(() => ({
      pointsMap,
    }));
  },

  buildingPointsMap: null,
  setBuildingPointsMap: (buildingPointsMap: Map<string, Point>) => {
    set(() => ({
      buildingPointsMap,
    }));
  },

  selectedPoint: null,
  setSelectedPoint: (selectedPoint: Point) => {
    set(() => ({
      selectedPoint: _.cloneDeep(selectedPoint),
    }));
  },

  selectedFilterPoint: null,
  setSelectedFilterPoint: (selectedFilterPoint: Point) => {
    set(() => ({
      selectedFilterPoint: _.cloneDeep(selectedFilterPoint),
    }));
  },
}));

export default usePointStore;
