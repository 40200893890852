import { ActionKey, Actions } from 'types/common/action.type';
import { create } from 'zustand';

type MapActionStoreProps = {
  actions: Actions;

  setMapAction: (action: ActionKey, value: boolean) => void;
  resetMapActions: () => void;
};

// 지도관련 액션 상태관리
const useMapActionStore = create<MapActionStoreProps>((set, get) => ({
  actions: {
    navigation: false,
    location: false,
    floorChange: false,
    floorChanged: false,
    facilityLocation: false,
    completeNavigation: false,
    transport: false,
  },

  setMapAction(action, value) {
    set(state => ({
      actions: {
        ...state.actions,
        [action]: value,
      },
    }));
  },

  resetMapActions() {
    set(() => ({
      actions: {
        navigation: false,
        location: false,
        floorChange: false,
        floorChanged: false,
        facilityLocation: false,
        completeNavigation: false,
        transport: false,
      },
    }));
  },
}));

export default useMapActionStore;
