/* eslint-disable no-nested-ternary */
import useColorSetStore from 'stores/colorSet';
import useLanguageStore from 'stores/languages';
import useMachineStore from 'stores/machine';
import Clock from '../clock/Clock';
import BusinessHour from '../info/business-hour/BusinessHour';
import ClosedDays from '../info/closed-days/ClosedDays';
import MultiLanguageBtn from '../language/MultiLangBtn';
import styles from './VertiMallInfo.module.scss';

// 세로형 mall info
const VertiMallInfo = () => {
  const langStore = useLanguageStore();
  const machineStore = useMachineStore();
  const colorSetStore = useColorSetStore();

  const isShowClosedDays = machineStore.machine?.point.closedDaysUsed;
  const isShowClock =
    !machineStore.machine?.point.hoursUsed &&
    !machineStore.machine?.point.hoursDescription &&
    !machineStore.machine?.point.closedDaysUsed;

  return (
    <div style={{ backgroundColor: colorSetStore.footer.bg }} className={styles.container}>
      <div className={styles.mall_info}>
        {/* (가로/세로) 운영시간과 휴무일 사용하지 않고 운영시간 대체 텍스트도 없을 경우 시계 노출 */}
        {isShowClock && <Clock />}
        <BusinessHour />
        {isShowClosedDays && <ClosedDays />}
      </div>

      {langStore.languages.length >= 2 && <MultiLanguageBtn />}
    </div>
  );
};

export default VertiMallInfo;
