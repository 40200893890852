export const EndPoint = {
  screen: '/v2/api/screensavers',
  machinesV2: '/v2/api/machines',
  auth: '/api/auth',
  deskAuth: '/api/desktop-auth',
  languages: '/api/languages',
  floors: '/api/floors',
  tenants: '/api/tenants',
  tenantCategoryGroups: '/api/tenant-category-groups',
  menusV2: '/v2/api/main-menus',
  customPage: '/api/custom-pages/:customPageId',
  pointsV2: '/v2/api/points',
  poiContentKeywords: '/api/poi-content-keywords',

  // 통계
  usages: '/api/metrics/usages', // 기기 사용량
  tenantsUsage: '/api/metrics/tenants', // 매장 사용량
  categoryGroupsUsage: '/api/metrics/tenant-category-groups', // 카테고리 사용량
};
