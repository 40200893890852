import { EndPoint } from 'data/endPoints';
import useFloorStore from 'stores/floor';
import usePointStore from 'stores/point';
import { Floor, PointFloor } from 'types/common/floor.type';
import { LangCode, MultiLang } from 'types/common/language.type';
import { Point } from 'types/common/point.type';
import useAxios from 'utils/axios/fetchAxios';
import { convertMultiLang } from 'utils/multi-lang/convertMultiLang';

const useFloor = () => {
  const floorStore = useFloorStore();
  const pointStore = usePointStore();

  const { api } = useAxios();

  // 층 이름
  const findFloorName = (floorId: string, pointId: string) => {
    if (floorStore.pointFloorsMap) {
      const findFloors: Floor[] | undefined = floorStore.pointFloorsMap.get(pointId);

      const findFloor: Floor | undefined = findFloors?.find(floor => floor.id === floorId);

      return findFloor?.name;
    }
  };

  // 층 이름
  const findFloorNameByBuildingId = (floorId: string, buildingId: string) => {
    if (floorStore.buildingFloorsMap) {
      const findFloors: Floor[] | undefined = floorStore.buildingFloorsMap.get(buildingId);

      const findFloor: Floor | undefined = findFloors?.find(floor => floor.id === floorId);

      return findFloor?.name;
    }
  };

  // 층 설명
  const findFloorDesc = (floorId: string, pointId: string): MultiLang | undefined => {
    if (pointStore.point && floorStore.pointFloorsMap) {
      const findFloors: Floor[] | undefined = floorStore.pointFloorsMap.get(pointId);

      if (findFloors) {
        const findFloor: Floor | undefined = findFloors.find(floor => floor.id === floorId);

        if (findFloor) {
          return findFloor.description;
        }
      }
    }
  };

  // floor fetch
  const getFloorData = async (mainLang: LangCode, pointId: string, pointsMap: Map<string, Point>) => {
    const response = await api.get<PointFloor[] | undefined>(EndPoint.floors);

    if (response) {
      const pointFloorMap = new Map<string, Floor[]>();
      const buildingFloorMap = new Map<string, Floor[]>();

      pointsMap?.forEach(point => {
        response.forEach(pointFloor => {
          pointFloor.floors.map(floor => {
            return {
              ...floor,
              name: convertMultiLang(floor.name, mainLang),
              description: convertMultiLang(floor.description, mainLang),
            };
          });

          // point별 floors
          pointFloorMap.set(pointFloor.pointId, pointFloor.floors);

          // building별 floors
          if (point.id === pointFloor.pointId) {
            buildingFloorMap.set(point.buildingId, pointFloor.floors);
          }
        });
      });

      floorStore.setPointFloorsMap(pointFloorMap);
      floorStore.setBuildingFloorsMap(buildingFloorMap);

      const findFloors: Floor[] | undefined = pointFloorMap.get(pointId);

      if (findFloors) {
        floorStore.setFloors(findFloors);
      }
    }
  };

  // point 의 default floor 찾는 함수
  const findDefaultFloor = (pointId: string): Floor | undefined => {
    if (pointStore.point && floorStore.pointFloorsMap) {
      const pointFloor: Floor[] | undefined = floorStore.pointFloorsMap.get(pointId);

      if (pointFloor) {
        const mainFloor: Floor | undefined = pointFloor.find(floor => floor.main);

        return mainFloor;
      }
    }
  };

  return { getFloorData, findFloorName, findFloorDesc, findDefaultFloor, findFloorNameByBuildingId };
};
export default useFloor;
