import DeleteAllSvg from 'component/common/icon/delete/all/DeleteAllSvg';
import DeleteOneSvg from 'component/common/icon/delete/one/DeleteOneSvg';
import { t } from 'i18next';
import { Dispatch, SetStateAction } from 'react';
import useColorSetStore from 'stores/colorSet';
import useSwiperStore from 'stores/swiper';
import KeywordItem from '../keywords/keyword/KeywordItem';
import styles from './InputField.module.scss';

type InputFieldProps = {
  searchWord: string;
  setSearchWord: Dispatch<SetStateAction<string>>;
  keyword: string;
  removeKeyword: () => void;
};

const InputField = ({ searchWord, setSearchWord, keyword, removeKeyword }: InputFieldProps) => {
  // store
  const swiperStore = useSwiperStore();
  const colorSetStore = useColorSetStore();

  /**
   * @desc 모든 검색어를 지운다.
   */
  const deleteAll = () => {
    setSearchWord('');
    swiperStore.moveTenantSwiperToFirstSlide();

    removeKeyword();
  };

  /**
   * @desc 마지막으로 입력한 검색어를 지운다.
   */
  const deleteOne = () => {
    setSearchWord(prev => prev.slice(0, -1));
    swiperStore.moveTenantSwiperToFirstSlide();

    removeKeyword();
  };

  return (
    <div className={styles.wrapper}>
      <div
        style={{
          border: searchWord ? `1px solid ${colorSetStore.input.active}` : `1px solid ${colorSetStore.input.inactive}`,
        }}
        className={styles.input_wrapper}
      >
        {keyword && <KeywordItem keyword={keyword} type='item' />}

        <input
          style={{
            background: colorSetStore.header.bg,
            color: searchWord ? colorSetStore.input.active : colorSetStore.input.inactive,
          }}
          className={`${styles.keypad_input}`}
          readOnly
          value={keyword ? '' : searchWord || `${t(`tenant.placeholder`)}`}
          type='text'
          // 동적으로 placeholder css 를 변경할 수 없어서 값으로 넣어줌
          // placeholder={t(`tenant.placeholder`)}
        />

        {(searchWord || keyword) && (
          <div onClick={deleteAll} className={styles.delete_all_btn}>
            <DeleteAllSvg color={colorSetStore.input.inactive} />
          </div>
        )}

        {/* 가로형 delete 버튼 */}
        <div
          onClick={deleteOne}
          className={styles.hori_delete_one_btn}
          style={{
            border: `1px solid ${colorSetStore.input.active}`,
          }}
        >
          <DeleteOneSvg color={colorSetStore.input.active} />
        </div>
      </div>

      {/* 세로형 delete 버튼 */}
      <div
        onClick={deleteOne}
        className={styles.delete_one_btn}
        style={{
          border: `1px solid ${colorSetStore.input.active}`,
        }}
      >
        <DeleteOneSvg color={colorSetStore.input.active} />
      </div>
    </div>
  );
};
export default InputField;
