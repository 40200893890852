import { Dispatch, SetStateAction } from 'react';
import useColorSetStore from 'stores/colorSet';
import { SearchTabType } from 'types/feature/tenantFilter.type';
import styles from './TenantFilter.module.scss';
import CategoryController from './controller/category/CategoryController';
import FloorController from './controller/floor/FloorController';
import KeypadController from './controller/keypad/KeypadController';
import SearchTabs from './tab/SearchTabs';

type Props = {
  currentFilter: SearchTabType;
  setCurrentFilter: Dispatch<SetStateAction<SearchTabType>>;
};

const TenantFilter = ({ currentFilter, setCurrentFilter }: Props) => {
  // store
  const colorSetStore = useColorSetStore();

  return (
    <div style={{ background: colorSetStore.header.bg }} className={styles.container}>
      {/* 포인트 선택, 필터 탭 */}
      <SearchTabs currentFilter={currentFilter} setCurrentFilter={setCurrentFilter} />

      {/* 초성 검색, 층별 검색, 카테고리 검색 */}
      {currentFilter === SearchTabType.tenant && <KeypadController />}
      {currentFilter === SearchTabType.floor && <FloorController />}
      {currentFilter === SearchTabType.category && <CategoryController currentFilter={currentFilter} />}
    </div>
  );
};
export default TenantFilter;
