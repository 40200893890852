import React from 'react';
import useColorSetStore from 'stores/colorSet';
import useLanguageStore from 'stores/languages';
import CustomFieldQr from 'component/common/qr/container/custom-field/CustomFieldQr';
import styles from './QrField.module.scss';

const QrField = ({ field }: any) => {
  const colorSetStore = useColorSetStore();
  const langStore = useLanguageStore();

  return (
    <div className={styles.detail_qr}>
      {/* 제목 */}
      <div style={{ color: colorSetStore.text.item }} className={styles.field_title}>
        {field.title[langStore.currentLang]}
      </div>
      {/* 내용 */}
      <div className={styles.field_content_qr}>
        <div className={styles.content}>
          <CustomFieldQr url={field.content?.[langStore.currentLang]} />
        </div>
      </div>
    </div>
  );
};

export default QrField;
