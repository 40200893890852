import React from 'react';
import './CustomPagination.scss';
import styles from './CustomPagination.module.scss';

const CustomPagination = () => {
  return (
    <div className={styles.pagination_wrapper}>
      <div className='swiper_pagination_common' />
    </div>
  );
};

export default CustomPagination;
