/* eslint-disable no-nested-ternary */
import { useTranslation } from 'react-i18next';
import useColorSetStore from 'stores/colorSet';
import useMachineStore from 'stores/machine';
import styles from './BusinessHour.module.scss';

const BusinessHour = () => {
  const machineStore = useMachineStore();
  const colorSetStore = useColorSetStore();

  const textColor = colorSetStore.footer.text;
  const borderColor = `${`1px solid ${colorSetStore.footer.text}`}`;

  const { t } = useTranslation();

  const businessHour = `${machineStore.machine?.point.hoursStart} ~ ${machineStore.machine?.point.hoursEnd}`;
  // 운영시간 사용
  return machineStore.machine?.point.hoursUsed ? (
    <div className={styles.container}>
      <div
        style={{
          border: borderColor,
          color: textColor,
        }}
        className={styles.box}
      >
        {t(`info.businessHours`)}
      </div>
      <div style={{ color: textColor }} className={styles.hour}>
        {businessHour || ''}
      </div>
    </div>
  ) : // 운영시간 대체 텍스트 있는 경우
  machineStore.machine?.point.hoursDescription ? (
    <div className={styles.container}>
      <div
        style={{
          border: borderColor,
          color: textColor,
        }}
        className={styles.box}
      >
        {t(`info.businessHours`)}
      </div>
      {machineStore.machine?.point.hoursDescription ? (
        <div style={{ color: textColor }} className={styles.hours_desc}>
          {machineStore.machine?.point.hoursDescription}
        </div>
      ) : (
        <div style={{ color: textColor }} className={styles.hour}>
          {businessHour || ''}
        </div>
      )}
    </div>
  ) : (
    <> </>
  );
};

export default BusinessHour;
