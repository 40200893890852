import ARROW_DOWN from 'assets/main/arrow/arrow-down-gy.svg';
import ARROW_UP from 'assets/main/arrow/arrow-up-gy.svg';
import ICON_INDOOR from 'assets/point/indoor.svg';
import ICON_OUTDOOR from 'assets/point/outdoor.svg';
import { addBuilding } from 'map/control/building';
import { goToMyLocation } from 'map/control/myLocation';
import { useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import useCategoryGroupStore from 'stores/categoryGroup';
import useColorSetStore from 'stores/colorSet';
import useFacilityStore from 'stores/facility';
import useFloorStore from 'stores/floor';
import useLanguageStore from 'stores/languages';
import useLoadingStore from 'stores/loading';
import usePointStore from 'stores/point';
import useQrStore from 'stores/qr';
import { CategoryGroupWithTenants } from 'types/common/category.type';
import { Floor } from 'types/common/floor.type';
import { Point } from 'types/common/point.type';
import { changeMapPointSetting } from 'utils/map/changeMapSetting';
import styles from './FloorPointSelect.module.scss';

// 층별안내 포인트 선택
const FloorPointSelect = () => {
  const pointStore = usePointStore();
  const floorStore = useFloorStore();
  const categoryStore = useCategoryGroupStore();
  const langStore = useLanguageStore();
  const themeStore = useColorSetStore();
  const qrStore = useQrStore();
  const loadingStore = useLoadingStore();
  const facilityStore = useFacilityStore();

  const [openOptions, setOpenOptions] = useState(false);

  // select on/off
  const handleOpenOptions = () => {
    setOpenOptions(prev => !prev);
  };

  /**
   * 층 리스트와 현재 층 전역 상태값 변경 및 빌딩의 main 층 반환.
   * @param pointId 포인트 아이디로 층 검색
   * @returns point 의 main 층 반환
   */
  const changeFloorRelatedStatesAndFindMainFloor = (pointId: string): string | undefined => {
    if (floorStore.pointFloorsMap) {
      const findFloors: Floor[] | undefined = floorStore.pointFloorsMap.get(pointId);

      if (findFloors) {
        // 층 목록
        floorStore.setFloors(findFloors);

        const findMainFloor = findFloors.find(floor => floor.main);

        if (findMainFloor) {
          // 층
          floorStore.setCurrentFloorId(findMainFloor.id);

          // 층 이름
          floorStore.setFloorName(findMainFloor.name);

          return findMainFloor.id;
        }
      }
    }
  };

  // point 의 category 로 세팅
  const setPointCategoryGroups = (pointId: string) => {
    if (categoryStore.mapFacilityCategoriesMap) {
      const findCategories: CategoryGroupWithTenants | undefined = categoryStore.mapFacilityCategoriesMap.get(pointId);

      if (findCategories) {
        categoryStore.setCategoryList('MAP_FACILITY', findCategories);
      }
    }
  };

  /**
   * point select 에서 point 클릭 시 일어나는 일
   *
   * 1. selectedPoint 전역 상태 변경
   * 2. 길찾기 qr 제거
   * 3. 다른 포인트로 이동 (빌딩 추가, 빌딩의 메인 층으로 층 이동)
   * 4. 층별안내 category 목록을 변경
   * 5. 카메라 설정 및 센터값 변경 (changeMapPointSetting)
   * 6. 기기 층일 경우만 GPS 노출
   *
   * @param point 클릭한 포인트 정보
   */
  const onChangePoint = async (point: Point) => {
    loadingStore.setFloorChanging(true);

    setOpenOptions(false);

    pointStore.setSelectedPoint(point);
    facilityStore.resetCurrentFacilityId();
    qrStore.setShowNaviQr(false);

    const pointMainFloor: string | undefined = changeFloorRelatedStatesAndFindMainFloor(point.id);

    if (pointMainFloor) {
      setPointCategoryGroups(point.id);

      // 빌딩 변경
      await addBuilding(point.buildingId, pointMainFloor);

      // 카메라 설정 및 센터값 변경
      changeMapPointSetting(point);

      // 내위치 마커
      await goToMyLocation();
    }

    loadingStore.setFloorChanging(false);
  };

  return (
    <div className={styles.container}>
      <OutsideClickHandler onOutsideClick={() => setOpenOptions(false)}>
        <div className={styles.select_box_wrapper}>
          {openOptions && (
            <div className={styles.options}>
              {pointStore.points
                .filter(point => point.id !== pointStore.selectedPoint?.id)
                .map(point => (
                  <div key={point.id} onClick={onChangePoint.bind(this, point)} className={styles.option}>
                    <div className={styles.point_icon}>
                      <img src={point.buildingType === 'OUTDOOR' ? ICON_OUTDOOR : ICON_INDOOR} alt='icon' />
                    </div>
                    <div className={styles.point_name}>{point.name[langStore.currentLang]}</div>
                  </div>
                ))}
            </div>
          )}

          <div style={{ backgroundColor: themeStore.sub }} onClick={handleOpenOptions} className={styles.select_box}>
            <div className={styles.current_select}>
              <div className={styles.current_select_icon}>
                <img src={pointStore.selectedPoint?.buildingType === 'OUTDOOR' ? ICON_OUTDOOR : ICON_INDOOR} alt='' />
              </div>
              <div className={styles.current_select_name}>
                {pointStore.selectedPoint?.name[langStore.currentLang] || ''}
              </div>
            </div>
            <div className={styles.arrow}>
              <img src={openOptions ? ARROW_UP : ARROW_DOWN} alt='arrow' />
            </div>
          </div>
        </div>
      </OutsideClickHandler>
    </div>
  );
};
export default FloorPointSelect;
