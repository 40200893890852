/* eslint-disable react/destructuring-assignment */
import { ModalType } from 'component/common/modal/container/ModalContainer';
import useTenantStore from 'stores/tenant';
import styles from './TenantPopup.module.scss';
import MultiTenants from './list/MultiTenants';
import DefaultTenantPopup from './popup/default/DefaultTenantPopup';
import SimpleTenantPopup from './popup/simple/SimpleTenantPopup';

type Props = {
  openModal: (type: ModalType) => void;
  closeModal: () => void;
  isFloorPage: boolean;
};

const TenantPopup = (props: Props) => {
  const tenantStore = useTenantStore();

  if (!tenantStore.currentTenant) {
    return <> </>;
  }

  const isMultiTenants = tenantStore.currentTenantArr.length > 0;
  const isSimpleTenant = tenantStore.currentTenant.popupType === 'SMALL';

  return (
    <div
      className={`${styles.tenant_popup} ${isMultiTenants && styles.multi} ${
        isSimpleTenant ? styles.simple : styles.default
      }`}
    >
      {/* 다중 contents */}
      {isMultiTenants && <MultiTenants closeModal={props.closeModal} tenantList={tenantStore.currentTenantArr} />}

      <div className={styles.popup_body}>
        {/* 기본형 혹은 축약형 팝업 */}
        {tenantStore.currentTenant.popupType === 'SMALL' ? (
          <SimpleTenantPopup {...props} />
        ) : (
          <DefaultTenantPopup {...props} />
        )}
      </div>
    </div>
  );
};
export default TenantPopup;
