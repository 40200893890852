import { useMemo } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { PoiContentKeyword } from 'types/common/poiContentKeyword.type';
import { sortKeywordsBySearchWord } from 'utils/sort/keyword/sortKeywordsBySearchWord';
import styles from './KeywordsContainer.module.scss';
import KeywordItem from './keyword/KeywordItem';

type Props = {
  isOpen: boolean;
  onClickKeyWord: (keyword: PoiContentKeyword) => Promise<void>;
  keywords: PoiContentKeyword[];
  searchWord: string;
  closeKeywordList: () => void;
};

const KeywordsContainer = ({ isOpen, onClickKeyWord, keywords, searchWord, closeKeywordList }: Props) => {
  const sortedKeywords = useMemo(() => {
    return sortKeywordsBySearchWord(keywords, searchWord);
  }, [keywords, searchWord]);

  return (
    <OutsideClickHandler onOutsideClick={closeKeywordList} display='contents'>
      <div className={`${styles.wrapper} ${isOpen && styles.open}`}>
        <div className={styles.words_wrapper}>
          {sortedKeywords.map((keyword, id) => (
            <div key={id} onClick={onClickKeyWord.bind(this, keyword)}>
              <KeywordItem keyword={keyword.title} type='list' />
            </div>
          ))}
        </div>
      </div>
    </OutsideClickHandler>
  );
};
export default KeywordsContainer;
