/* eslint-disable no-undef */
import axios, { AxiosError, AxiosInstance } from 'axios';

const baseURL = process.env.REACT_APP_BASE_URL;

interface CustomInstance extends AxiosInstance {
  get<T>(...params: Parameters<AxiosInstance['get']>): Promise<T>;
  post<T>(...params: Parameters<AxiosInstance['post']>): Promise<T>;
}

// token 필요없는 로그인용 (웹 키오스크 로그인)
const loginApi: CustomInstance = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
    // TODO: 제거 및 nologinaxios 랑 합치기
    Authorization: localStorage.getItem('jwt') || 'token',
  },
});

// 요청
loginApi.interceptors.request.use(
  config =>
    // 요청 전 토큰 설정이든 필요한 설정
    // config.headers.Authorization = '토큰';
    config,
  error => Promise.reject(error),
);

// 응답
loginApi.interceptors.response.use(
  response => response.data.data,

  (error: AxiosError<any>) => {
    throw error;
  },
);

export default loginApi;
