/* eslint-disable no-restricted-syntax */
import React, { useEffect, useMemo, useState } from 'react';
import useLanguageStore from 'stores/languages';
import usePoiContentKeywordStore from 'stores/poiContentKeyword';
import useScreenTypeStore from 'stores/screenType';
import useSwiperStore from 'stores/swiper';
import useTenantStore from 'stores/tenant';
import { ScreenType } from 'types/common/common.type';
import { LangCode } from 'types/common/language.type';
import { PoiContentKeyword } from 'types/common/poiContentKeyword.type';
import { KeypadType } from 'types/feature/tenantFilter.type';
import styles from './KeypadController.module.scss';
import InputField from './input-field/InputField';
import Keypad from './keypad/Keypad';
import KeywordsContainer from './keywords/KeywordsContainer';
import KeypadTabs from './tabs/KeypadTabs';

const KeypadController = () => {
  // state
  const [searchWord, setSearchWord] = useState('');
  const [keypadType, setKeypadType] = useState<KeypadType>(KeypadType.chosung);
  const [isOpenKeywordList, setOpenKeywordList] = useState<boolean>(false);
  const [selectedKeyword, setSelectedKeyword] = useState<PoiContentKeyword | null>(null);

  // store
  const tenantStore = useTenantStore();
  const swiperStore = useSwiperStore();
  const langStore = useLanguageStore();
  const screenTypeStore = useScreenTypeStore();
  const keywordStore = usePoiContentKeywordStore();

  /**
   * useMemo
   * @desc 검색어를 포함한 keyword 만 필터링한다.
   * @desc 키워드 목록과 검색어가 변경될 때만 리랜더링한다.
   */
  const filteredKeywordList = useMemo(() => {
    return keywordStore.poiContentKeywords.filter(word => word.initialSearchKeyword.includes(searchWord.toUpperCase()));
  }, [keywordStore.poiContentKeywords, searchWord]);

  /**
   * @desc 검색어를 입력한다.
   * @param {React.MouseEvent<HTMLDivElement>} e
   */
  const onClickSearchWord = (e: React.MouseEvent<HTMLDivElement>) => {
    setSearchWord(prev => prev + (e.target as HTMLDivElement).id);
    swiperStore.moveTenantSwiperToFirstSlide();
    setOpenKeywordList(true);

    // 선택된 키워드가 있다면 제거한다.
    if (selectedKeyword) {
      setSelectedKeyword(null);
    }
  };

  /**
   * @desc 검색어를 지운다.
   */
  const removeKeyword = () => {
    setSelectedKeyword(null);
    setSelectedKeyword(null);
    setOpenKeywordList(false);
  };

  /**
   * @desc 기본 키패트 타입을 설정한다.
   */
  const initKeypadType = () => {
    if (langStore.currentLang === LangCode.ko) {
      setKeypadType(KeypadType.chosung);
      return;
    }

    setKeypadType(KeypadType.alphabet);
  };

  /**
   * @desc 키워드 클릭 시 해당 키워드가 포함된 매장만 검색 결과에 오름차순 노출한다.
   */
  const onClickKeyword = async (keyword: PoiContentKeyword) => {
    setSelectedKeyword(keyword);
    setOpenKeywordList(false);

    setSearchWord('');
  };

  /**
   * useEffect
   * @desc 검색어가 변경될 때마다 tenant 목록이 변경된다.
   */
  useEffect(() => {
    if (!tenantStore.searchTenants) return;

    tenantStore.setFilteredTenants(
      tenantStore.searchTenants,
      langStore.currentLang,
      searchWord,
      selectedKeyword?.title,
    );
  }, [searchWord, selectedKeyword]);

  /**
   * useEffect
   * @desc 현재 언어를 변경할 때마다 수행한다.
   */
  useEffect(() => {
    swiperStore.moveTenantSwiperToFirstSlide();
    setSearchWord('');
    initKeypadType();
  }, [langStore.currentLang]);

  const closeKeywordList = () => {
    setOpenKeywordList(false);
  };

  return (
    <div className={styles.keypad_controller}>
      {screenTypeStore.screenType === ScreenType.hori ? (
        // 가로형
        <>
          <div className={styles.hori_wrapper}>
            {/* 키워드 검색 */}
            {isOpenKeywordList && filteredKeywordList.length > 0 && (
              <KeywordsContainer
                isOpen={isOpenKeywordList}
                onClickKeyWord={onClickKeyword}
                keywords={filteredKeywordList}
                searchWord={searchWord}
                closeKeywordList={closeKeywordList}
              />
            )}

            {/* 한/영/숫자 */}
            <KeypadTabs keypadType={keypadType} setKeypadType={setKeypadType} />

            {/* 검색어 입력창 */}
            <InputField
              searchWord={searchWord}
              setSearchWord={setSearchWord}
              keyword={selectedKeyword?.title || ''}
              removeKeyword={removeKeyword}
            />
          </div>

          {/* 키패드 */}
          <Keypad keypadType={keypadType} onClickWord={onClickSearchWord} />
        </>
      ) : (
        // 세로형, 정방형
        <>
          {/* 한/영/숫자 */}
          <KeypadTabs keypadType={keypadType} setKeypadType={setKeypadType} />

          <div className={styles.verti_wrapper}>
            {/* 키워드 검색 */}
            {isOpenKeywordList && filteredKeywordList.length > 0 && (
              <KeywordsContainer
                isOpen={isOpenKeywordList}
                onClickKeyWord={onClickKeyword}
                keywords={filteredKeywordList}
                searchWord={searchWord}
                closeKeywordList={closeKeywordList}
              />
            )}

            {/* 검색어 입력창 */}
            <InputField
              searchWord={searchWord}
              setSearchWord={setSearchWord}
              keyword={selectedKeyword?.title || ''}
              removeKeyword={removeKeyword}
            />

            {/* 키패드 */}
            <Keypad keypadType={keypadType} onClickWord={onClickSearchWord} />
          </div>
        </>
      )}
    </div>
  );
};

export default KeypadController;
