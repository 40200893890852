import { useState } from 'react';
import { SearchTabType } from 'types/feature/tenantFilter.type';
import TenantFilter from './filter/TenantFilter';
import TenantList from './tenant-list/TenantList';

const TenantContainer = () => {
  const [currentFilter, setCurrentFilter] = useState(SearchTabType.tenant);

  return (
    <>
      <TenantList currentFilter={currentFilter} />
      <TenantFilter currentFilter={currentFilter} setCurrentFilter={setCurrentFilter} />
    </>
  );
};
export default TenantContainer;
