/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ICONS } from 'constants/icons';
import { FLOOR_SLIDE_ITEM_MAX_COUNT } from 'data/common';
import { useEffect, useState } from 'react';
import Slider, { Settings } from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import useFloorStore from 'stores/floor';
import { Floor, OrderedFloor } from 'types/common/floor.type';
import FloorButton from '../floor/FloorButton';
import styles from './FloorSlider.module.scss';

type Props = {
  floors: OrderedFloor[];
  slide: Slider | null;
  setSlide: (slider: Slider | null) => void;
  onClickFloor: (floor: Floor) => void;
};

const FloorSlider = (props: Props) => {
  // store
  const floorStore = useFloorStore();

  // state
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [activeTopIndex, setActiveTopIndex] = useState<number>(0);
  const [hasUpSlide, setHasUpSlide] = useState<boolean>(true);
  const [hasDownSlide, setHasDownSlide] = useState<boolean>(true);

  /**
   * 내가 0보다 크다면 무조건 위가 있다
   */
  const handleHasUp = (topIndex: number) => {
    if (topIndex <= 0) {
      setHasUpSlide(false);
      return;
    }

    setHasUpSlide(true);
  };

  const handleHasDown = (nextIndex: number) => {
    const slideLastIndex = nextIndex + FLOOR_SLIDE_ITEM_MAX_COUNT - 1;

    if (slideLastIndex < props.floors.length - 1) {
      setHasDownSlide(true);
      return;
    }

    setHasDownSlide(false);
  };

  /**
   * 이전 슬라이드로 이동한다
   */
  const onClickUp = () => {
    if (props.slide) {
      props.slide.slickPrev();
    }
  };

  /**
   * 다음 슬라이드로 이동한다
   */
  const onClickDown = () => {
    if (props.slide) {
      props.slide.slickNext();
    }
  };

  /**
   * 처음 진입 시 기기 층으로 포커스해준다
   */
  useEffect(() => {
    const findFloor = props.floors.find(floor => floor.id === floorStore.currentFloorId);

    if (findFloor) {
      setActiveIndex(findFloor.order);
    }
  }, [props.floors, floorStore.currentFloorId]);

  /**
   * 처음 진입 시 UP, DOWN 화살표 사용 여부를 설정한다
   */
  useEffect(() => {
    handleHasUp(activeTopIndex);
    handleHasDown(activeTopIndex);
  }, [activeTopIndex]);

  /**
   * 처음 그릴 때 선택된 층으로 이동한다
   */
  useEffect(() => {
    props.slide?.slickGoTo(activeIndex, true);
  }, [activeIndex]);

  /**
   * 슬라이더를 그리는 데 필요한 설정값
   */
  const settings: Settings = {
    arrows: false,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: FLOOR_SLIDE_ITEM_MAX_COUNT,
    slidesToScroll: FLOOR_SLIDE_ITEM_MAX_COUNT,
    vertical: true,

    beforeChange(currentTopIndex: number, nextTopIndex: number) {
      setActiveTopIndex(nextTopIndex);
    },
  };

  return (
    <div className={styles.wrapper}>
      {/* UP */}
      <button
        onClick={onClickUp}
        disabled={!hasUpSlide}
        className={`${styles.arrow} ${!hasUpSlide && styles.disabled}`}
        type='button'
      >
        <img src={!hasUpSlide ? ICONS.UP_DISABLE : ICONS.UP} alt='up' />
      </button>

      {/* 슬라이더 */}
      <div className={styles.slider}>
        <Slider ref={slider => props.setSlide(slider)} {...settings}>
          {props.floors.map((floor: Floor) => (
            <FloorButton
              key={floor.id}
              floor={floor}
              overFiveFloor
              activeFloor={floorStore.currentFloorId === floor.id}
              onClickFloor={props.onClickFloor}
            />
          ))}
        </Slider>
      </div>

      {/* DOWN */}
      <button
        className={`${styles.arrow} ${!hasDownSlide && styles.disabled}`}
        disabled={!hasDownSlide}
        type='button'
        onClick={onClickDown}
      >
        <img src={!hasDownSlide ? ICONS.DOWN_DISABLE : ICONS.DOWN} alt='down' />
      </button>
    </div>
  );
};

export default FloorSlider;
