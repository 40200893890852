import { DESKTOP_ENVIRONMENT } from 'constants/environment';
import { EndPoint } from 'data/endPoints';
import { TokenType } from 'data/token';
import useAxios from 'utils/axios/fetchAxios';

// DESKTOP 기기일 경우 token 발행 후 localStorage 에 token 저장
const useToken = () => {
  const { api } = useAxios();

  const getToken = async () => {
    const tokenUrl = DESKTOP_ENVIRONMENT ? EndPoint.deskAuth : EndPoint.auth;

    const result = await api.post<{ token: string; type: string }>(tokenUrl, {
      machineId:
        process.env.REACT_APP_ENVIRONMENT === 'local' ? 'F1E3118C89B2471FBB3A3C6838F64036' : localStorage.getItem('id'),
      machineKey:
        process.env.REACT_APP_ENVIRONMENT === 'local'
          ? '12fc6596afa64ac7b7defba92fa69fe57053fa472d4'
          : localStorage.getItem('key'),
    });

    // desktop에서는 윈도우 어플리케이션에서 cookie에 담긴 토큰 값을 못 읽어와서, localStorage에 저장 되어 있음.
    if (result) {
      localStorage.setItem(TokenType.DESKTOP, result.token);

      return result;
    }
  };

  return {
    getToken,
  };
};

export default useToken;
