import { ALPHABET, CHOSUNG, NUMBER } from 'data/keypad';
import React from 'react';
import useColorSetStore from 'stores/colorSet';
import { KeypadType } from 'types/feature/tenantFilter.type';
import styles from './Keypad.module.scss';

type KeypadProps = {
  keypadType: KeypadType;
  onClickWord: (e: React.MouseEvent<HTMLDivElement>) => void;
};

const Keypad = ({ keypadType, onClickWord }: KeypadProps) => {
  // store
  const colorSetStore = useColorSetStore();

  return (
    <div className={`${styles.keypad}`}>
      {/* 초성 */}
      {keypadType === KeypadType.chosung && (
        <div className={styles.chosung_wrapper}>
          {CHOSUNG.map(chosung => (
            <div
              className={styles.keypad_item}
              style={{ color: colorSetStore.button.text }}
              onClick={onClickWord}
              id={chosung}
              key={chosung}
            >
              {chosung}
            </div>
          ))}
        </div>
      )}

      {/* 알파벳 */}
      {keypadType === KeypadType.alphabet && (
        <div className={styles.alphabet_wrapper}>
          {ALPHABET.map(alphabet => (
            <div
              className={styles.keypad_item}
              style={{ color: colorSetStore.button.text }}
              onClick={onClickWord}
              id={alphabet}
              key={alphabet}
            >
              {alphabet}
            </div>
          ))}
        </div>
      )}

      {/* 숫자 */}
      {keypadType === KeypadType.number &&
        NUMBER.map(number => (
          <div
            className={styles.keypad_item}
            style={{ color: colorSetStore.button.text }}
            onClick={onClickWord}
            id={number}
            key={number}
          >
            {number}
          </div>
        ))}
    </div>
  );
};
export default Keypad;
