import { EndPoint } from 'data/endPoints';
import useFloor from 'hooks/floor/useFloor';
import useBuildingStore from 'stores/building';
import usePointStore from 'stores/point';
import { LangCode } from 'types/common/language.type';
import { Point } from 'types/common/point.type';
import useAxios from 'utils/axios/fetchAxios';
import { convertMultiLang } from 'utils/multi-lang/convertMultiLang';

const usePoint = () => {
  const { api } = useAxios();
  const { getFloorData } = useFloor();

  const pointStore = usePointStore();
  const buildingStore = useBuildingStore();

  // point 이름
  const findPointName = (tenantPointId: string) => {
    if (pointStore.pointsMap) {
      const currentPoint: Point | undefined = pointStore.pointsMap.get(tenantPointId);

      if (currentPoint) {
        return currentPoint.name;
      }
    }
  };

  // point 이름
  const setPointNameByBuildingId = (buildingId: string) => {
    if (pointStore.buildingPointsMap) {
      const currentPoint: Point | undefined = pointStore.buildingPointsMap.get(buildingId);

      if (currentPoint) {
        buildingStore.setBuildingName(currentPoint.name);
        return currentPoint.name;
      }
    }
  };

  // points fetch
  const getPoints = async (mainLang: LangCode, pointId: string) => {
    const response = await api.get<Point[] | undefined>(EndPoint.pointsV2);

    // 포인트 한 개일 경우 pointsMap 생성하고 싶지 않다면 이 곳에 조건 추가
    if (response) {
      pointStore.setPoints(response);

      const pointsMap = new Map<string, Point>();
      const buildingPointsMap = new Map<string, Point>();

      response.forEach(point => {
        pointsMap.set(point.id, {
          ...point,
          name: convertMultiLang(point.name, mainLang),
        });

        buildingPointsMap.set(point.buildingId, {
          ...point,
          name: convertMultiLang(point.name, mainLang),
        });
      });

      pointStore.setPointsMap(pointsMap);
      pointStore.setBuildingPointsMap(buildingPointsMap);

      await getFloorData(mainLang, pointId, pointsMap);
    }
  };

  return {
    getPoints,
    findPointName,
    setPointNameByBuildingId,
  };
};
export default usePoint;
