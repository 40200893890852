import { useEffect } from 'react';
import styles from './SaveAccount.module.scss';

type SaveAccountProps = {
  isRemember: boolean;
  isRememberUsernameInCookie: string;
  setIsRemember: (value: boolean) => void;
};

const SaveAccount = ({ isRemember, isRememberUsernameInCookie, setIsRemember }: SaveAccountProps) => {
  useEffect(() => {
    // 쿠키에 아이디 저장되어 있을 때
    if (isRememberUsernameInCookie !== '') {
      setIsRemember(true);
    }
  }, []);

  return (
    <div className={styles.container}>
      <label htmlFor='check' className={styles.label} />
      <input
        id='check'
        checked={isRemember}
        type='checkbox'
        className={`${styles.checkbox}`}
        onChange={() => setIsRemember(!isRemember)}
      />

      <div className={`${styles.text} ${isRemember && styles.saveAccount}`}>아이디 저장</div>
    </div>
  );
};

export default SaveAccount;
