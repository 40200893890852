import { errorConsole } from 'component/common/console/console';

/**
 * 주어진 값이 비어 있는지 여부 확인
 * @param {string | undefined} value 확인할 값
 * @returns {boolean} 값이 비어 있으면 true, 아니면 false를 반환
 */
export const isEmpty = (value: string | undefined): boolean => value === undefined && value === null && value === '';

/**
 * 지정된 uri 에서 svg 데이터를 가져와 필요에 따라 svg의 fill 및 stroke 색상을 변경
 * @param {string} uri svg 데이터를 가져올 URI입니다.
 * @param {string | undefined} mainIconColor svg의 채우기 및 윤곽선 속성에 적용할 색상, 정의되지 않으면 색상 변경이 적용되지 않는다.
 * @returns {Promise<string>} svg 데이터가 포함된 데이터 URI를 해결하는 promise, 색상이 변경된 경우 수정된 svg 데이터가 포함
 * @throws {Error} svg 데이터 가져오기 또는 처리 중 오류가 발생한 경우 발생
 */
export const fetchAndColorizeSvgData = async (uri: string, mainIconColor: string | undefined): Promise<string> => {
  try {
    const response = await fetch(uri);
    const data = await response.text();

    if (mainIconColor) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(data, 'image/svg+xml');
      const checkElement = (element: Element) => {
        if (element.getAttribute('fill')) {
          element.setAttribute('fill', mainIconColor);
        }
        if (element.getAttribute('stroke')) {
          element.setAttribute('stroke', mainIconColor);
        }
        element.querySelectorAll('*').forEach(checkElement);
      };
      doc.querySelectorAll('*:not(svg)').forEach(checkElement);
      const svgAsXML = new XMLSerializer().serializeToString(doc.documentElement);
      return `data:image/svg+xml;base64,${btoa(svgAsXML)}`;
    }
    return uri;
  } catch (error) {
    errorConsole('svg error', error);
    throw error; // Re-throw the error for handling by the caller
  }
};
