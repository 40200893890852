import { disassembleHangul } from '@toss/hangul';
import _ from 'lodash';
import { TenantWithPointId } from 'types/common/tenant.type';
import { extractConsonants } from 'utils/hangul/extractConsonants';
import { sortKoEnFirst } from 'utils/sort/ko-en-first/sortKoEnFirst';
import { create } from 'zustand';
import { LangCode } from '../types/common/language.type';

type TenantStoreType = {
  // 모든 point 들의 tenants list
  // TODO: rawTenants -> converted tenants ? all tenants ? 으로 naming 변경
  rawTenants: TenantWithPointId[];
  setRawTenants: (rawTenants: TenantWithPointId[]) => void;

  /**
   * 매장검색용 전체 tenants
   *
   * tenant 를 지도에서는 노출하지만
   * tenant 목록에서는 노출하고 싶지 않을 경우를 위해서
   * 매장 검색 페이지에서는 raw tenants 가 아닌 매장 검색용 tenants를 사용해야 한다.
   * (매장 비노출 처리를 하게되면 지도에서도 tenant 팝업을 사용할 수 없다.)
   */
  searchTenants: TenantWithPointId[];
  setSearchTenants: (tenants: TenantWithPointId[]) => void;

  // 층별안내용 전체 tenants
  mapFacilityTenants: TenantWithPointId[];
  setMapFacilityTenants: (tenants: TenantWithPointId[]) => void;

  // 모든 point 들의 tenants map
  pointTenantsMap: Map<string, TenantWithPointId[]> | null; // pointId, Tenant[]
  setPointTenantsMap: (pointTenantsMap: Map<string, TenantWithPointId[]>) => void;

  // poiId 별 tenants map (다중 poi 에서 사용)
  poiTenantsMap: Map<string, TenantWithPointId[]> | null;
  setPoiTenantsMap: (poiTenantsMap: Map<string, TenantWithPointId[]>) => void;

  // point 의 tenants list
  pointTenants: TenantWithPointId[];
  setPointTenants: (originTenants: TenantWithPointId[], lang: LangCode) => void;

  // 팝업에 보여줄 tenant
  currentTenant: TenantWithPointId | null;
  setCurrentTenant: (tenants: TenantWithPointId) => void;
  resetCurrentTenant: () => void;

  // 팝업에 보여줄 다중 contents list
  currentTenantArr: TenantWithPointId[];
  setCurrentTenantArr: (tenants: TenantWithPointId[]) => void;

  // 검색된 tenants 목록
  filteredTenants: TenantWithPointId[];
  setFilteredTenants: (tenants: TenantWithPointId[], lang: LangCode, searchWord?: string, keyword?: string) => void;
};

const useTenantStore = create<TenantStoreType>(set => ({
  rawTenants: [],
  setRawTenants(rawTenants: TenantWithPointId[]) {
    set(() => ({
      rawTenants,
    }));
  },

  searchTenants: [],
  setSearchTenants(tenants: TenantWithPointId[]) {
    set(() => ({ searchTenants: _.cloneDeep(tenants) }));
  },

  mapFacilityTenants: [],
  setMapFacilityTenants(tenants: TenantWithPointId[]) {
    set(() => ({ mapFacilityTenants: _.cloneDeep(tenants) }));
  },

  pointTenantsMap: null,
  setPointTenantsMap(pointTenantsMap: Map<string, TenantWithPointId[]>) {
    set(() => ({
      pointTenantsMap,
    }));
  },

  poiTenantsMap: null,
  setPoiTenantsMap(poiTenantsMap: Map<string, TenantWithPointId[]>) {
    set(() => ({
      poiTenantsMap,
    }));
  },

  pointTenants: [],
  setPointTenants(pointTenants: TenantWithPointId[], lang: LangCode) {
    const onlyUsedTenants = pointTenants.filter(tenant => tenant.used);

    const sortedTenants = onlyUsedTenants.sort((a: TenantWithPointId, b: TenantWithPointId) =>
      sortKoEnFirst(a.name[lang] ?? '', b.name[lang] ?? ''),
    );

    set(() => ({
      pointTenants: _.cloneDeep(sortedTenants),
    }));
  },

  currentTenant: null,
  setCurrentTenant(tenant: TenantWithPointId) {
    set(() => ({
      currentTenant: tenant,
    }));
  },

  currentTenantArr: [],
  setCurrentTenantArr(tenants: TenantWithPointId[]) {
    set(() => ({
      currentTenantArr: tenants,
    }));
  },
  resetCurrentTenant() {
    set(() => ({ currentTenant: null, currentTenantArr: [] }));
  },

  filteredTenants: [],

  /**
   * @desc 검색어를 입력하면 테넌트 목록이 변경된다.
   * @param {TenantWithPointId[]} tenants
   * @param {LangCode} lang
   * @param {string} searchWord
   * @param {string} keyword
   */
  setFilteredTenants(tenants: TenantWithPointId[], lang: LangCode, searchWord?: string, keyword?: string) {
    /**
     * @desc 키워드가 있을 경우, 테넌트의 키워드 목록에서 해당 키워드를 포함하는 테넌트만 필터링한다.
     */
    if (keyword) {
      const keywordIncludedTenants = tenants.filter(tenant => tenant.keywords.includes(keyword));
      return set(() => ({ filteredTenants: _.cloneDeep(keywordIncludedTenants) }));
    }

    /**
     * @desc 검색어가 없을 경우, 테넌트를 오름차순 정렬한다.
     */
    if (!searchWord) {
      const sortAscendingOrderTenants = tenants.sort((a: TenantWithPointId, b: TenantWithPointId) =>
        sortKoEnFirst(a.name[lang].toUpperCase() ?? '', b.name[lang].toUpperCase() ?? ''),
      );

      return set(() => ({ filteredTenants: _.cloneDeep(sortAscendingOrderTenants) }));
    }

    /**
     * @desc 검색어가 있을 경우 검색어의 언어에 맞게 테넌트를 정렬한다.
     */
    const includeSearchWordTenants = tenants.filter(tenant =>
      tenant.searchKeyword.toUpperCase().includes(searchWord.toUpperCase()),
    );

    // 한국어
    if (lang === LangCode.ko) {
      includeSearchWordTenants.sort((a: TenantWithPointId, b: TenantWithPointId): number => {
        // 이름을 해체해서 자음만 모은 다음에 검색어로 시작하는지 확인
        const startsWithChosungA = extractConsonants(disassembleHangul(a.name[lang] ?? '')).startsWith(searchWord);
        const startsWithChosungB = extractConsonants(disassembleHangul(b.name[lang] ?? '')).startsWith(searchWord);

        // 검색어가 포함된 테넌트들을 오름차순 정렬
        if (startsWithChosungA && startsWithChosungB) {
          return sortKoEnFirst(a.name[lang].toUpperCase(), b.name[lang].toUpperCase());
        }

        if (startsWithChosungA) return -1;
        if (startsWithChosungB) return 1;

        // 예외
        return sortKoEnFirst(a.name[lang].toUpperCase(), b.name[lang].toUpperCase());
      });

      set(() => ({ filteredTenants: _.cloneDeep(includeSearchWordTenants) }));
      return;
    }

    // 한국어 아닌 나머지
    includeSearchWordTenants.sort((a: TenantWithPointId, b: TenantWithPointId): number => {
      const startsWithSearchWordA = a.name[lang].toUpperCase().startsWith(searchWord.toUpperCase());
      const startsWithSearchWordB = b.name[lang].toUpperCase().startsWith(searchWord.toUpperCase());

      // 검색어가 포함된 테넌트들을 오름차순 정렬
      if (startsWithSearchWordA && startsWithSearchWordB) {
        return sortKoEnFirst(a.name[lang].toUpperCase(), b.name[lang].toUpperCase());
      }

      if (startsWithSearchWordA) return -1;
      if (startsWithSearchWordB) return 1;

      return sortKoEnFirst(a.name[lang].toUpperCase(), b.name[lang].toUpperCase());
    });

    set(() => ({ filteredTenants: _.cloneDeep(includeSearchWordTenants) }));
  },
}));

export default useTenantStore;
