import { create } from 'zustand';

type TransportStoreProps = {
  transport: string;
  transports: string[];
  openTransportPopup: boolean;

  setCurrentTransport: (transport: string) => void;
  setTransports: (transports: string[]) => void;
  setOpenTransportPopup: (openTransportPopup: boolean) => void;

  isLoadingTransport: boolean;
  setLoadingTransport: (isLoadingTransport: boolean) => void;
};

const useTransportStore = create<TransportStoreProps>((set, get) => ({
  transport: 'recommendation',
  transports: [],
  setCurrentTransport(transport) {
    set(() => ({ transport: transport.toLowerCase() }));
  },
  setTransports(transports) {
    const result = transports.filter(item => item !== 'recommendation');
    set(() => ({ transports: result }));

    // 추천경로 포함
    // set(() => ({ transports }));
  },

  openTransportPopup: false,
  setOpenTransportPopup(openTransportPopup: boolean) {
    set(() => ({ openTransportPopup }));
  },

  isLoadingTransport: false,
  setLoadingTransport(isLoadingTransport: boolean) {
    set(() => ({ isLoadingTransport }));
  },
}));
export default useTransportStore;
