import usePagination from 'hooks/pagination/usePagination';
import { useEffect } from 'react';
import useSwiperStore from 'stores/swiper';
import { Navigation, Pagination, Virtual } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/virtual';
import { Swiper } from 'swiper/react';
import './CardSwiper.scss';

export type SwiperProps = {
  children: React.JSX.Element;
};

const CardSwiper = ({ children }: SwiperProps) => {
  const swiperStore = useSwiperStore();

  const { pagination } = usePagination();

  const prevButton = document.querySelector('.prev-button');
  const nextButton = document.querySelector('.next-button');

  // 이전 클릭
  const handleClickPrev = () => {
    swiperStore.cardSwiper?.slidePrev();
  };

  // 다음 클릭
  const handleClickNext = () => {
    swiperStore.cardSwiper?.slideNext();
  };

  const handleEvent = () => {
    prevButton?.addEventListener('click', handleClickPrev);
    nextButton?.addEventListener('click', handleClickNext);
  };

  useEffect(() => {
    handleEvent();

    return () => {
      prevButton?.removeEventListener('click', handleClickPrev);
      nextButton?.removeEventListener('click', handleClickNext);
    };
  }, [prevButton, nextButton]);

  return (
    <Swiper
      onInit={e => {
        swiperStore.setCardSwiperState(e);
      }}
      modules={[Navigation, Pagination, Virtual]}
      pagination={pagination}
      style={{ overflow: 'visible' }}
    >
      {children}
    </Swiper>
  );
};
export default CardSwiper;
