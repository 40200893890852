import { useEffect, useState } from 'react';

import { fetchAndColorizeSvgData } from 'utils/common/common';

type Props = {
  iconUri: string;
  iconColor: string;
};

const MenuIcon = ({ iconUri, iconColor }: Props) => {
  const [svgDataUri, setSvgDataUri] = useState<string | null>(null);

  useEffect(() => {
    fetchAndColorizeSvgData(iconUri, iconColor).then(svgUri => setSvgDataUri(svgUri));
  }, [iconUri, iconColor]);

  return svgDataUri ? <img className='MenuButton_icon_img' src={svgDataUri} alt='' /> : null;
};
export default MenuIcon;
