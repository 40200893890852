import MapDraw from 'map/MapDraw';
import { LangType } from 'map/types/floor';
import { validMap } from './common/valid';

// poi 언어 변경
export const changeMapLanguage = (lang: LangType) => {
  validMap();

  MapDraw.map?.context?.changeLanguage(lang);
};
