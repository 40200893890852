import { EndPoint } from 'data/endPoints';
import { Screensaver } from 'types/feature/screensaver.type';
import useAxios from 'utils/axios/fetchAxios';
import useFetchScreensaver from '../useFetchScreensaver';

const useScreensaver = () => {
  const { api } = useAxios();
  const { setSucceededMedia } = useFetchScreensaver();

  const getScreensaver = async () => {
    const result = await api.get<Screensaver[]>(EndPoint.screen);

    if (result) {
      const clientResult = result.map((screensaver, index) => {
        return {
          title: screensaver.title,
          fileUri: screensaver.fileUri,
          fileType: screensaver.fileType,
          fileName: screensaver.fileName,
          sortOrder: index,
          id: screensaver.id,
        };
      });
      setSucceededMedia(clientResult);
    }
  };

  return {
    getScreensaver,
  };
};

export default useScreensaver;
