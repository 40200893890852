import { ScreenType } from 'types/common/common.type';

export const returnScreenType = (): ScreenType => {
  const width = window.innerWidth || window.screen.availWidth;
  const height = window.innerHeight || window.screen.availHeight;

  if (width / height < 1 && width / height >= 0.6) {
    return ScreenType.square;
  }

  if (width / height >= 1) {
    return ScreenType.hori;
  }

  return ScreenType.verti;
};
