import { useLocation, useNavigate } from 'react-router-dom';
import useColorSetStore from 'stores/colorSet';
import useMachineStore from 'stores/machine';
import { PathType } from 'types/common/common.type';
import { MainMenu } from 'types/feature/menu.type';
import styles from './MainButton.module.scss';
import Home from './home/Home';

type Props = {
  menu: MainMenu;
};

const MainButton = ({ menu }: Props) => {
  // store
  const colorSetStore = useColorSetStore();
  const machineStore = useMachineStore();

  // hook
  const location = useLocation();
  const navigate = useNavigate();

  const isActiveMain = location.pathname === PathType.home;

  const onClick = () => {
    navigate(PathType.home);
  };

  return (
    <div
      style={{ background: `${isActiveMain ? colorSetStore.gnb.bg.active : colorSetStore.gnb.bg.inactive}` }}
      className={`${styles.main}`}
      onClick={onClick}
    >
      {
        // active 이고 로고 있을 경우
        isActiveMain && machineStore.machine?.point.logoUri ? (
          <div className={styles.logo}>
            <img src={machineStore.machine.point.logoUri} alt='logo' />
          </div>
        ) : (
          // 나머지 경우
          <Home isActive={isActiveMain} menu={menu} />
        )
      }
    </div>
  );
};
export default MainButton;
