/* eslint-disable no-param-reassign */
import { isEmpty } from 'lodash';
import { MutableRefObject, RefObject, useState } from 'react';
import Keyboard, { KeyboardReactInterface } from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';
import './Keyboard.scss';
import styles from './VirtualKeyboard.module.scss';

type VirtualKeyboardProps = {
  keyboardRef: MutableRefObject<KeyboardReactInterface | null>;
  activeInputName: string;
  userRef: RefObject<HTMLInputElement>;
  passwordRef: RefObject<HTMLInputElement>;
  // handleLoginUserByKeyboard: (input: string, name: string) => void;
  // handleChangeKeyboard: any;
  handleSearchedValue: any;
  removeOneValue: any;
};

const VirtualKeyboard = ({
  keyboardRef,
  activeInputName,
  userRef,
  passwordRef,
  handleSearchedValue,
  removeOneValue,
}: // handleLoginUserByKeyboard,
// handleChangeKeyboard,
VirtualKeyboardProps) => {
  const [layoutName, setLayoutName] = useState('default');

  // const handleChangeKeyboard = (input: string) => {
  //   console.log('input >>>> ', input);
  //   handleLoginUserByKeyboard(input, activeInputName);
  // };

  // 기능키 리스트
  const FUCNTIONKEYS = ['{shift}', '{enter}', '{space}', '{bksp}'];

  const handleKeyPressKeyboard = (key: string, event: MouseEvent | undefined) => {
    if (key === '{bksp}') {
      removeOneValue(activeInputName);
    }

    if (key === `{shift}`) {
      setLayoutName(layoutName === 'default' ? 'shift' : 'default');
    }

    if (key === '{enter}') {
      if (userRef.current && passwordRef.current) {
        if (!isEmpty(userRef.current.value) && !isEmpty(passwordRef.current.value)) return;

        if (isEmpty(userRef.current.value)) {
          userRef.current.focus();
        } else {
          passwordRef.current.focus();
        }
      }
    }

    // 기능키가 아닌 텍스트 일 경우
    if (!FUCNTIONKEYS.includes(key)) {
      // 입력값 한문자씩 searchValue로 전달.
      handleSearchedValue(key, activeInputName);
      // handleLoginUserByKeyboard(key, activeInputName);
    }
  };

  const initKeyboard = (r: KeyboardReactInterface) => {
    // keyboard instance 생성
    keyboardRef.current = r;
  };

  return (
    <div className={styles.container}>
      <Keyboard
        inputName={activeInputName}
        layoutName={layoutName}
        keyboardRef={initKeyboard}
        // onChange={e => handleChangeKeyboard(e)}
        onKeyPress={(key, e: MouseEvent | undefined) => handleKeyPressKeyboard(key, e)}
        display={{
          '{bksp}': '⬅',
          '{shift}': layoutName === 'default' ? 'ABC / !@#' : 'abc / 123',
          '{space}': 'space',
          '{enter}': '⏎',
        }}
        layout={{
          default: [
            '1 2 3 4 5 6 7 8 9 0',
            'q w e r t y u i o p {bksp}',
            'a s d f g h j k l {enter}',
            '{shift} z x c v b n m',
          ],

          shift: [
            '! @ # $ % ^ & * ( ) _ +',
            'Q W E R T Y U I O P {bksp}',
            'A S D F G H J K L {enter}',
            '{shift} Z X C V B N M',
          ],
        }}
      />
    </div>
  );
};

export default VirtualKeyboard;
