import DownArrow from 'assets/main/arrow/arrow-down-bk.svg';
import Arrow from 'assets/main/arrow/arrow-up-bk.svg';
import Earth from 'assets/main/earth.svg';
import useColorSetStore from 'stores/colorSet';
import useLanguageStore from 'stores/languages';
import useMultiLangStore from 'stores/multiLang';
import useScreenTypeStore from 'stores/screenType';
import { ScreenType } from 'types/common/common.type';
import { LangBtnType, LangCode } from 'types/common/language.type';
import { changeLangCodeForMap } from 'utils/multi-lang/convertLangCodeForMap';
import styles from './MultiLangBtn.module.scss';

const MultiLanguageBtn = () => {
  const { languages, setCurrentLang, currentLang } = useLanguageStore();
  const { isOpenMultiLang, toggleOpenMultiLang, closeMultiLang } = useMultiLangStore();
  const colorSetStore = useColorSetStore();
  const screenTypeStore = useScreenTypeStore();

  const isVerti = screenTypeStore.screenType === ScreenType.verti || screenTypeStore.screenType === ScreenType.square;

  const handleClickBtn = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    toggleOpenMultiLang(isOpenMultiLang);
  };

  const handleSelect = (lang: LangCode) => {
    setCurrentLang(lang);
    closeMultiLang();
    changeLangCodeForMap(lang);
  };

  return (
    <div className={`${styles.container} ${isOpenMultiLang && styles.active}`}>
      <div
        onClick={handleClickBtn}
        style={{
          color: colorSetStore.footer.text,
        }}
        className={`${styles.button_wrapper} ${isOpenMultiLang && styles.active}`}
      >
        <div className={styles.icon}>
          <img src={Earth} alt='earth' />

          <div className={styles.arrow_hori}>
            <img src={isOpenMultiLang ? DownArrow : Arrow} alt='arrow' />
          </div>
        </div>
        <div className={`${styles.current_lang}`}>{LangBtnType[currentLang]}</div>
        <div className={styles.arrow}>
          <img src={isOpenMultiLang ? DownArrow : Arrow} alt='arrow' />
        </div>
      </div>

      {isOpenMultiLang && (
        <div className={styles.select_box}>
          {languages
            .filter(language => language.code === LangCode[language.code as LangCode])
            .map(language => (
              <button
                type='button'
                key={language.code}
                style={{ background: `${currentLang === language.code && isVerti && colorSetStore.sub}` }}
                className={`${styles.lang} ${currentLang === language.code && styles.active}`}
                onClick={handleSelect.bind(this, language.code as LangCode)}
              >
                {LangBtnType[language.code]}
              </button>
            ))}
        </div>
      )}
    </div>
  );
};

export default MultiLanguageBtn;
