import TenantContainer from 'component/tenant/TenantContainer';
import styles from './Pages.module.scss';

const TenantPage = () => {
  return (
    <div className={styles.tenant}>
      <TenantContainer />
    </div>
  );
};

export default TenantPage;
