import { ModalType } from 'component/common/modal/container/ModalContainer';
import { useState } from 'react';

const useModal = () => {
  const [isVisibleModal, setIsVisibleModal] = useState<boolean>(false);
  const [modalType, setModalType] = useState<ModalType>(ModalType.tenant);

  const openModal = (type: ModalType) => {
    setModalType(type);

    if (isVisibleModal) return;
    setIsVisibleModal(true);
  };

  const closeModal = () => {
    setIsVisibleModal(false);
  };

  return {
    modalType,
    isVisibleModal,
    openModal,
    closeModal,
  };
};
export default useModal;
