import KeywordItem from 'component/tenant/filter/controller/keypad/keywords/keyword/KeywordItem';
import useTenantStore from 'stores/tenant';
import styles from './KeywordsViewer.module.scss';

const KeywordsViewer = () => {
  // store
  const tenantStore = useTenantStore();

  return (
    <div className={styles.wrapper}>
      <div className={styles.keywords}>
        {tenantStore.currentTenant?.keywords.slice(0, 10).map((keyword, index) => (
          <KeywordItem keyword={keyword} type='item' key={index} />
        ))}
      </div>
    </div>
  );
};
export default KeywordsViewer;
