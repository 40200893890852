import { IndexType } from './strIndex.type';

export enum LangCode {
  ko = 'ko',
  en = 'en',
  ja = 'ja',
  cn = 'cn',
  tw = 'tw',
}

export type Language = {
  code: LangCode;
  main: boolean;
  sortOrder: number;
};

export type MultiLang = {
  [code: string]: string;
  ko: string;
  en: string;
  ja: string;
  cn: string;
  tw: string;
};

export const LangBtnType: IndexType = {
  ko: '한국어',
  en: 'English',
  ja: '日本語',
  cn: '簡體中文',
  tw: '繁體中文',
};
