export enum SearchTabType {
  tenant = 'tenant',
  floor = 'floor',
  category = 'category',
}

export enum KeypadType {
  chosung = 'chosung',
  alphabet = 'alphabet',
  number = 'number',
}

export type SearchTab = {
  type: SearchTabType;
  value: string;
};
