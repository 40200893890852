import { create } from 'zustand';

type PopupStore = {
  isOpenFloorChangedPopup: boolean;
  setOpenFloorChangedPopup: (isOpenFloorChangedPopup: boolean) => void;
};

const usePopupStore = create<PopupStore>((set, get) => ({
  isOpenFloorChangedPopup: false,

  setOpenFloorChangedPopup(isOpenFloorChangedPopup: boolean) {
    set({
      isOpenFloorChangedPopup,
    });
  },
}));

export default usePopupStore;
