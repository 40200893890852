import styles from './Pages.module.scss';

const DesktopErrorPage = () => {
  return (
    <div className={styles.error}>
      <div className={styles.container}>
        <div className={styles.title}>데이터 불러오기에 실패했습니다. 관리자에게 문의 바랍니다.</div>
        <button className={styles.go_to_main_btn} type='button' onClick={() => window.location.reload()}>
          메인으로
        </button>
      </div>
    </div>
  );
};

export default DesktopErrorPage;
