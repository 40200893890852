import useSwiperStore from 'stores/swiper';
import SwiperCore from 'swiper';
import { PaginationOptions } from 'swiper/types';

const usePagination = () => {
  const swiperStore = useSwiperStore();

  const handleRenderPagination = (swiperCore: SwiperCore, current: number, total: number) => {
    swiperStore.setCardSwiperState(swiperCore);

    let customPagination = '';
    let endIndex;

    const quotient = Math.floor((current - 1) / 10);
    const startIndex = quotient * 10;

    // 이전 버튼
    // off
    if (current === 1) {
      customPagination += `<div class="prev-button"><img src="assets/icon/pre.svg"/></div>`;
    } else {
      // on
      customPagination += `<div class="prev-button"><img src="assets/icon/pre-active.svg"/></div>`;
    }

    // end index 구하는 식
    if (total < 11) {
      endIndex = total;
    } else {
      endIndex = startIndex + 10 < total ? startIndex + 10 : total - startIndex + (quotient + 1) * 10 - 10;
    }

    // page 숫자
    customPagination += `
        <div class="page-num-wrapper">
          <span class="swiper-pagination-customs current" data-index=${current}>
            ${current}
          </span>
          <span class="swiper-pagination-customs slash">/</span>
          <span class="swiper-pagination-customs total">${endIndex}</span>
        </div>
      `;

    // 다음 버튼
    // off
    if (current === total) {
      customPagination += `<div class='next-button'><img src="assets/icon/next.svg"/></div>`;
    } else {
      // on
      customPagination += `<div class='next-button'><img src="assets/icon/next-active.svg"/></div>`;
    }

    return customPagination;
  };

  // pagination
  const pagination: PaginationOptions = {
    clickable: true,
    el: '.swiper_pagination_common',
    type: 'custom',
    renderCustom: handleRenderPagination,
  };

  return { pagination };
};
export default usePagination;
