import useNaviRouteStore from 'stores/naviRoute';
import useTransportStore from 'stores/transport';

// 이동수단 선택
const useMapTransport = () => {
  const transportStore = useTransportStore();
  const naviRouteStore = useNaviRouteStore();

  const triggerTransport = async () => {
    transportStore.setLoadingTransport(true);

    transportStore.setTransports(Object.keys(naviRouteStore.naviRoutes));
    transportStore.setOpenTransportPopup(true);

    transportStore.setLoadingTransport(false);
  };

  return {
    triggerTransport,
  };
};
export default useMapTransport;
