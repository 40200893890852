import { SvgProps } from '../../type/SvgProps';

const DeleteOneSvg = ({ color }: SvgProps) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='26' height='22' viewBox='0 0 26 22' fill='none'>
      <g clipPath='url(#clip0_4690_13054)'>
        <path d='M10.5 2.5L2 11M2 11L10.5 19.5M2 11H24.5' stroke={color} strokeLinejoin='round' />
      </g>
      <defs>
        <clipPath id='clip0_4690_13054'>
          <rect width='21' height='26' fill='white' transform='translate(0 21.5) rotate(-90)' />
        </clipPath>
      </defs>
    </svg>
  );
};
export default DeleteOneSvg;
