import { create } from 'zustand';

type QrStoreProps = {
  isShowNaviQr: boolean;
  setShowNaviQr: (isShowNaviQr: boolean) => void;

  isShowMainQr: boolean;
  openMainQr: () => void;
  closeMainQr: () => void;
};

const useQrStore = create<QrStoreProps>((set, get) => ({
  isShowNaviQr: false,
  setShowNaviQr: (isShowNaviQr: boolean) => {
    set({
      isShowNaviQr,
    });
  },

  isShowMainQr: false,
  openMainQr: () => {
    set({
      isShowMainQr: true,
    });
  },
  closeMainQr: () => {
    set({
      isShowMainQr: false,
    });
  },
}));

export default useQrStore;
