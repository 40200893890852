import { COLORS } from 'constants/colors';
import useColorSetStore from 'stores/colorSet';
import useLanguageStore from 'stores/languages';
import { MainMenu } from 'types/feature/menu.type';
import MenuIcon from '../../menu-button/menu-icon/MenuIcon';
import styles from './Home.module.scss';

type Props = {
  isActive: boolean;
  menu: MainMenu;
};

const Home = ({ isActive, menu }: Props) => {
  const colorSetStore = useColorSetStore();
  const langStore = useLanguageStore();

  const color = isActive ? colorSetStore.gnb.text.active : colorSetStore.text.title;

  return (
    <div className={styles.main_menu}>
      {menu?.iconUsed && (
        <div className={styles.home_icon}>
          <MenuIcon iconUri={menu.mainIconUri} iconColor={isActive ? COLORS.WHITE : colorSetStore.text.title} />
        </div>
      )}

      <div style={{ color }} className={styles.home_text}>
        {menu?.name[langStore.currentLang] || ''}
      </div>
    </div>
  );
};
export default Home;
