import React, { useEffect } from 'react';

type ImageProps = {
  imageUrl: string;
  isActive: boolean;
  goNextSlide: () => void;
};

const Image = ({ imageUrl, isActive, goNextSlide }: ImageProps) => {
  useEffect(() => {
    if (isActive) {
      goNextSlide();
    }
  }, [isActive]);

  return <img src={imageUrl} alt='screen' />;
};

export default Image;
