import { changeCamera, moveCamera } from 'map/control/camera';
import { changeMapMinMaxZoom } from 'map/control/zoom';
import MapDraw from 'map/MapDraw';
import { Point } from 'types/common/point.type';

/**
 * @desc 빌딩 변경 시 지도 센터값, 방향각, 줌레벨 그리고 최소/최대 줌레벨을 변경한다.
 * @param {Point} point 변경된 포인트
 *
 * ex) 기기의 빌딩인 경우 해당 기기의 줌레벨을 적용.
 *     다른 빌딩인 경우 포인트의 줌레벨을 적용.
 *
 *     최소/최대 줌레벨은 포인트의 값으로 적용.
 */
export const changeMapPointSetting = (point: Point) => {
  // 지도 센터값
  const centerPosition =
    point.id === MapDraw.machine?.point.id
      ? {
          x: MapDraw.machine.mapPosition.centerPositionX,
          y: MapDraw.machine.mapPosition.centerPositionY,
        }
      : {
          x: point.mapPosition.centerPositionX,
          y: point.mapPosition.centerPositionY,
        };

  moveCamera(centerPosition);

  // 지도 방향각
  const rotation =
    point.id === MapDraw.machine?.point.id ? MapDraw.machine.mapPosition.rotation : point.mapPosition.rotation;

  // 지도 기본 줌레벨
  const defaultZoom =
    point.id === MapDraw.machine?.point.id ? MapDraw.machine.mapPosition.zoom : point.mapPosition.zoom;

  // 포인트별 지도 최소/최대 줌레벨 적용
  changeMapMinMaxZoom(Number(point.mapMinZoom), Number(point.mapMaxZoom));

  changeCamera({
    rotation: Number(rotation),
    zoom: Number(defaultZoom),
    transition: false,
  });
};

export const changeMapPointSettingWithoutCamera = (point: Point) => {
  // 포인트별 지도 최소/최대 줌레벨 적용
  changeMapMinMaxZoom(Number(point.mapMinZoom), Number(point.mapMaxZoom));

  // 지도 방향각
  const rotation =
    point.id === MapDraw.machine?.point.id ? MapDraw.machine.mapPosition.rotation : point.mapPosition.rotation;

  // 지도 기본 줌레벨
  const defaultZoom =
    point.id === MapDraw.machine?.point.id ? MapDraw.machine.mapPosition.zoom : point.mapPosition.zoom;

  changeCamera({
    rotation: Number(rotation),
    zoom: Number(defaultZoom),
    transition: false,
  });
};
