import { useTranslation } from 'react-i18next';
import useFloorStore from 'stores/floor';
import useLanguageStore from 'stores/languages';
import styles from './NoCurrentFloorFacilityPopup.module.scss';

type Props = {
  facilityName: string;
};

const NoCurrentFloorFacilityPopup = ({ facilityName: facility }: Props) => {
  const { t } = useTranslation();

  const floorStore = useFloorStore();
  const langStore = useLanguageStore();

  return (
    <div className={styles.container}>
      <div className={styles.text}>
        {t(`floor.noFacility`, { floor: floorStore.floorName[langStore.currentLang], facility })}
      </div>
    </div>
  );
};
export default NoCurrentFloorFacilityPopup;
