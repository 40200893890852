import { Poi } from 'types/common/poi.type';
import { create } from 'zustand';

type Distance = {
  poi: Poi;
  totalDistance: number;
};

type FacilityStore = {
  calculatedFacilityDistances: Map<string, Distance[]>;
  setCalculatedFacilityDistances: (value: Map<string, Distance[]>) => void;

  currentFacilityId: string;
  setCurrentFacilityId: (currentFacilityId: string) => void;
  resetCurrentFacilityId: () => void;
};

const useFacilityStore = create<FacilityStore>((set, get) => ({
  calculatedFacilityDistances: new Map<string, Distance[]>(),
  setCalculatedFacilityDistances(value: Map<string, Distance[]>) {
    set(() => ({ calculatedFacilityDistances: value }));
  },

  currentFacilityId: '',

  setCurrentFacilityId(currentFacilityId: string) {
    set({
      currentFacilityId,
    });
  },

  resetCurrentFacilityId() {
    set({
      currentFacilityId: '',
    });
  },
}));

export default useFacilityStore;
