import { useTranslation } from 'react-i18next';
import styles from './NoTenant.module.scss';

const NoTenant = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <div className={styles.desc}>{t(`tenant.noTenant`)}</div>
    </div>
  );
};
export default NoTenant;
