import MapDraw from '../MapDraw';

// 현재 지도 zoom 반환
export const getCurrentZoomLevel = () => {
  if (!MapDraw.map) return;
  return MapDraw.map.control.getCurrentZoomLevel();
};

// 현재 지도 min max zoom 반환
export const getCurrentMinMaxZoomLevel = () => {
  if (!MapDraw.map) return;
  return MapDraw.map.control.dabeeoMapsManager.cameraController.controlOptions.controlRangeOption.zoom;
};

// 지도 줌레벨 변경
export const changeMapZoom = (zoom: number) => {
  if (!MapDraw.map) return;
  MapDraw.map.control.changeZoom({ zoom, transition: true });
};

// 지도 최소/최대 줌레벨 설정
export const changeMapMinMaxZoom = (min: number, max: number) => {
  MapDraw.map.control.setOption({
    controlRangeOption: {
      zoom: {
        min,
        max,
      },
    },
  });
};

// 지도 zoom in
export const zoomIn = () => {
  if (!MapDraw.map) return;
  MapDraw.map.control.zoomIn({ transition: true });
};

// 지도 zoom out
export const zoomOut = () => {
  if (!MapDraw.map) return;
  MapDraw.map.control.zoomOut({ transition: true });
};
