import { create } from 'zustand';

type Store = {
  naviRoutes: any;
  setNaviRoutes: (naviRoutes: any) => void;
  clearNaviRoutes: () => void;
};

const useNaviRouteStore = create<Store>((set, get) => ({
  naviRoutes: null,
  setNaviRoutes(naviRoutes: any) {
    set(() => ({ naviRoutes }));
  },
  clearNaviRoutes() {
    set(() => ({ naviRoutes: null }));
  },
}));

export default useNaviRouteStore;
