import React from 'react';
import styles from './Layout.module.scss';
import Footer from './footer/Footer';

type LayoutProps = {
  children: React.ReactNode;
};

const Layout = ({ children }: LayoutProps) => (
  <div className={styles.layout}>
    {children}
    <Footer />
  </div>
);

export default Layout;
