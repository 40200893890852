import usePoint from 'hooks/fetch/point/usePoint';
import useColorSetStore from 'stores/colorSet';
import useLanguageStore from 'stores/languages';
import { TenantWithPointId } from 'types/common/tenant.type';
import styles from './PointName.module.scss';

type Props = {
  tenant: TenantWithPointId;
};

const PointName = ({ tenant }: Props) => {
  const langStore = useLanguageStore();
  const colorSetStore = useColorSetStore();

  const { findPointName } = usePoint();

  return (
    <div style={{ color: colorSetStore.text.category }} className={styles.point_name}>
      {findPointName(tenant?.pointId ?? '')?.[langStore.currentLang]}
    </div>
  );
};
export default PointName;
