import axios, { AxiosInstance } from 'axios';

interface CustomInstance extends AxiosInstance {
  get<T>(...params: Parameters<AxiosInstance['get']>): Promise<T>;
  post<T>(...params: Parameters<AxiosInstance['post']>): Promise<T>;
}

// pwd 필요없는 로그인 시 사용하는 axios hook (WEB 버전만 사용)
const useNoLoginAxios = () => {
  const api: CustomInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  // 요청
  api.interceptors.request.use(
    config => config,
    error => Promise.reject(error),
  );

  // 응답
  api.interceptors.response.use(
    response => response.data.data,

    (error: any) => {
      throw error;
    },
  );

  return { api };
};

export default useNoLoginAxios;
