import usePointStore from 'stores/point';
import { TenantWithPointId } from 'types/common/tenant.type';
import BoothNumber from '../../booth/BoothNumber';
import DotSpan from '../../dot/DotSpan';
import FloorName from '../../floor/FloorName';
import PointName from '../../point/PointName';
import styles from './InfoGroup.module.scss';

type Props = {
  tenant: TenantWithPointId;
  categoryOn: boolean;
};

const InfoGroup = ({ categoryOn, tenant }: Props) => {
  const pointStore = usePointStore();

  return (
    <>
      <div className={styles.floor_and_point}>
        {/* 층 */}
        <FloorName tenant={tenant} />

        {/* 부스 번호 - 전시일 경우 노출 */}
        {tenant.boothNumber && <BoothNumber tenant={tenant} />}

        {/* 포인트 */}
        {pointStore.points.length > 1 && (
          <>
            <DotSpan />
            <PointName tenant={tenant} />
          </>
        )}
      </div>

      {/* 카테고리 */}
      {/* {categoryOn && (
        <div className={styles.category_wrapper}>
          <Divider />
          <CategoryName tenant={tenant} />
        </div>
      )} */}
    </>
  );
};
export default InfoGroup;
