/* eslint-disable no-param-reassign */
// TODO: lint error
/* eslint-disable react/require-default-props */
import DeleteAllBtn from 'assets/login/del.svg';
import { FocusEvent, MutableRefObject, RefObject, useState } from 'react';
import { KeyboardReactInterface } from 'react-simple-keyboard';
import styles from './InputForm.module.scss';

type InputFormProps = {
  inputKey: string;
  inputRef: RefObject<HTMLInputElement>;
  keyboardRef: MutableRefObject<KeyboardReactInterface | null>;
  type: string;
  placeholder: string;
  name: string;
  value: string;
  onChangeValue: (value: any) => void;
  onKeyUpValue?: (event: any) => void;
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
  deleteInput: (name: string) => void;
};

const InputForm = ({
  inputKey,
  inputRef,
  keyboardRef,
  type,
  placeholder,
  name,
  value,
  onChangeValue,
  onKeyUpValue,
  onFocus,
  deleteInput,
}: InputFormProps) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  // 전체 삭제 버튼 클릭시 삭제
  const deleteAllInput = () => {
    // input
    if (inputRef.current) {
      inputRef.current.value = '';
      deleteInput(name);
    }

    // 키보드 라이브러리가 가지고 있는 input 삭제
    if (keyboardRef.current) {
      keyboardRef.current.clearInput(name);
      // 다시 작성하도록 focus
      inputRef.current?.focus();
    }
  };

  const visibleType = () => {
    if (type === 'username') {
      return 'text';
    }
    if (type === 'password') {
      return isVisible ? 'text' : 'password';
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.inputContainer}>
        <input
          key={inputKey}
          ref={inputRef}
          className={styles.input}
          type={visibleType()}
          // type={type === "username" && true isVisible ? 'text' : 'password'}
          // type={type === 'password' ? }
          // type === 'password' ? displayValue :
          value={value}
          name={name}
          placeholder={placeholder}
          onChange={onChangeValue.bind(this)}
          onKeyUp={onKeyUpValue?.bind(this)}
          onFocus={onFocus?.bind(this)}
          autoComplete={type === 'password' ? 'off' : 'on'}
        />

        {value !== '' && (
          <div className={styles.delete} onClick={deleteAllInput}>
            <img src={DeleteAllBtn} alt='deleteAll' />
          </div>
        )}
      </div>
      {type === 'password' && (
        <div className={`${styles.keyboard} ${styles.visible}`} onClick={() => setIsVisible(!isVisible)}>
          {isVisible ? '비밀번호 숨기기' : '비밀번호 표시'}
        </div>
      )}
    </div>
  );
};

export default InputForm;
