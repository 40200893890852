/* eslint-disable no-console */
import { warnConsole } from 'component/common/console/console';
import MapDraw from 'map/MapDraw';

// map : 다비오 지도 API 에 접근할 수 있는 class
export const validMap = () => {
  if (!MapDraw.map) {
    return warnConsole('map 이 없습니다.');
  }
};

// mapData : map을 보여주기 전 사용자가 데이터의 정보를 찾아볼 수 있는 class
export const validMapData = () => {
  if (!MapDraw.mapData) {
    return warnConsole('mapData 가 없습니다.');
  }
};
