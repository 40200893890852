import HoriScreen from 'assets/screen/default_horizontal.svg';
import VertiScreen from 'assets/screen/default_vertical.svg';
import useScreenTypeStore from 'stores/screenType';
import { ScreenType } from 'types/common/common.type';
import styles from './InitialLoadingPage.module.scss';

const InitialLoadingPage = () => {
  const screenTypeStore = useScreenTypeStore();

  const defaultImage = screenTypeStore.screenType === ScreenType.verti ? VertiScreen : HoriScreen;

  return (
    <div className={styles.loading}>
      <div className={styles.loading_image}>
        <img src={defaultImage} alt='default' />

        <div className={styles.loading_text}>서비스 화면을 불러오는 중 입니다.</div>
        <div className={styles.dots_loading}>
          <div className={styles.dots} />
        </div>
      </div>
    </div>
  );
};
export default InitialLoadingPage;
