import InfoGroup from 'component/common/info/group/basic/InfoGroup';
import useLanguageStore from 'stores/languages';
import useTenantStore from 'stores/tenant';
import styles from './TenantPopupTitle.module.scss';

const TenantPopupTitle = () => {
  const tenantStore = useTenantStore();
  const langStore = useLanguageStore();

  if (!tenantStore.currentTenant) {
    return <> </>;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>{tenantStore.currentTenant?.name[langStore.currentLang]}</div>

      <div className={styles.info_group}>
        <InfoGroup tenant={tenantStore.currentTenant} categoryOn />
      </div>
    </div>
  );
};
export default TenantPopupTitle;
